// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import function from actions
import { addCrumb } from "store/actions/common";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// import stylesheet
import Style from "./fastfacts.module.css";

// import classname package to combine module css and normal css
import classNames from "classnames";

// import custom data
import { topcardData, accordion1Data, accordion2Data } from "./data";

//   import custom component
import Accordion from "../../components/Accordion";
import BreadCrumb from "components/Breadcrumb";
import BodyButton from "components/Buttons/BodyButton";
import CardHeader from "components/Card/CardHeader";
import { H3Grey, H4Grey, H1Grey, BlackArrayText } from "components/Heading";
import { stats, card_header_img } from "assets/images/images";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function FastFacts() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/fast-facts", name: "Fast Facts" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const currentToken =  useSelector(state => state.auth.currentUserToken)


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  return (
    <Container>
      <BreadCrumb />
      <Row className="mt-5">
        <H1Grey>
          Cyber iTOO Go <span style={{ color: "#DA291C" }}>Fast Facts</span>
        </H1Grey>
        <H3Grey>Covered by Experts</H3Grey>
      </Row>
      <Row className="mt-5 p-0 m-0">
        <Col lg={5} md={7} xs={12} className={classNames("p-0")}>
          <CardHeader image={card_header_img} title="Cyber iTOO Go" />
        </Col>
        <Col lg={7} md={5} className="p-0">
          <div
            className={`card__footer__bottom  ${
              width < 430
                ? "mt-2 text-center"
                : "mt-4 d-flex justify-content-end"
            }`}
          >
            <div className="amount">
              <span>From:</span>
              <span> R 225 pm</span>
            </div>
            <div className={width < 430 ? `d-flex justify-content-center` : ""}>
              <BodyButton
                color="white"
                bg="rgb(53, 63, 78)"
                name="Get Cover Now"
                size={16}
                marginL={width > 770 && 100}
                weight={700}
                marginT={0}
                black={width > 430 ? false : true}
                link="/forms"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={classNames(Style.topcard, "mx-0")}>
        <div className={classNames(Style.topcard__theader, "pt-5")}>
          {topcardData?.header}
        </div>
        <div className={classNames(Style.topcard__header, "pt-5")}>
          {topcardData?.header2}
        </div>
        <div>
          <ul>
            {topcardData?.content?.map(({ title, answer, refNo }, i) => (
              <li key={i}>
                <span className={classNames(Style.topcard__header1)}>
                  {title}:{" "}
                </span>
                <span className={classNames(Style.topcard__answer)}>
                  {answer}
                  <sup style={{ color: "red" }}> {refNo}</sup>
                </span>
              </li>
            ))}
          </ul>
        </div>
        <Row className="mb-2" style={{ fontSize: "0.8rem" }}>
          <Col lg={1}>Reference:</Col>
          <Col>
            <span className="text-danger font-weight-bold">1 - </span> Check
            Point 2021 Mid Year Report / &nbsp;
            <span className="text-danger font-weight-bold">2 - </span> 2019
            study by Keeper Security and the IBM Institute / &nbsp;
            <span className="text-danger font-weight-bold">3 - </span> Research
            from Alliant Cybersecurity / &nbsp;
            <span className="text-danger font-weight-bold">4 - </span> Research
            from Alliant Cybersecurity and Cyber Resilient Organization study by
            Resilient and IBM / &nbsp;
            <span className="text-danger font-weight-bold">5 - </span> 2019 data
            from Keeper Security and the IBM Institute / &nbsp;
            <span className="text-danger font-weight-bold">6 - </span> IBM Cost
            of a Data Breach study 2021
          </Col>
        </Row>
        <div>
          <BlackArrayText link="/product">
            [ See Full Product Details ]
          </BlackArrayText>
        </div>
      </Row>

      <Row>
        <Accordion title={"What are the common causes of compromises?"}>
          <div className={classNames("mt-4")}>
            <Row>
              <Col className="text-left p-5" sm={6}>
                <H4Grey>
                  The Real State of CyberCrime in South Africa 2021:
                </H4Grey>{" "}
                <br />
                <p style={{fontWeight: 500}}>Cyanre - The Authority on digital forensics in Africa</p>
                <ul className="mt-3">
                  <li className="font-weight-bold">15% Outdated Anti-Virus</li>
                  <li className="font-weight-bold">
                    15% Unsecured RDP Structures
                  </li>
                  <li className="font-weight-bold">
                    21% Poorly Configured Firewall Rules
                  </li>
                  <li className="font-weight-bold">
                    23% Outdated Firewall Patches 
                  </li>
                  <li className="font-weight-bold">
                    44% Easily Guessable Passwords
                  </li>
                </ul>
              </Col>
              <Col sm={5} className="text-center d-flex align-items-center">
                <img className="stats-img" src={stats} alt="stats" />
              </Col>
            </Row>
          </div>
        </Accordion>
      </Row>

      <Row>
        <Accordion title={accordion1Data?.header}>
          <div className={classNames("mt-4")}>
            <ul>
              {accordion1Data?.content?.map(
                ({ title, answer, subAnswer }, i) => (
                  <li key={i}>
                    <span className={classNames(Style.content__header)}>
                      {title}
                      {answer.length ? ":" : ""}&nbsp;{" "}
                    </span>
                    <span className={classNames(Style.accordion__answer)}>
                      {answer}
                    </span>
                    {subAnswer?.map((ans, idx) => (
                      <div
                        key={idx}
                        className={classNames(Style.content__answer)}
                      >
                        - {ans}
                      </div>
                    ))}
                  </li>
                )
              )}
            </ul>
          </div>
        </Accordion>
      </Row>

      <Row className="mt-2">
        <Accordion title={accordion2Data?.header}>
          {accordion2Data?.section?.map(({ header, content }, i) => (
            <div key={i} className={classNames("mt-4")}>
              <div className={classNames(Style.accordion2__header)}>
              {header.includes(1) ? <div>1<sup>st</sup> PARTY</div> : header.includes(3) ? <div>3<sup>rd</sup> PARTY</div> : 'INCIDENT RESPONSE'}
              </div>
              <ul style={{ paddingLeft: "20px" }}>
                {content?.map(({ title, answer, subAnswer, subAnswerType }, idx) => (
                  <li key={idx} className={`p-0 ${i !== 0 ? `mt-0` : ``}`}>
                    <div className={classNames(Style.content__header)}>
                      {" "}
                      {title}: <span  className={classNames(Style.content__answer)}>{answer}</span>
                    </div>
                    <div>
                      <ul className="dashed">
                        {subAnswer?.map((ans, index) => (
                          <li
                            key={index}
                            style={{ listStyleType: 'disc' }}
                            className={classNames(Style.content__answer)}
                          >
                            {ans}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Accordion>
      </Row>
      <Row className="m-0 mt-5">
        <div
          className={`card__footer__bottom  ${
            width < 430
              ? "mt-2 text-center"
              : "mt-4 d-flex justify-content-start"
          }`}
        >
          <div className="amount">
            <span>From:</span>
            <span> R 225 pm</span>
          </div>
          <div className={width < 430 ? `d-flex justify-content-center` : ""}>
            <BodyButton
              color="white"
              bg="rgb(53, 63, 78)"
              name="Get cover now"
              size={16}
              marginL={width > 770 && 100}
              weight={700}
              marginT={0}
              black={width > 430 ? false : true}
              link="/forms"
            />
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default FastFacts;
