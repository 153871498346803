import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import classNames from "classnames";
import { VerticalSpacer } from "components";
import { Divider } from "components";
import Button from "components/Buttons/Button";
import { toast } from "react-toastify";
import CONSTANTS from "utils/constants";
import useWindowDimensions from "utils/useWindowDimension";
import ReCAPTCHA from "react-google-recaptcha";

// import stylesheet
import style from "./ProductCard.module.css";

const CreditOrderCard = ({
  containerStyle,
  title,
  price,
  LPA,
  policyStartDate,
  policyEndDate,
  policyRenewalDate,
  selectedProduct,
  policyResponse,
  handleTC,
  tCChecked,
  setIsVisible,
  companyDetails,
}) => {
  const { width } = useWindowDimensions();

  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const [isRecaptchaComplete, setIsRecaptachaComplete] = useState(false);

  return (
    <div
      className={classNames(style.container)}
      style={containerStyle && containerStyle}
    >
      <h3 className={style.title}>{title}</h3>
      <div
        className={`d-flex justify-content-between ${
          width > 550
            ? "flex-row  align-items-center"
            : "flex-column  align-items-start"
        }`}
      >
        <h6 className={style.sub}>Pro rata perimum for the First Year</h6>
        <h5 className={style.price}>{price}</h5>
      </div>
      <span className={style.spanText}>*R {LPA} per annum there after</span>
      <VerticalSpacer rows={1} />
      <Divider />

      <div
        className={classNames(
          "d-flex mt-3 justify-content-between",
          style.policyPrimaryContainer
        )}
      >
        <h6 className={style.policyPrimary}>Policy Start Date: </h6>
        <h6 className={style.policyPrimary}>{policyStartDate}</h6>
      </div>

      <div
        className={classNames(
          "d-flex justify-content-between",
          style.policySecondaryContainer
        )}
      >
        <h6 className={style.policySecondary}>Policy End Date: </h6>
        <h6 className={style.policySecondary}>{policyEndDate}</h6>
      </div>

      <div
        className={classNames(
          "d-flex justify-content-between",
          style.policySecondaryContainer
        )}
      >
        <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
        <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
      </div>

      <Divider />

      <form
        name="ivery_form"
        method="post"
        action={CONSTANTS.IVERY_BASEURL}
        id="ivery_form"
      >
        <input
          type="hidden"
          name="Lite_Merchant_ApplicationID"
          id="Lite_Merchant_ApplicationID"
          value={CONSTANTS.LITE_MERCHANT_APP_ID}
        />
        <input
          type="hidden"
          name="Lite_Website_Successful_Url"
          id="Lite_Website_Successful_Url"
          value={CONSTANTS.REDIRECTION_SUCCESS_URL}
        />
        <input
          type="hidden"
          name="Lite_Website_Fail_Url"
          id="Lite_Website_Fail_Url"
          value={CONSTANTS.REDIRECTION_ERROR_URL}
        />
        <input
          type="hidden"
          name="Lite_Website_Trylater_Url"
          id="Lite_Website_Trylater_Url"
          value={CONSTANTS.REDIRECTION_ERROR_URL}
        />
        <input
          type="hidden"
          name="Lite_Website_Error_Url"
          id="Lite_Website_Error_Url"
          value={CONSTANTS.REDIRECTION_ERROR_URL}
        />
        <input
          style={{ display: "none" }}
          type="text"
          readOnly="readOnly"
          className="clsInputReadOnlyText"
          name="Lite_Order_LineItems_Product_1"
          id="Lite_Order_LineItems_Product_1"
          value="Cyber Insurance"
        />
        <input
          style={{ display: "none" }}
          type="text"
          readOnly="readOnly"
          className="clsInputReadOnlyText"
          name="Lite_Order_LineItems_Quantity_1"
          id="Lite_Order_LineItems_Quantity_1"
          value="1"
        />
        <input
          style={{ display: "none" }}
          type="text"
          readOnly="readOnly"
          className="clsInputReadOnlyText"
          name="Lite_Order_LineItems_Amount_1"
          id="Lite_Order_LineItems_Amount_1"
          value={parseFloat(
            getProrate(
              selectedProduct.risks && selectedProduct.risks[0].rating.message
            )
              .prorataAnnualTotal.replace(".", "")
              .toString()
          )}
        />
        <input
          style={{ display: "none" }}
          name="Lite_Order_Amount"
          readOnly="readOnly"
          type="text"
          value={parseFloat(
            getProrate(
              selectedProduct.risks && selectedProduct.risks[0].rating.message
            )
              .prorataAnnualTotal.replace(".", "")
              .toString()
          )}
          maxLength="10"
          id="Lite_Order_Amount"
          className="clsInputReadOnlyText"
        />
        <input
          type="hidden"
          name="Lite_ConsumerOrderID_PreFix"
          id="Lite_ConsumerOrderID_PreFix"
          value="LITE"
        />
        <input
          style={{ display: "none" }}
          name="Ecom_BillTo_Online_Email"
          readOnly="readOnly"
          type="text"
          value={companyDetails.eMail}
          maxLength="50"
          id="Ecom_BillTo_Online_Email"
          className="clsInputReadOnlyText"
        />
        <input
          type="hidden"
          name="Ecom_Payment_Card_Protocols"
          id="Ecom_Payment_Card_Protocols"
          value="iVeri"
        />
        <input
          style={{ display: "none" }}
          name="Ecom_ConsumerOrderID"
          readOnly="readOnly"
          type="text"
          value={policyResponse.policies[0].policyReference}
          maxLength="20"
          id="Ecom_ConsumerOrderID"
          className="clsInputReadOnlyText"
        />
        <input
          type="hidden"
          name="Ecom_TransactionComplete"
          id="Ecom_TransactionComplete"
          value="false"
        />
        <div className="d-flex justify-content-start align-items-center">
          <FormControlLabel
            className="mt-3 mb-2"
            onClick={handleTC}
            control={
              <Checkbox
                checked={tCChecked ? tCChecked : null}
                sx={{
                  "&.Mui-checked": {
                    color: "#DA291C",
                    fontSize: 8,
                  },
                }}
              />
            }
            label=""
          />
          <div
            onClick={() => setIsVisible(true)}
            className="mt-2 t_c_text"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            I accept the T&C's
          </div>
        </div>

        <ReCAPTCHA
          sitekey={CONSTANTS.RECAPTCHA_KEY}
          onChange={() => setIsRecaptachaComplete(true)}
        />
        {isRecaptchaComplete && (
          <Button
            name="Confirm Purchase"
            color="#fff"
            type={tCChecked ? "submit" : "button"}
            onClick={() => {
              if (!tCChecked) {
                toast.error("Please accept All terms & conditions.");
              }
            }}
            containerStyle={{
              width: "100%",
              marginTop: 10,
            }}
            bg="#24B03C"
          />
        )}
      </form>
    </div>
  );
};

export default CreditOrderCard;
