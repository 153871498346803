// default imports for react project
import React, { useState,useEffect } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";


// Import image from assets folder

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import {useDispatch, useSelector } from "react-redux";
import { H1Red,H1Grey } from "components/Heading";
import { phoneIcon, mailIcon, mapIcon, facebookIcon, twitterIcon, instagramIcon, linkedinIcon } from "assets/images/images";
import { SocialIcons } from "components";
import useWindowDimensions from "utils/useWindowDimension";
import axios from "axios";
import CONSTANTS from "utils/constants";
import { toast } from "react-toastify";
import { validate } from "validate.js";
import ReCAPTCHA from "react-google-recaptcha";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";


// React functional component starts here..
function Contact() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  const [contact, setContact] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContact({ ...contact, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const submitContact = async (e) => {
    e.preventDefault();

    const errors = validate(contact, contactSchema);

    if (errors) {
      setErrors(errors);
      return;
    }
    try {
      setLoading(true);
      const res = await axios.post(
        `${CONSTANTS.MIDDLEWARE_URL}/contact`,
        {
          ...contact,
          productName: "Cyber",
          subject: `Query received - ${CONSTANTS.SITE_URL}`,
          broker_email_address: companyDetails?.contactEMail?.length
            ? companyDetails?.contactEMail
            : "tammyj@itoo.co.za",
        },
        {}
      );
      setContact({});
      setErrors({});
      setLoading(false);
      toast.success(
        "Thank you for contacting us. Our team will reach out to you soon."
      );
      
    } catch (err) {
      
      setLoading(false);
      toast.error("Something wrong. Please try again later.");
    }
  };

  const { width } = useWindowDimensions();

  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  const socials = [
    {
      link: companyDetails?.contactNumber,
      label: companyDetails?.contactNumber,
      icon: phoneIcon,
      disabled: false,
      type: 'phone'
    },
    {
      link: companyDetails?.contactEMail?.length ? `mailto:${companyDetails.contactEMail}` : '',
      label: companyDetails?.contactEMail,
      icon: mailIcon,
      type: 'mail'
    },
    {
      link: companyDetails?.address,
      // label: companyDetails?.address,
      label:`${companyDetails?.address ? companyDetails?.address : " "} ${companyDetails?.address2 ? companyDetails?.address2 : " "} ${companyDetails?.address3 ? companyDetails?.address3 : " "} ${companyDetails?.city ? companyDetails?.city : " "}  ${companyDetails?.province ? companyDetails?.province : " "}  ${companyDetails?.country ? companyDetails?.country : " "}  ${companyDetails?.postalCode ? companyDetails?.postalCode : " "}`,
      icon: mapIcon,
      disabled: true,
      type: 'text'
    },
    {
      link:  companyDetails?.facebook,
      label: 'Facebook',
      icon: facebookIcon,
      type: 'text'
    },
    {
      link: companyDetails?.twitter,
      label: 'Twitter',
      icon: twitterIcon,
      type: 'text'
    },
    {
      link: companyDetails?.instagram,
      label: 'Instagram',
      icon: instagramIcon,
      type: 'text'
    },
    {
      link: companyDetails?.linkedIn,
      // link:`${companyDetails?.linkedIn ? companyDetails?.linkedIn : "444"}`,
      label: "LinkedIn",
      icon: linkedinIcon,
      type: "text",
    },
  ];

  const [isRecaptchaComplete, setIsRecaptachaComplete] = useState(false);

  return (
    <div style={{backgroundColor: '#F9F9F9'}}>
      <Container>
        <Row className="mt-5">
          <H1Grey style={{ textAlign: 'center' }}>
            Get in touch 
          </H1Grey>
          <H1Red  style={{ textAlign: 'center' }}>
            and let's get your every click covered.
          </H1Red>
        </Row>
        <Row className="mt-5 container boxBorder" >
          <Col
            lg={12}
            xs={12}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            {/* <H1Red>Get in Touch</H1Red> */}
            <form onSubmit={submitContact} noValidate className={width < 770 ? 'w-100' : 'w-50'}>
              <div className="form-group contact_form_input">
                <label>Name</label>
                <input  
                  name="name"
                  value={contact?.name}
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.name}</span>
              </div>
              <div className="form-group contact_form_input mt-4">
                <label>Last name</label>
                <input  
                  name="lastName"
                  value={contact?.lastName}
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.lastName}</span>
              </div>
             
              <div className="form-group contact_form_input mt-4">
                <label>Email</label>
                <input  
                  name="email"
                  value={contact?.email}
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.email}</span>
              </div>

              <div className="form-group contact_form_input mt-4">
                <label>Message</label>
                <textarea  
                  name="message"
                  rows={5}
                  value={contact?.message}
                  style={{height: '100px'}}
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.message}</span>
              </div>
             
             
             
                <div className="mt-3">
                <ReCAPTCHA
                  sitekey={CONSTANTS.RECAPTCHA_KEY}
                  onChange={() => setIsRecaptachaComplete(true)}
                
                />
                </div>
                 {isRecaptchaComplete && (
                     <button disabled={loading} className="contact_submit" type="submit">
                     {" "}
                     {loading ? "Sending..." : "Send"}
                   </button>
                )}
           
            </form>
            <Row className="mt-5 pt-5 justify-content-center">
              <Col
                lg={10}
                xs={12}
                className={`d-flex justify-content-around align-items-center ${
                  width > 430 ? "flex-row" : "flex-column"
                }`}
              >
              <Row>
                {socials
                  .filter((fil) => fil.link && fil.link.length !== 0)
                  .map((ex) => (
                    <Col lg={4} xs={6} className="mt-3">
                      <SocialIcons link={ex.link} type={ex.type} disabled={ex.disabled} label={ex.label} icon={ex.icon} />
                    </Col>
                  ))}
              </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xs={12}>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
}

export default Contact;

const contactSchema = {
  name: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
  message: {
    presence: { allowEmpty: false },
  },
};
