// <----------- Importing global style sheets --------->
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/common.css";

// <----------- Importing Reac-router dom --------->
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";

// <----------- Importing Essential components --------->
import Navbar from "./components/Navbar/Navbar";
import Homepage from "screens/Homepage";
import TopNavbar from "components/Navbar/TopNavbar";
import Footer from "components/Navbar/Footer";
import FastFacts from "screens/FastFacts";
import About from "screens/About";
import Claims from "screens/Claims";
import ClaimsVideo from "screens/Video/ClaimsVideo";
import FAQ from "screens/FastFacts/FAQ";
import Product from "screens/FastFacts/Product";
import useWindowDimensions from "utils/useWindowDimension";
import Contact from "screens/Contact";
import { useDispatch, useSelector } from "react-redux";
import { login } from "store/actions/auth";
import { useEffect } from "react";
import Form from "./screens/Forms/Forms";
import PaymentSuccess from "screens/Forms/PaymentSuccess";
import { getAgentDetails, updateLoading } from "store/actions/auth";
import GIFLoader from "./assets/images/loader.gif";
import DebitMandate from "screens/CompanyPolicies/DebitMandate";
import { clearState } from "store/actions/products";
import Disclosures from "screens/CompanyPolicies/Disclosures";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
// <---------- Initialize the App component ---------->

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const isCookiesAccept = useSelector((state) => state?.auth?.isCookiesAccept);
  const isCookiesReject = useSelector((state) => state?.auth?.isCookiesReject);
  const agentName = useSelector((state) => state.auth?.agent?.firstName);

  const [cookies, setCookie] = useCookies(["username"]);
  const [cookiesReject, setCookieReject, removeCookie] = useCookies([
    "username",
  ]);
  const isNecessary = useSelector((state) => state?.products?.isNecessary);
  const isPerformance = useSelector((state) => state?.products?.isPerformance);
  const isFunctional = useSelector((state) => state?.products?.isFunctional);
  const isAnalytics = useSelector((state) => state?.products?.isAnalytics);
  const isAdvertisement = useSelector(
    (state) => state?.products?.isAdvertisement
  );
  const isOthers = useSelector((state) => state?.products?.isOthers);

  // Get the current date
  const now = new Date();

  // Calculate the expiry date
  const expiry = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};

  // function parseQuery(queryString) {
  //   var query = {};
  //   var pairs = (
  //     queryString[0] === "?" ? queryString.substr(1) : queryString
  //   ).split("&");
  //   for (var i = 0; i < pairs.length; i++) {
  //     var pair = pairs[i].split("=");
  //     query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  //   }
  //   return query;
  // }

  const agentCode = useSelector((state) => state.auth.agentCode);

  useEffect(() => {
    if (isCookiesAccept === true) {
      // Cookies.set("googleUrl", "https://www.google.com");
      // const googleUrl = Cookies.get("googleUrl");
      // setCookie("username", agentName, { path: "/", domain: "google.com" });
      setCookie("username", agentName, { path: "/", expires: expiry });
    }
    if (isCookiesReject === true) {
      Cookies.remove("googleUrl");
      removeCookie("username", agentName, { path: "/", expires: expiry });
    }
  }, [agentName, isCookiesAccept, isCookiesReject]);

  useEffect(() => {
    //Necessary
    if (isNecessary === false) {
      setCookie("necessary", "no", { path: "/", expires: expiry });
    }
    if (isNecessary === true) {
      setCookie("necessary", "yes", { path: "/", expires: expiry });
    }

    //Performance
    if (isPerformance === false) {
      // document.cookie = "performance=no; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      // setCookie("performance", "no");
      setCookie("performance", "no", { path: "/", expires: expiry });
    }
    if (isPerformance === true) {
      // document.cookie ="performance=yes; expires=Fri, 31 Dec 9999 23:59:59 GMT";
      // setCookie("performance", "yes");
      setCookie("performance", "yes", { path: "/", expires: expiry });
    }

    //Functional
    if (isFunctional === false) {
      setCookie("functional", "no", { path: "/", expires: expiry });
    }
    if (isFunctional === true) {
      setCookie("functional", "yes", { path: "/", expires: expiry });
    }

    //Analytics
    if (isAnalytics === false) {
      setCookie("analytics", "no", { path: "/", expires: expiry });
    }
    if (isAnalytics === true) {
      setCookie("analytics", "yes", { path: "/", expires: expiry });
    }

    //Advertisement
    if (isAdvertisement === false) {
      setCookie("advertisement", "no", { path: "/", expires: expiry });
    }
    if (isAdvertisement === true) {
      setCookie("advertisement", "yes", { path: "/", expires: expiry });

      //Others
    }
    if (isOthers === false) {
      setCookie("others", "no", { path: "/", expires: expiry });
    }
    if (isOthers === true) {
      setCookie("others", "yes", { path: "/", expires: expiry });
    }
  }, [isPerformance, isFunctional, isAnalytics, isAdvertisement, isOthers]);

  useEffect(() => {
    // if (location.pathname === "/") {
    (async () => {
      dispatch({
        type: "CURRENT_USER_TOKEN",
        payload: parseQuery(location.search).token ?? currentToken,
      });
      await dispatch(login());
      if (agentCode !== parseQuery(location.search).token) {
        if (parseQuery(location.search).token) {
          await dispatch(getAgentDetails(parseQuery(location.search).token));
        } else {
          if (Object.keys(companyDetails).length === 0) {
            await dispatch(
              getAgentDetails(
                process.env.REACT_APP_ENV_TYPE === "PROD" ||
                  process.env.REACT_APP_ENV_TYPE === "UAT"
                  ? "SOFS-2"
                  : "iTOO-22"
              )
            );
          }
        }
      } else {
        dispatch(updateLoading(false));
      }
    })();
    // } else {
    dispatch(updateLoading(false));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      location.pathname !== "/forms" &&
      location.pathname !== "/payment-success"
    ) {
      dispatch(clearState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);

  return !isLoading ? (
    <div
      className="main__container"
      style={{ paddingBottom: width > 770 ? "320px" : "620px" }}
    >
      <BrowserRouter>
        {width > 430 ? <TopNavbar /> : null}
        <Navbar />
        <Switch>
          {/* <------- Forms --------> */}
          <Route path="/forms" exact component={Form} />

          {/* <------- Payment Success --------> */}
          <Route path="/payment-success" exact component={PaymentSuccess} />

          {/* <------- About page --------> */}
          <Route path="/about" component={About} />

          {/* <------- Contact page --------> */}
          <Route path="/contact" component={Contact} />

          {/* <------- Claims page --------> */}
          <Route path="/claims" component={Claims} />

          {/* <------- Disclosures page --------> */}
          <Route path="/disclosure" component={Disclosures} />

          {/* <------- Video page --------> */}
          <Route path="/claims-video" component={ClaimsVideo} />

          {/* <------- Privacy policy page --------> */}
          {/* <Route path="/privacy-policy" component={PrivacyPolicy} /> */}

          {/* <------- Terms & Conditions page --------> */}
          {/* <Route path="/terms-and-conditions" component={TermsAndConditions} /> */}

          {/* <------- FAQ page --------> */}
          <Route path="/faq" component={FAQ} />

          {/* <------- Fast facts page --------> */}
          <Route path="/fast-facts" component={FastFacts} />

          {/* <------- Product page --------> */}
          <Route path="/product" component={Product} />

          {/* <------- Debit Mandate --------> */}
          <Route path="/debit-mandate" exact component={DebitMandate} />

          {/* <------- Home page --------> */}
          <Route path="/" component={Homepage} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  ) : (
    <div className="gif-loader-full">
      <img src={GIFLoader} alt="GIF LOADER"></img>
    </div>
  );
}

export default App;
