import {AUTH_EMAIL, AUTH_PASSWORD, IVERY_BASEURL,BANK_BRANCH_GUID, COMPANY_ID, ENV, ENV_TYPE, LITE_MERCHANT_APP_ID, MIDDLEWARE_URL, SITE_URL, NETWORK_ID, OCP_APIM_SUBSCRIPTION_KEY, PAYMENT_CONFIGURATION_KEY, PRODUCT_INSTANCE_ID, RECAPTCHA_SITE_KEY} from './api-constants';

// <-------- Constants ------->

// Define the ENV TYPE in ENV_VAR
const ENV_VAR = ENV_TYPE[process.env.REACT_APP_ENV_TYPE];
const CONSTANTS = {
  BASEURL: ENV[ENV_VAR],
  ENV: ENV_VAR,
  OCP_APIM_SUBSCRIPTION_KEY: OCP_APIM_SUBSCRIPTION_KEY[ENV_VAR],
  PRODUCT_INSTANCE_ID: PRODUCT_INSTANCE_ID[ENV_VAR],
  AUTH_EMAIL: AUTH_EMAIL[ENV_VAR],
  AUTH_PASSWORD: AUTH_PASSWORD[ENV_VAR],
  IVERY_BASEURL: IVERY_BASEURL[ENV_VAR],
  LITE_MERCHANT_APP_ID: LITE_MERCHANT_APP_ID[ENV_VAR],
  BANK_BRANCH_GUID: BANK_BRANCH_GUID[ENV_VAR],
  NETWORK_ID: NETWORK_ID[ENV_VAR],
  COMPANY_ID: COMPANY_ID[ENV_VAR],
  REDIRECTION_SUCCESS_URL: `${MIDDLEWARE_URL[ENV_VAR]}/payment-success`,
  REDIRECTION_ERROR_URL: `${MIDDLEWARE_URL[ENV_VAR]}/payment-error`,
  MIDDLEWARE_URL: MIDDLEWARE_URL[ENV_VAR],
  SITE_URL: SITE_URL[ENV_VAR],
  CURRENCY_CODE: "ZAR",
  EXTERNAL_PAYMENT_CONFIGURATION_KEY: PAYMENT_CONFIGURATION_KEY[ENV_VAR],
  RECAPTCHA_KEY: RECAPTCHA_SITE_KEY[ENV_VAR],
};
export default CONSTANTS;

export const PRODUCTION_CONSTANTS = {
  REFERER_BASE_URL_PROD: 'https://policy-cache-itp2-prod-za.azurewebsites.net',
  COMPANY_DETAILS: 'https://organisation-itp2-prod-za.azurewebsites.net',
  PRODUCT_RISKS: 'https://product-itp2-prod-za.azurewebsites.net',
  BANK_LOOKUPS: 'https://maintaindata-itp2-prod-za.azurewebsites.net',
  POLICY: 'https://policy-itp2-prod-za.azurewebsites.net'
}


// <-------- Common Messages ------->
export const COMMONMESSAGES = {};


export const COVER_AMOUNT_MID =  {
  OSE: [
    'e723dd50-f942-6b59-0e7e-f470925608a0',
    '8f426e8d-c2aa-3b74-51a0-3bcf797e0f26',
    '8d864b2a-aaa2-e90a-065e-12b31d3c11fb',
    'cea3b886-f998-618d-3075-2272397065ab',
    '6edcf97b-9a4f-37e3-24b2-3c3cabbb1dba',
    '84a29ed1-3c58-d757-5b0f-2262d4946b67',
    '2f86de22-2bd8-0344-cc31-5339b295b2b7',
    '978331e8-bb2f-ffd4-70d9-1e6a8b6b7c50',
    '5a5fbdb6-abe7-91f0-da03-b244b472d692',
    'c330c140-5e83-291b-4b40-b01b8ede43a7',
    '648b7707-a984-8692-18f4-b93ca228302a',
     // UAT Instance ID
     '553572bf-6f01-42b8-a4a5-a988525b663c',
     'd5d1af3f-412e-4654-835d-c114b45007ad',
     '83d05c16-085f-4c59-92b4-d68ad6fc2df6',
     '10b19317-2364-459e-8742-6cc98010a822',
     '50423dcd-1bb5-4248-b73b-cc2ab6e986fc',
     'd77fe8bb-d7ad-494d-b20f-ed9ebb53dd1f',
     '1d14ff70-26f2-4a45-a583-7ed743b57bab',
     '13466280-86e6-413b-ab57-f75a98204900',
     'c8ce6e85-c863-436f-81cf-ab7f08422b0b',
     'e61bd426-6158-4a70-93fc-fcb13ec3a4db',
     '6ef19926-a616-4178-84d2-23201f76a7ce',
     // PROD Instance ID
     '2ad21442-7b0e-48aa-85eb-2e6233598b39',
     'fcb313a4-09f8-478d-97f0-16b564ec7ef4',
     'd0a4e84e-e311-4b00-acf4-2b2b22c564a2',
     '9dc6c62f-da71-4b58-848d-62b88ece793e',
     'f2273ada-8231-446f-8c20-b4f50470328b',
     '75a4ce39-5551-4c98-b3bf-f59be0d76057',
     '6503d750-5df2-4659-97bf-30f01c5583fd',
     'd2a8efee-ebb4-4386-a695-65d77feda727',
     'd46ac317-740d-4d1f-ae05-8c8826999385',
     'a5ace147-0a01-41fb-8dfe-aec5d0710aa8',
     'f6e20de5-fa57-4ecf-b514-b14e19681f0b',

     // NO QUESTIONS FOR DEV
    "915c76b5-7244-c6c4-e424-57aa266188b0",
    "f84e6924-3c8c-7470-eef6-0c6c02dd43de",
    "861d0b9c-277f-ba67-1015-002c27307948",
    "a44becd1-6a2b-3ed8-60ad-3b74f7d59388",
    "a4d63400-9443-ea70-5542-5eec0742889a",
    "5815602e-e8f0-64a0-16b2-1b048fa777d2",
    "3aeae1e8-94c2-2c9e-46fb-fb878cb7a3c1",
    "a3638c8a-68bf-6902-e578-7751c75d9fe0",
    "d2d91e96-242c-0b8a-49b7-d7d7f6f1172e",
    "30dac259-bd58-b337-3bba-0772aa805a96",
    "c44ca5a8-fddc-1eda-c2f1-e27736a548b6",
    "23a8d959-626b-2d8d-b0fe-6c03a9394ca0",
    "e204d032-8f88-2756-7505-d6bbe0ad4ea4",
    "26c12bae-6aac-425e-90d5-f370984d59d8",
    "fead7bba-6676-9252-dfd0-23323fab68dd",
    "44793ea1-934a-ba0f-0666-a0953ba2bd1d",
    "166cfad8-f1b3-b8a4-1704-5f7aafa37e5d",

    // NO QUESTIONS FOR UAT
    "53139cf1-ca9b-4713-aba4-f844578cde8e",
    "f159af38-911a-4191-b517-2164e221b6fc",
    "f0b2faee-7659-4e32-b0a8-f3bb9ec2a2d0",
    "4973a294-c119-4241-b4a1-f1cb798f1e58",
    "029fe452-052c-4031-8dc0-a0b35f807f29",
    "a66caf60-09d2-4d67-8831-1bf11c729581",
    "b13ecd09-5464-45a3-834f-6f228aaf45da",
    "f4576a53-40a8-4cc3-a23c-bcab6dcc0c0e",
    "f3fd1c1b-9041-40ff-846c-749965a88eb6",
    "4c5f2d70-ff7f-4bb2-af14-24cefe186613",
    "4feb84c8-dd4e-455d-97e6-d74a5a6f6c01",
    "0d59db60-8306-4ee5-abc9-3731ce29ce54",
    "22b3ee78-7635-4d89-9257-5434149da10d",
    "519cb8e3-9b5f-4b78-b7dc-8ea904ca39d7",
    "72d819d7-bfc4-4004-99dc-dad76e9f8cae",
    "1462faaf-7e81-4d7f-81ef-a572dc88c817",
    "d4227144-3168-4439-ae83-9c1aa089697f",

    // NO QUESTIONS FOR PROD
    "3f0a1821-cca1-4316-9450-4cc67fee741d",
    "10475814-cc63-453d-b8e3-3e886a3b4818",
    "3e7e0463-dccc-48e5-a960-c2cc1b9917d2",
    "db3cf303-a6b0-47e6-9170-ac8e8b5102a9",
    "6d7fa0e5-04c7-4018-a595-9eee6f55b46f",
    "2be8d696-2966-4d9d-ab79-69f6922a252d",
    "6537946c-b644-4ad9-ba17-5d0ba035a553",
    "578f81b8-0d87-40ae-905b-a7024185ece0",
    "fbd358f8-57ee-4221-b337-1710463acae4",
    "e84af0b4-19a1-40c1-8935-ebd273a1db98",
    "50c22adf-8946-47c4-9a94-9a19c320bdfd",
    "9e920a9d-5c52-4895-9dc6-7e765353125c",
    "f9f5514f-691f-4665-a211-b0657e65a257",
    "b2bc3668-48f1-48de-9ca6-a55a91bca2f2",
    "fb8a7ad5-33ff-4575-9c35-f9b782cc1dbd",
    "091b0827-15d1-4f61-8354-1b62f596beed",
    "98970951-c745-4c37-9a58-52f0a6e5785c"
  ],
  CEU: [
    '49412807-5096-e4ec-44d4-e0273f759438',
    'edffcabe-be70-1eea-89fd-1777728fa6cd',
    '83804408-a35e-106f-e934-b4d77869d295',
    '6cb5d5de-3060-78fe-5738-cd82c024be39',
    'aed6a473-f77b-f769-f1bf-ac8ba4a56f68',
    '11150e04-d16b-5122-645c-0740c1e5bcc2',
    'c151cbc5-6ac9-1c64-4182-9eccd6c27a47',
    'c99802ab-0ef0-cec2-3d11-fa35b7f25941',
    'bfeb8bab-cfbd-0f2e-1673-0e8c66a62182',
    'eb8a8cf4-8770-474e-1ba6-21e42c382c3f',

    // UAT Instance ID
    '49d6d708-b6b7-42f1-b543-44ff3e4e74eb',
    'e11d9fd4-1994-4af7-851e-b1a219837a53',
    '604434df-9ef7-4fdb-a223-81eceaef3971',
    'dff06375-e7f4-4f29-ba1b-69bc43e15ed7',
    'f53768b5-aaf7-4b09-9d48-0d27a879aa7c',
    'b9099623-c24b-4390-9719-86a8da0d7d9f',
    'd6507774-9622-4c41-9510-9d3d4143bb9c',
    'ec33e967-0b56-4def-bc50-9891a847bb57',
    'b1eb5196-b7d2-439b-9e0f-f62fee5e66c8',
    'dcdab233-3fc4-4914-86eb-423d948225ca',

    // PROD Instance ID
    'e19adb09-3623-4192-9f3d-caedd459b888',
    'b13052e2-ae64-4759-a602-b27fd721868c',
    '7a045b96-d886-4a55-b010-35827921d187',
    'ff0a8f81-7d69-4346-9ac7-a9413e95d2a1',
    '695752d8-d808-4959-8ddf-de5183b3610f',
    'eeaebb2d-15c8-4fbf-ae5c-afe8e07045b8',
    '2d6253ab-9112-4df2-93d6-34e48785d21d',
    'ace31eeb-cff3-4e4c-8aa2-9110002799a3',
    '2c59f8ee-a871-44ae-913c-a81f4ae5c89b',
    'efb5eef2-a58b-41be-9095-382307ac6e52',

    //NO QUESTIONS FOR DEV
    "3fc4c256-5641-3ca3-a111-71501380c05c",
    "08c0b882-45f2-7966-3038-ccb749b1b2c1",
    "57ea22ea-b3d9-8ef9-bbbd-ee0abb12b577",
    "a2f316e7-0efd-da97-4d0f-a5a9ccf36608",
    "8ee88d9b-6f4b-5d90-35fc-954e651f80c0",
    "76632819-ded0-486a-353e-e55a2d3aa800",
    "b9af8e34-97fa-00b2-d1b5-934f524a4861",
    "4f6449b5-fe41-df3f-b761-c92792e601b0",
    "f6c23a39-575c-56fd-f853-38b57af2231a",
    "5e3f2fc6-774c-a2ac-f2d0-e97ceb5d0a0c",

    // NO QUESTIONS FOR UAT
    "94c35be1-e8e0-415b-8f1c-82a11b4fa2ca",
    "c37a97b6-ee4f-4138-a6ff-ddf29dc4fced",
    "675d1824-089a-40bb-9936-c40344982af4",
    "850e3280-b5e2-4983-a159-60c8d272ce84",
    "f8b6cbf0-7dfa-422f-8d9e-c164c62ee690",
    "5c94d157-779e-4a27-abce-6607f365647a",
    "1a2ac377-7ff2-4da9-8948-eb9c79324d36",
    "7aa32eb0-7e8b-4627-89d1-2c92eb3b9d3b",
    "1519852b-98e7-4bbf-9f6d-3137b70b7771",
    "f4a6049a-198b-4bb5-9900-d2f1fce4761e",

    // NO QUESTIONS FOR PROD
    "16dd1d99-5abb-45b1-982c-b72e2bc5ee62",
    "4092367b-ba0a-4d52-9d5c-03badf4d3ff6",
    "905709fe-b85c-4aa8-84c7-1437ee73d327",
    "f62c4849-6061-4fe7-981b-a45f6c163a51",
    "2ffe3d3a-b560-434e-a011-e302be48e8aa",
    "aa250c88-fcb4-4b18-b998-d38d316bd43c",
    "0a4a5b22-39a1-4ad9-8602-1127e2b1dc45",
    "146d2083-d34b-492b-9dde-f0f20a5de3c1",
    "8eb58c1f-29d3-44dd-90bf-417eb13f3cae",
    "f3b93f96-2055-4c16-bfbe-e4537e248935"
  ],
  ICU: [
    'e5dffc60-ffbd-96d6-2e09-03d703838e25',
    '2f3578a0-11b5-2470-6533-b65bcaa7e016',
    'cac7a1f7-d1cd-30ae-6a87-7d2d08e4440f',
    '27bffbbc-a1aa-e7dc-cec1-0debfe756bda',
    '174f02b4-1cc2-9bc9-6b6a-67b187dd8ff8',
    '3f54fa07-f7d2-dfee-90f1-0841feaa6cb9',
    'c8035590-aa5b-0dc2-3845-b6be7e06f028',
    '9bcfbb47-1c11-04c3-9b68-1fc2a3cf2cd9',

    // UAT Instance ID
    '160edd11-951c-4898-9da9-68a7487e5533',
    'a7efc2cb-8048-4bb8-94dc-0a568519cccf',
    '2727f91e-87a8-4913-9b0b-cb69f5372835',
    '98cbc365-0014-47cf-abaf-87ce0819f466',
    '7d6aeb11-a410-4033-84f9-1133d12c61f9',
    '0710b13c-f211-40df-b5b0-bfea7670e0e3',
    '6302e027-a443-4bc9-af56-e005a0ed62d2',
    '8afce966-0274-4151-8718-4f7d0fff0f3f',

    // PROD Instance ID
    '5c5a010c-82e5-4b43-b5f3-d906ce203789',
    'c63b8b80-560d-447c-83fc-ae15b78c6c9e',
    '81b5e730-e24d-4294-9c3d-835389c8f4a8',
    'f43323ad-0f3e-40f7-960f-076c87c86878',
    'b43a2bfc-1fe0-40b3-b413-d1137c79f1b5',
    'a2ded343-cbb3-48b6-9d3c-310fc076f258',
    '0803740d-7eec-49bd-9a1d-557bb8729f42',
    '19029769-5ab0-446e-88bb-ab0b6b656a9c',

    // NO QUESTIONS FOR DEV
    "bf7668d9-4964-4c4c-0fcf-d60bcb1ae967",
    "2097949b-e013-1b86-899b-cb123429aa3c",
    "be4f6811-bde9-a352-6a9a-ba009b1e9b4d",
    "205de097-2c32-1e8a-1e95-fff0fcdcb68f",
    "24c936e5-27c3-afec-6b39-af3d28e54402",
    "43ab3460-b560-61a5-bd43-092ca51aa5a5",
    "87a01424-b174-44f3-f35f-a2ee9c7d4585",
    "e3f39be4-3629-6af3-6a1e-111ec5031b03",
    "9ffebfbc-3170-cb1b-73bc-c1fb90c4fda3",
    "1c6ded36-48f8-1fb8-e78e-95379d0f62e0",

    // NO QUESTION FOR UAT
    "c77dc0da-43fc-4eff-a5bd-2e44c0fb4204",
    "8386a7ed-61f0-4c87-acc4-ffaba9bdfe88",
    "894ec13a-319a-41cd-99f7-da7dbb96c1df",
    "313eb743-38f7-4097-87bf-097114af4cfe",
    "6d380fc3-41d5-42ee-83b9-260ee152b468",
    "6c765c7c-02b7-475a-9ad0-215b8905714c",
    "2eacdd8f-2b4d-44c8-ad6a-3d538dbdb01f",
    "e96f76a2-5b1b-43bc-a247-af557a914ff1",
    "a39f0c9f-f6db-40ec-ac65-95cef32d711a",
    "a0950b23-3344-460e-8a4f-085eb7bdfbd9",

    // NO QUESTION FOR PROD
    "dea847c8-746a-4b9e-9802-6007e276d52e",
    "ecb7e05a-4cbf-45df-be0c-ea17fa83e654",
    "a538d9c6-8e31-450d-afb4-dfe1575c572b",
    "10fa4ca2-125f-4ed4-a154-9a7f655a36d0",
    "15b10f32-119a-4b86-9169-9a29f3ddf84c",
    "39ebb132-2271-4242-985a-18d4af3df0cd",
    "fb6cb819-9462-4fc1-b164-2ae6e40c5246",
    "25088934-2cbd-4aa6-8f19-a1a226437c28",
    "62915565-1dc5-4c37-a865-4a9162e5df99",
    "203b7bb2-ddd3-4ab5-85d4-5ca866f082fd"
  ]
}

export const COVER_AMOUNT_HIGH = {
  OSE: [
    'e723dd50-f942-6b59-0e7e-f470925608a0',
    '8f426e8d-c2aa-3b74-51a0-3bcf797e0f26',
    '8d864b2a-aaa2-e90a-065e-12b31d3c11fb',
    '0c346ca1-49e6-ad69-ac3f-e2fb43cca8e0',
    '7d734822-68ea-073d-0823-e668c2ea8838',
    '0af91326-cca7-2520-ed1f-491bfa0f4418',
    'd62c6ca7-db13-127c-ef39-808a0d45fc19',
    '339d8cb8-c7b6-803c-585e-20d9c9551898',
    '974479fe-055f-55b0-243d-90e27f292ba1',
    'cea3b886-f998-618d-3075-2272397065ab',
    '84a29ed1-3c58-d757-5b0f-2262d4946b67',
    '6edcf97b-9a4f-37e3-24b2-3c3cabbb1dba',
    '2f86de22-2bd8-0344-cc31-5339b295b2b7',
    '978331e8-bb2f-ffd4-70d9-1e6a8b6b7c50',
    '5a5fbdb6-abe7-91f0-da03-b244b472d692',
    'c330c140-5e83-291b-4b40-b01b8ede43a7',
    '648b7707-a984-8692-18f4-b93ca228302a',

    // UAT
    '553572bf-6f01-42b8-a4a5-a988525b663c',
    'd5d1af3f-412e-4654-835d-c114b45007ad',
    '10b19317-2364-459e-8742-6cc98010a822',
    '34d19786-d835-433c-a29f-92e8f2365131',
    '55ed8ea4-156c-409e-bf9d-2550f002759a',
    'cfb5d287-3d7a-4483-9ea9-cb1770f92323',
    'fa80eb3d-a78d-479b-a027-d8b2aa6e062c',
    '9655b9b6-aeea-4d6e-a5c6-955e85677bc6',
    '83d05c16-085f-4c59-92b4-d68ad6fc2df6',
    'a606e045-acbb-4cc9-8691-644fbaf6a55a',
    '50423dcd-1bb5-4248-b73b-cc2ab6e986fc',
    'd77fe8bb-d7ad-494d-b20f-ed9ebb53dd1f',
    '1d14ff70-26f2-4a45-a583-7ed743b57bab',
    '13466280-86e6-413b-ab57-f75a98204900',
    'c8ce6e85-c863-436f-81cf-ab7f08422b0b',
    'e61bd426-6158-4a70-93fc-fcb13ec3a4db',
    '6ef19926-a616-4178-84d2-23201f76a7ce',

    // PROD instance ID
    '2ad21442-7b0e-48aa-85eb-2e6233598b39',
    'fcb313a4-09f8-478d-97f0-16b564ec7ef4',
    'd0a4e84e-e311-4b00-acf4-2b2b22c564a2',
    'c2074469-50ee-4ca5-982e-0f22fa343f0a',
    'f91b822a-b4a7-4d2a-8384-f121a5b944bb',
    'fe4e5a25-960f-4166-8b5b-81ef6c289d9b',
    '4b5f6dab-319c-4319-a9cd-623bc0ca2fed',
    '4be14538-d3bc-44fe-aae8-1652f1988c0d',
    '9dc6c62f-da71-4b58-848d-62b88ece793e',
    'f2273ada-8231-446f-8c20-b4f50470328b',
    '75a4ce39-5551-4c98-b3bf-f59be0d76057',
    '6503d750-5df2-4659-97bf-30f01c5583fd',
    'd2a8efee-ebb4-4386-a695-65d77feda727',
    'd46ac317-740d-4d1f-ae05-8c8826999385',
    'a5ace147-0a01-41fb-8dfe-aec5d0710aa8',
    '1349297c-2618-43ea-9a2c-e84ad63e9070',
    'f6e20de5-fa57-4ecf-b514-b14e19681f0b',

    // NO QUESTIONS FOR DEV
    "915c76b5-7244-c6c4-e424-57aa266188b0",
    "f84e6924-3c8c-7470-eef6-0c6c02dd43de",
    "861d0b9c-277f-ba67-1015-002c27307948",
    "a44becd1-6a2b-3ed8-60ad-3b74f7d59388",
    "a4d63400-9443-ea70-5542-5eec0742889a",
    "5815602e-e8f0-64a0-16b2-1b048fa777d2",
    "3aeae1e8-94c2-2c9e-46fb-fb878cb7a3c1",
    "a3638c8a-68bf-6902-e578-7751c75d9fe0",
    "d2d91e96-242c-0b8a-49b7-d7d7f6f1172e",
    "30dac259-bd58-b337-3bba-0772aa805a96",
    "c44ca5a8-fddc-1eda-c2f1-e27736a548b6",
    "23a8d959-626b-2d8d-b0fe-6c03a9394ca0",
    "e204d032-8f88-2756-7505-d6bbe0ad4ea4",
    "26c12bae-6aac-425e-90d5-f370984d59d8",
    "fead7bba-6676-9252-dfd0-23323fab68dd",
    "44793ea1-934a-ba0f-0666-a0953ba2bd1d",
    "166cfad8-f1b3-b8a4-1704-5f7aafa37e5d",

    // NO QUESTIONS FOR UAT
    "53139cf1-ca9b-4713-aba4-f844578cde8e",
    "f159af38-911a-4191-b517-2164e221b6fc",
    "f0b2faee-7659-4e32-b0a8-f3bb9ec2a2d0",
    "4973a294-c119-4241-b4a1-f1cb798f1e58",
    "029fe452-052c-4031-8dc0-a0b35f807f29",
    "a66caf60-09d2-4d67-8831-1bf11c729581",
    "b13ecd09-5464-45a3-834f-6f228aaf45da",
    "f4576a53-40a8-4cc3-a23c-bcab6dcc0c0e",
    "f3fd1c1b-9041-40ff-846c-749965a88eb6",
    "4c5f2d70-ff7f-4bb2-af14-24cefe186613",
    "4feb84c8-dd4e-455d-97e6-d74a5a6f6c01",
    "0d59db60-8306-4ee5-abc9-3731ce29ce54",
    "22b3ee78-7635-4d89-9257-5434149da10d",
    "519cb8e3-9b5f-4b78-b7dc-8ea904ca39d7",
    "72d819d7-bfc4-4004-99dc-dad76e9f8cae",
    "1462faaf-7e81-4d7f-81ef-a572dc88c817",
    "d4227144-3168-4439-ae83-9c1aa089697f",

    // NO QUESTIONS FOR PROD
    "3f0a1821-cca1-4316-9450-4cc67fee741d",
    "10475814-cc63-453d-b8e3-3e886a3b4818",
    "3e7e0463-dccc-48e5-a960-c2cc1b9917d2",
    "db3cf303-a6b0-47e6-9170-ac8e8b5102a9",
    "6d7fa0e5-04c7-4018-a595-9eee6f55b46f",
    "2be8d696-2966-4d9d-ab79-69f6922a252d",
    "6537946c-b644-4ad9-ba17-5d0ba035a553",
    "578f81b8-0d87-40ae-905b-a7024185ece0",
    "fbd358f8-57ee-4221-b337-1710463acae4",
    "e84af0b4-19a1-40c1-8935-ebd273a1db98",
    "50c22adf-8946-47c4-9a94-9a19c320bdfd",
    "9e920a9d-5c52-4895-9dc6-7e765353125c",
    "f9f5514f-691f-4665-a211-b0657e65a257",
    "b2bc3668-48f1-48de-9ca6-a55a91bca2f2",
    "fb8a7ad5-33ff-4575-9c35-f9b782cc1dbd",
    "091b0827-15d1-4f61-8354-1b62f596beed",
    "98970951-c745-4c37-9a58-52f0a6e5785c"
  ],
  CEU: [
    '49412807-5096-e4ec-44d4-e0273f759438',
    'edffcabe-be70-1eea-89fd-1777728fa6cd',
    '83804408-a35e-106f-e934-b4d77869d295',
    '6cb5d5de-3060-78fe-5738-cd82c024be39',
    'aed6a473-f77b-f769-f1bf-ac8ba4a56f68',
    'eb8a8cf4-8770-474e-1ba6-21e42c382c3f',
    '11150e04-d16b-5122-645c-0740c1e5bcc2',
    'c151cbc5-6ac9-1c64-4182-9eccd6c27a47',
    'c99802ab-0ef0-cec2-3d11-fa35b7f25941',
    'bfeb8bab-cfbd-0f2e-1673-0e8c66a62182',

    // UAT Instance ID
    '49d6d708-b6b7-42f1-b543-44ff3e4e74eb',
    'e11d9fd4-1994-4af7-851e-b1a219837a53',
    '604434df-9ef7-4fdb-a223-81eceaef3971',
    'dff06375-e7f4-4f29-ba1b-69bc43e15ed7',
    'f53768b5-aaf7-4b09-9d48-0d27a879aa7c',
    'b9099623-c24b-4390-9719-86a8da0d7d9f',
    'd6507774-9622-4c41-9510-9d3d4143bb9c',
    'ec33e967-0b56-4def-bc50-9891a847bb57',
    'b1eb5196-b7d2-439b-9e0f-f62fee5e66c8',
    'dcdab233-3fc4-4914-86eb-423d948225ca',

    // PROD Instance ID
    'e19adb09-3623-4192-9f3d-caedd459b888',
    'b13052e2-ae64-4759-a602-b27fd721868c',
    '7a045b96-d886-4a55-b010-35827921d187',
    'ff0a8f81-7d69-4346-9ac7-a9413e95d2a1',
    '695752d8-d808-4959-8ddf-de5183b3610f',
    'eeaebb2d-15c8-4fbf-ae5c-afe8e07045b8',
    '2d6253ab-9112-4df2-93d6-34e48785d21d',
    'ace31eeb-cff3-4e4c-8aa2-9110002799a3',
    '2c59f8ee-a871-44ae-913c-a81f4ae5c89b',
    'efb5eef2-a58b-41be-9095-382307ac6e52',

     //NO QUESTIONS FOR DEV
     "3fc4c256-5641-3ca3-a111-71501380c05c",
     "08c0b882-45f2-7966-3038-ccb749b1b2c1",
     "57ea22ea-b3d9-8ef9-bbbd-ee0abb12b577",
     "a2f316e7-0efd-da97-4d0f-a5a9ccf36608",
     "8ee88d9b-6f4b-5d90-35fc-954e651f80c0",
     "76632819-ded0-486a-353e-e55a2d3aa800",
     "b9af8e34-97fa-00b2-d1b5-934f524a4861",
     "4f6449b5-fe41-df3f-b761-c92792e601b0",
     "f6c23a39-575c-56fd-f853-38b57af2231a",
     "5e3f2fc6-774c-a2ac-f2d0-e97ceb5d0a0c",
 
     // NO QUESTIONS FOR UAT
     "94c35be1-e8e0-415b-8f1c-82a11b4fa2ca",
     "c37a97b6-ee4f-4138-a6ff-ddf29dc4fced",
     "675d1824-089a-40bb-9936-c40344982af4",
     "850e3280-b5e2-4983-a159-60c8d272ce84",
     "f8b6cbf0-7dfa-422f-8d9e-c164c62ee690",
     "5c94d157-779e-4a27-abce-6607f365647a",
     "1a2ac377-7ff2-4da9-8948-eb9c79324d36",
     "7aa32eb0-7e8b-4627-89d1-2c92eb3b9d3b",
     "1519852b-98e7-4bbf-9f6d-3137b70b7771",
     "f4a6049a-198b-4bb5-9900-d2f1fce4761e",

    // NO QUESTIONS FOR PROD
    "16dd1d99-5abb-45b1-982c-b72e2bc5ee62",
    "4092367b-ba0a-4d52-9d5c-03badf4d3ff6",
    "905709fe-b85c-4aa8-84c7-1437ee73d327",
    "f62c4849-6061-4fe7-981b-a45f6c163a51",
    "2ffe3d3a-b560-434e-a011-e302be48e8aa",
    "aa250c88-fcb4-4b18-b998-d38d316bd43c",
    "0a4a5b22-39a1-4ad9-8602-1127e2b1dc45",
    "146d2083-d34b-492b-9dde-f0f20a5de3c1",
    "8eb58c1f-29d3-44dd-90bf-417eb13f3cae",
    "f3b93f96-2055-4c16-bfbe-e4537e248935"
  ],
  ICU: [
    'e5dffc60-ffbd-96d6-2e09-03d703838e25',
    '2f3578a0-11b5-2470-6533-b65bcaa7e016',
    'cac7a1f7-d1cd-30ae-6a87-7d2d08e4440f',
    '27bffbbc-a1aa-e7dc-cec1-0debfe756bda',
    '174f02b4-1cc2-9bc9-6b6a-67b187dd8ff8',
    '3f54fa07-f7d2-dfee-90f1-0841feaa6cb9',
    'c8035590-aa5b-0dc2-3845-b6be7e06f028',
    '9bcfbb47-1c11-04c3-9b68-1fc2a3cf2cd9',
    // UAT INSTANCE ID
    '160edd11-951c-4898-9da9-68a7487e5533',
    'a7efc2cb-8048-4bb8-94dc-0a568519cccf',
    '2727f91e-87a8-4913-9b0b-cb69f5372835',
    '98cbc365-0014-47cf-abaf-87ce0819f466',
    '7d6aeb11-a410-4033-84f9-1133d12c61f9',
    '0710b13c-f211-40df-b5b0-bfea7670e0e3',
    '6302e027-a443-4bc9-af56-e005a0ed62d2',
    '8afce966-0274-4151-8718-4f7d0fff0f3f',

    //PROD Instance ID
    '5c5a010c-82e5-4b43-b5f3-d906ce203789',
    'c63b8b80-560d-447c-83fc-ae15b78c6c9e',
    '81b5e730-e24d-4294-9c3d-835389c8f4a8',
    'f43323ad-0f3e-40f7-960f-076c87c86878',
    'b43a2bfc-1fe0-40b3-b413-d1137c79f1b5',
    'a2ded343-cbb3-48b6-9d3c-310fc076f258',
    '0803740d-7eec-49bd-9a1d-557bb8729f42',
    '19029769-5ab0-446e-88bb-ab0b6b656a9c',
    '69de0efb-3ec5-4fe0-ba9d-cc8e013f4da5',
    'bc642689-51bf-4208-ba75-9c9a773fec8e',

    // NO QUESTIONS FOR DEV
    "bf7668d9-4964-4c4c-0fcf-d60bcb1ae967",
    "2097949b-e013-1b86-899b-cb123429aa3c",
    "be4f6811-bde9-a352-6a9a-ba009b1e9b4d",
    "205de097-2c32-1e8a-1e95-fff0fcdcb68f",
    "24c936e5-27c3-afec-6b39-af3d28e54402",
    "43ab3460-b560-61a5-bd43-092ca51aa5a5",
    "87a01424-b174-44f3-f35f-a2ee9c7d4585",
    "e3f39be4-3629-6af3-6a1e-111ec5031b03",
    "9ffebfbc-3170-cb1b-73bc-c1fb90c4fda3",
    "1c6ded36-48f8-1fb8-e78e-95379d0f62e0",

    // NO QUESTION FOR UAT
    "c77dc0da-43fc-4eff-a5bd-2e44c0fb4204",
    "8386a7ed-61f0-4c87-acc4-ffaba9bdfe88",
    "894ec13a-319a-41cd-99f7-da7dbb96c1df",
    "313eb743-38f7-4097-87bf-097114af4cfe",
    "6d380fc3-41d5-42ee-83b9-260ee152b468",
    "6c765c7c-02b7-475a-9ad0-215b8905714c",
    "2eacdd8f-2b4d-44c8-ad6a-3d538dbdb01f",
    "e96f76a2-5b1b-43bc-a247-af557a914ff1",
    "a39f0c9f-f6db-40ec-ac65-95cef32d711a",
    "a0950b23-3344-460e-8a4f-085eb7bdfbd9",

    // NO QUESTION FOR PROD
    "dea847c8-746a-4b9e-9802-6007e276d52e",
    "ecb7e05a-4cbf-45df-be0c-ea17fa83e654",
    "a538d9c6-8e31-450d-afb4-dfe1575c572b",
    "10fa4ca2-125f-4ed4-a154-9a7f655a36d0",
    "15b10f32-119a-4b86-9169-9a29f3ddf84c",
    "39ebb132-2271-4242-985a-18d4af3df0cd",
    "fb6cb819-9462-4fc1-b164-2ae6e40c5246",
    "25088934-2cbd-4aa6-8f19-a1a226437c28",
    "62915565-1dc5-4c37-a865-4a9162e5df99",
    "203b7bb2-ddd3-4ab5-85d4-5ca866f082fd"
  ]
}


export const HIDDEN_QUESTION = [
  'f0ae08fd-870e-f7af-4873-f3473050c131',
  '0306656a-2882-4db3-901a-98616d74ae28',
  '24a89403-3b27-46f8-92b6-a517ca5acd97'
]

export const HIDE_QUESTION = [
  // DEV Instance
  '2b906a5d-39de-4b7c-8bf1-2ed4410e6cc5',
  // UAT Instance
  '0b92829f-41f0-d9aa-d81f-6c3686e3a4eb',
  // PROD Instance
  'e9213514-813a-4f86-88dd-8848ad837db1'
]