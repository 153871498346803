import React, { useEffect, useState } from "react";

// Form Module css
import style from "./Form.module.css";

// MUI Components
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import classNames from "classnames";

// Importing Forms
import Step1 from "./Steps/Step-1";
import Step2 from "./Steps/Step-2";
import Step3 from "./Steps/Step-3";
import Step4 from "./Steps/Step-4";
import Step5 from "./Steps/Step-5";
import Step6 from "./Steps/Step-6";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  UpdateSteps,
} from "store/actions/products";
import { login, getAgentOnboarding } from "store/actions/auth";
import useWindowDimensions from "utils/useWindowDimension";
import Check from "./Steps/Check";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Form = () => {
  // Dynamic width from Screen sizes
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  // Initial steps or presisted if user resume
  const intitalStep = useSelector((state) => state.products.activeStep);
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  // Active steps according to forms
  const [activeStep, setActiveStep] = useState(intitalStep || 0);

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Dispatch Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      // payload: parseQuery(location.search).token ?? currentToken,
      payload: parseQuery(location.search).token
        ? parseQuery(location.search).token
        : currentToken
        ? currentToken
        : process.env.REACT_APP_ENV_TYPE === "PROD" ||
          process.env.REACT_APP_ENV_TYPE === "UAT"
        ? "SOFS-2"
        : "iTOO-22",
    });
  }, [currentToken]);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  // Function performs Next Step
  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Function performs previous Step
  const handleBack = (step) => {
    window.scrollTo(0, 0);
    if (step >= 0) {
      setActiveStep(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getHashRoute = (steps) => {
    switch (steps) {
      case 0:
        return "cover";
      case 1:
        return "quote";
      case 2:
        return "cyber-overview";
      case 3:
        return "it-enviornment";
      case 4:
        return "company-details";
      case 5:
        return "application-review";
      case 6:
        return "payment-details";
      default:
        return "";
    }
  };

  // Updating Active steps to the store
  useEffect(() => {
    if (activeStep) {
      dispatch(UpdateSteps(activeStep));
    }
  }, [activeStep, dispatch]);

  useEffect(() => {
    history.push({
      hash: `#${getHashRoute(activeStep)}`,
      search: `?token=${
        parseQuery(location.search).token
          ? parseQuery(location.search).token
          : currentToken
          ? currentToken
          : process.env.REACT_APP_ENV_TYPE === "PROD" ||
            process.env.REACT_APP_ENV_TYPE === "UAT"
          ? "SOFS-2"
          : "iTOO-22"
      }`,
    });
  }, [activeStep, currentToken]);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  // Consolidating the risks and attributes (Array of the Risk attributes)
  useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: "CURRENT_USER_TOKEN",
          // payload: parseQuery(location.search).token ?? currentToken,
          payload: parseQuery(location.search).token
            ? parseQuery(location.search).token
            : currentToken
            ? currentToken
            : process.env.REACT_APP_ENV_TYPE === "PROD" ||
              process.env.REACT_APP_ENV_TYPE === "UAT"
            ? "SOFS-2"
            : "iTOO-22",
        });
        await dispatch(login());
        if (parseQuery(location.search).token) {
          await dispatch(getAgentOnboarding(parseQuery(location.search).token));
        } else if (Object.keys(agentOnBoarding || {}).length === 0) {
          await dispatch(getAgentOnboarding(agentCode));
        }
        await dispatch(getProductRisks());
      } catch (err) {
      } finally {
        history.push({
          hash: `#${getHashRoute(activeStep)}`,
          // search: `?token=${currentToken}`
          search: `?token=${
            parseQuery(location.search).token
              ? parseQuery(location.search).token
              : currentToken
              ? currentToken
              : process.env.REACT_APP_ENV_TYPE === "PROD" ||
                process.env.REACT_APP_ENV_TYPE === "UAT"
              ? "SOFS-2"
              : "iTOO-22"
          }`,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (risks?.length) {
      if (risksAttributes.length <= 5) {
        setIsLoading(true);
        dispatch(getProductTypeDetails());
        risks?.forEach((risk, index) => {
          dispatch(getProductRisksAttributes(risk.instanceId));
          if (index === risks.length - 1) {
            setIsLoading(false);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [risks, dispatch]);

  return !isLoading ? (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center flex-column">
        {/* <------- HEADER --------> */}
        <h3 className={style.header}>
          Instant cyber insurance in a few clicks
        </h3>

        {/* <------- STEPPER STARTS --------> */}

        <Box className="mt-5">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{width > 430 ? label.label : ""}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* <------- STEPPER ENDS --------> */}

        {/* <------- FORMS STARTS --------> */}

        <div className={classNames("container", style.boxBorder)}>
          {activeStep === 0 && (
            <Step1 handleNext={handleNext} data={risksAttributes[activeStep]} />
          )}

          {activeStep === 1 && (
            <Step2 handleNext={handleNext} handleBack={handleBack} />
          )}

          {activeStep === 2 && (
            <Step3
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={risksAttributes[1]}
            />
          )}

          {activeStep === 3 && (
            <Step4
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={risksAttributes[2]}
              ose={risksAttributes[3]}
              ceu={risksAttributes[4]}
              icu={risksAttributes[5]}
            />
          )}

          {activeStep === 4 && (
            <Step5
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={risksAttributes[activeStep]}
            />
          )}

          {activeStep === 5 && (
            <Check
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={risksAttributes[activeStep]}
            />
          )}

          {activeStep === 6 && (
            <Step6
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              data={risksAttributes[activeStep]}
            />
          )}
        </div>
        {/* <------- FORMS ENDS --------> */}
      </div>
    </div>
  ) : null;
};

const steps = [
  {
    label: "Choose your cover",
    title: "Cyber SME Business",
    sub: "Choose a limit of indemnity between R 250 000 and R10 000 000",
  },
  {
    label: "Get a quote",
    title: "Cyber SME Business",
    sub: "Select a quote",
  },
  {
    label: "Cyber overview",
    title: "Cyber SME Business",
    sub: "Let us determine your Cyber needs",
  },
  {
    label: "IT environment",
    title: "Cyber SME Business",
    sub: "Choose your IT environment, then answer the questions posed",
  },
  {
    label: "Company details",
    title: "Cyber SME Business",
    sub: "Add your details",
  },
  {
    label: "Application Review",
    title: "Cyber SME Business",
    sub: "Check",
  },
  {
    label: "Payment details",
    title: "Payment details",
    sub: "Add your details",
  },
];

export default Form;
