// default imports for react project
import React from "react";

// default import for routing in react
import { Link } from "react-router-dom";

// import default styles from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import classname to combine module css and normal css
import classNames from "classnames";

// Import icon and images from assets folder

import { logofooter, hollard } from "assets/images/images";

// import custom component
import { Href,RedirectToExternal } from "components/Heading";

// import stylesheet
import Style from "./Navbar.module.css";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";
import { useSelector } from "react-redux";
import CookieBanner from "../../screens/Cookies/cookies";

// React functional component starts here..
function Footer() {
  // Get width from util
  const { width } = useWindowDimensions();

  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );
  const isCookies = useSelector((state) => state?.auth?.isCookies);
  const secondaryLogo = useSelector((state) => state?.auth?.secondaryLogo);
  const tertiaryLogo = useSelector((state) => state?.auth?.tertiaryLogo);
  const agentLogo = useSelector((state) => state?.auth?.agentLogo);

  // return jsx to display on screen
  return (
    <div
      className={classNames(
        width > 770 ? Style.footer_container : Style.footer_container_xs
      )}
    >
      <Container>
        <Row>
          <Col
            lg={2}
            xs={12}
            className={classNames(`${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`)}
            style={
              width > 770
                ? { transform: "translateY(-20px)" }
                : { width: "70%" }
            }
          >
            {(secondaryLogo !== null && secondaryLogo.trim() !== "") ? (
              <>
                <img
                  src={secondaryLogo}
                  alt="secondary_logo"
                  width={width > 770 ? "170px" : "162px"}
                  className="secondary_logo"
                  // className={classNames(
                  //   `${width > 770 ? "footer_logo_lg" : "footer_logo mb-2"}`
                  // )}
                />
                <div style={{ fontSize: "10px" }}>
                  {agentLogo?.agentLogoText ?? ""}
                </div>{" "}
              </>
            ) : (
              <>
                <Link to="/">
                  <img
                    src={logofooter}
                    alt="iTOO"
                    width={width > 770 ? "170px" : "162px"}
                    className={classNames(
                      `${width > 770 ? "footer_logo_lg" : "footer_logo mb-2"}`
                    )}
                  />
                </Link>
                <div style={{ fontSize: "10px" }}>
                  ITOO Special Risks (Pty) Ltd,
                  <br />
                  Reg. No. 2016/281463/07, is an <br /> authorised Financial
                  Services <br />
                  Provider, FSP No. 47230
                </div>
              </>
            )}
          </Col>
          <Col lg={2} md={6} xs={6} className={classNames("p-0")}>
            <div
              style={{ fontSize: "15px" }}
              className={classNames(Style.footer_header)}
            >
              Let Us Help You
            </div>
            <div style={{ fontSize: "12px" }}>
              <Href link="/faq">FAQs</Href>
              <RedirectToExternal href="/disclosure">
                Disclosures
              </RedirectToExternal>
              <br />
              <RedirectToExternal
                href="https://itoo.co.za/legal-documents"
                target="_blank"
              >
                Legal & Compliance
              </RedirectToExternal>
            </div>
          </Col>
          <Col lg={2} md={6} xs={6}>
            <div
              style={{ fontSize: "15px" }}
              className={classNames(Style.footer_header)}
            >
              Get to Know us
            </div>
            <div style={{ fontSize: "12px" }}>
              <Href link="/contact">Contact</Href>
              <Href link="/about">About</Href>
              <Href link="/forms">Buy Insurance</Href>
              <Href link="/claims">Submit Claim</Href>
            </div>
          </Col>
          <Col
            lg={3}
            xs={12}
            className={classNames(width < 770 ? "mt-5 p-0" : "")}
            style={{ transform: width > 770 ? "translateX(10%)" : "0px" }}
          >
            <div
              style={width < 430 ? { fontSize: "15px", padding: 0 } : {}}
              className={classNames(Style.footer_header)}
            >
              Address
            </div>
            <div style={{ fontSize: "12px", paddingRight: "75px" }}>
              {companyDetails?.address
                ? companyDetails?.address
                : "PO Box 87419, houghton, 2041"}{" "}
              <br />
              {companyDetails?.contactNumber
                ? companyDetails.contactNumber
                : "+27 11 351 5000"}{" "}
              <br />{" "}
              {companyDetails?.contactEMail
                ? companyDetails.contactEMail
                : "info@itoo.co.za"}
            </div>
          </Col>
          <Col lg={2} xs={12} className={classNames(width < 770 ? "mt-5" : "")}>
            <Row>
              {(tertiaryLogo !== null && tertiaryLogo.trim() !== "") ? (
                <>
                  <div className={classNames("p-0 pt-0 pb-1")}>
                    <img
                      className="tertiary_logo"
                      src={tertiaryLogo}
                      alt="tertiary_logo."
                    />
                  </div>
                  <span className="p-0" style={{ fontSize: "10px" }}>
                    {" "}
                    {agentLogo?.agentLogoText ?? ""}
                  </span>
                </>
              ) : (
                <>
                  <div className={classNames("p-0 pt-0 pb-1")}>
                    <img src={hollard} alt="Hollard." width="120px" />
                  </div>
                  <span className="p-0" style={{ fontSize: "10px" }}>
                    {" "}
                    Underwritten by The Hollard Insurance Company Limited (Reg.
                    No. 1952/003004/06),
                    <br /> a Licensed Non-Life Insurer and an authorised
                    Financial Services Provider.
                  </span>
                </>
              )}
            </Row>
          </Col>
        </Row>
        {isCookies === false ? <CookieBanner /> : <></>}
        {/* {process.env.REACT_APP_ENV_TYPE === "DEV" ? (
          <> {isCookies === false ? <CookieBanner /> : <></>}</>
        ) : null} */}
      </Container>
    </div>
  );
}

export default Footer;
