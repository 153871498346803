import axios from "axios";
import { store } from "store/store";
import { toast } from "react-toastify";
import CONSTANTS,{ PRODUCTION_CONSTANTS } from "utils/constants";

//********************************* NOTES **********************************//
// 1. Please refer the TYPE from the according reducers.                    //
// 2. All the actions will be exporting to the components.                  //
// 3. Each action may call API Calls and returning the response to store.   //
//********************************* NOTES **********************************//

export const clearState = () => async (dispatch) => {
  // Dispatching to clear all the values in the store
  dispatch({
    type: "CLEAR_STATE",
  });
};

export const UpdateSteps = (payload) => async (dispatch) => {
  // Dispatching --> update the activeStep in the store
  dispatch({
    type: "UPDATE_STEP",
    payload,
  });
};


export const updateCustomAttributes = (payload) => (dispatch) => {
  dispatch({
    type: payload.type,
    payload: payload.payload,
  });
};

export const getProductRisks = () => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // Calling the product risk API
      const response = await axios.get(
       `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRisks/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY, 
          token,
        },
      }
    );
    // Calling the bank details
      const response1 = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getLookupItemsList/${CONSTANTS.BANK_BRANCH_GUID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Dispatching ----> response data to the store
    // Refer the types withe reducer
    dispatch({ type: "PRODUCT_RISKS", payload: response.data.data });
    dispatch({ type: "BANK_OPTIONS", payload: response1.data.data });
  } catch (e) {}
};

export const getProductRisksAttributes =
  (risksInstanceID) => async (dispatch) => {
    try {
      // product risk from the store
      const risks = await store.getState().products.risks.risks;

      // JWT token from the store
      const token = await store.getState().auth.jwtToken;

      // Calling specific risk attributes 
        const response = await axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );

      // Consolidating and dispatching the values & response to the store
      var risk = risks.find(
        (find) => find.instanceId === response.data.data.risk.instanceId
      );
      var obj = { ...risk, attributes: response.data.data.attributes };


       // Dispatching ----> response data to the store
      dispatch({ type: "PRODUCT_RISKS_ATTRIBUTES", payload: obj });
      dispatch({ type: "PRESISTED_PRODUCT_RISKS_ATTRIBUTES", payload: obj });
    } catch (e) {}
  }; 

export const getProductRisksAttributesWithPresist =
  (risksInstanceID) => async (dispatch) => {
    try {
      // JWT token from the store
      const token = await store.getState().auth.jwtToken;

      // Calling specific risk attributes 
        return axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      ).then((response) => {
        return response;
      })
    } catch (e) {}
  };



export const getProductTypeDetails = () => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // Calling the product types
      const response = await axios.get(
      `${CONSTANTS.MIDDLEWARE_URL}/products/getProductTypes/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
     // Dispatching ----> response data to the store
    dispatch({ type: "PRODUCT_TYPES", payload: response.data.data });
  } catch (err) {}
};

export const executeCalculator = (payload) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;
    
    // formulating the next request body for the cover amount
    const nextAttributes = await store.getState().products.riskAttributes[0]
      .attributes[2];

    // Finding the particular element from the attributes
    const elementIndex = JSON.parse(nextAttributes.settings).items.findIndex(
      (ele) => ele === payload.risks[0].attributes[2].value
    );

    // Calling the execute calculator
      const response = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/calculator`,
      payload,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    // Storing the BUD attributes and request body to store for validating the Questions
    dispatch({
      type: 'STORE_BUB_REQUESTED_BODY',
      payload: payload.risks[0].attributes
    })

   // Applying the changes and calling the next iterated execute calculators
    var arr = [...payload.risks[0].attributes];
    arr[2] = {
      ...arr[2],
      value: JSON.parse(nextAttributes.settings).items[elementIndex + 1],
    };

    if (JSON.parse(nextAttributes.settings).items[elementIndex + 1]) {
      const response1 = await axios.post(
        `${CONSTANTS.MIDDLEWARE_URL}/calculator`,
        {
          factors: [],
          risks: [
            {
              attributes: arr,
              riskReference: payload.risks[0].riskReference,
              code: "BUD",
            },
          ],
          productTypeReference: payload.productTypeReference,
        },
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );
      // Dispatching the responses of the two execute calculator and spreades the request body to access the cover amount for future uses.
      return dispatch({
        type: "PRODUCT_OPTIONS",
        payload:{
          ...response.data.data,
          productOptions: response.data.data.productOptions?.map((ex) => ({
            ...ex,
            body: payload,
          })),
          requestBody: payload,
        },
        payload1: {
          ...response1.data.data,
          productOptions: response1.data.data.productOptions?.map((ex) => ({
            ...ex,
            body: {
              factors: [],
              risks: [
                {
                  attributes: arr,
                  riskReference: payload.risks[0].riskReference,
                  code: "BUD",
                },
              ],
              productTypeReference: payload.productTypeReference,
            },
          })),
          requestBody: {
            factors: [],
            risks: [
              {
                attributes: arr,
                riskReference: payload.risks[0].riskReference,
                code: "BUD",
              },
            ],
            productTypeReference: payload.productTypeReference,
          },
        },
      });
    }

    return dispatch({
      type: "PRODUCT_OPTIONS",
      payload: {
        ...response.data.data,
        productOptions: response.data.data.productOptions.map((ex) => ({
          ...ex,
          body: payload,
        })),
        requestBody: payload,
      },
    });
  } catch (err) {
    throw err;
  }
};

export const updateValues = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  var arr = [...riskAttributes];
  arr[0] = {
    ...arr[0],
    attributes: payload.attributes,
    startDate: payload.startDate,
  };
  // Dispatching ----> data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};

export const updateIEDAttributes = (payload) => async (dispatch) => {
  // Dispatching ----> data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload });
};

export const updateStepValue = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  const elementIndex = riskAttributes?.findIndex(
    (ele) => ele.code === payload.code
  );
  var arr = [...riskAttributes];
  arr[elementIndex] = { ...arr[elementIndex], attributes: payload.attributes };
  // Dispatching ---->  data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};

export const CreatePolicy = (payload) => async (dispatch) => {
  try {
    // Dispatching ----> response data to the store
    dispatch({ type: "CREATE_POLICY", payload });
  } catch (err) {}
};

export const selectProduct = (payload) => async (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({ type: "SELECT_PRODUCT", payload });
};

export const updateSelectedOption = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "SELECTED_OPTION",
    payload,
  });
};

export const updateCompanyDetails = (payload) => (dispatch) =>
  [
     // Dispatching ---->  payload to the store
    dispatch({
      type: "UPDATE_COMPANY_DETAILS",
      payload,
    }),
  ];

export const updateTryCount = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "UPDATE_TRY_COUNT",
    payload,
  });
};

export const issuePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the issue policy sale API
    const response = await axios.post(
    `${CONSTANTS.MIDDLEWARE_URL}/policy/issuePolicy`,

    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const sendOTP = (payload) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.jwtToken;

    // mobilePhone form the store
    const mobileNumber = await store.getState().products.companyDetails
      .mobilePhone;

    // Calling the SendOTP API
    const response = await axios.post(
      `${CONSTANTS.BASEURL}/pl/policies/otp/sendOTP`,
      {
        ...payload,
        productId: CONSTANTS.PRODUCT_INSTANCE_ID, // product instance ID from the constance
        mobilePhoneCode: "+27",
        mobilePhone: mobileNumber,
      },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    toast.success("OTP Sent successfully.");
    return response;
  } catch (err) {
    toast.error(err?.response?.data || "Error occured. Please try again !");
  }
};

export const validateOTP = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the Validate API
  const response = await axios.post(
    `${CONSTANTS.BASEURL}/pl/policies/otp/validateOTP`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response.data;
};

export const makeExternalPayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the external payment API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/payments/submitExternalPayment`
      :
    `${CONSTANTS.BASEURL}/pl/policies/payments/submitExternalPayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const makePolicySalePayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

  // Calling the makePolicySalePayment API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/makePolicySalePayment`
      :
    `${CONSTANTS.BASEURL}/pl/policies/sale/makePolicySalePayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const activatePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.jwtToken;

   // Calling the activatePolicy API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/activatePolicySale`
      :
    `${CONSTANTS.BASEURL}/pl/policies/sale/activatePolicySale`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};



export const checkTC = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_T&C',
    payload
  })
}