// default imports for react project
import classNames from "classnames";
import React from "react";

// default import for routing in react
import { useHistory } from "react-router";

import Style from "./Button.module.css";

// React functional component starts here..
function BodyButton({
  name,
  color,
  bg,
  size,
  marginL,
  weight,
  width,
  marginT,
  black,
  align,
  link,
  id
}) {
  // initialized history using react hook used to navigate
  const history = useHistory();

  // function to redirect page
  const handleClick = () => {
    history.push(link);
  };

  // return jsx to display on screen
  return (
    <button
      style={{
        background: `${bg}`,
        color: `${color}`,
        borderRadius: "5px",
        fontSize: size,
        width:  width ? width : "65%",
        marginLeft: marginL,
        fontWeight: weight,
        cursor: "pointer",
        marginTop: marginT,
        textAlign: "center",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className={classNames("px-2 py-2 d-flex", Style.body__btn__hover)}
      onClick={handleClick}
      name={id ?? name}
      aria-label={id ?? name}
    >
      {name}
    </button>
  );
}

export default BodyButton;
