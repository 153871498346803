/* eslint-disable */

// <----- API URL  -------> 
export const APIUrl = {
    login: '/lg/authorisation/login',
};

// <----- Environment types  -------> 
export const ENV_TYPE = {
    DEV: 'dev',
    PROD: 'prod',
    UAT: 'uat'
};

// <----- Environments  -------> 
export const ENV = {
    ['dev']: 'https://briisk-i-dev-api.azure-api.net',
    ['uat']: 'https://briisk-i-uat-api.azure-api.net',
    ['prod']: 'https://briisk-i-prod-api.azure-api.net',
};


export const OCP_APIM_SUBSCRIPTION_KEY = {
    ['dev']: 'ec2659d3859d421d801776e4efad5780',
    ['uat']: '3296a582dbb540b8ae4843332c638a3e',
    ['prod']: 'd6cd16e3f619492bbc3f75fccf0aba6c'
}
  
export const PRODUCT_INSTANCE_ID = {
    ['dev']: '38034826-d378-2cf4-95da-224ff90662d7',
    ['uat']: '28FE00E9-F194-4731-BCA6-9539776F925C',
    ['prod']: 'ce885e22-aa0c-47fc-97e4-e4431a5c3cd6'
}

export const AUTH_EMAIL = {
    ['dev']: 'briisk@itoo.co.za',
    ['uat']: 'cybersme@itoo.co.za',
    ['prod']: 'cybergo@briisk.io'
}

export const AUTH_PASSWORD = {
    ['dev']: 'Br!!5k3105',
    ['uat']: 'Cyb3r5M32021',
    ['prod']: 'VDhr6wS7ZfRG.Qd'
}

// export const IVERY_BASEURL = {
//     ['dev']: 'https://portal.iveri.net/Lite/Authorise.aspx',
//     ['uat']: 'https://portal.iveri.net/Lite/Authorise.aspx',
//     ['prod']: 'https://portal.iveri.net/Lite/Authorise.aspx'
// }
  
// export const LITE_MERCHANT_APP_ID = {
//     ['dev']: '753B138D-3720-4744-B075-89DF833433BC',
//     ['uat']: '753B138D-3720-4744-B075-89DF833433BC',
//     ['prod']: 'DB8DCC50-ACB4-45E1-B8A7-8451C50AE387'
// }

export const IVERY_BASEURL = {
    ['dev']:  'https://portal.iveri.net/Lite/Authorise.aspx',//'https://portal.iveri.net/Lite/Authorise.aspx',
    ['uat']:  'https://portal.nedsecure.co.za/Lite/Authorise.aspx',
    ['prod']: 'https://portal.nedsecure.co.za/Lite/Authorise.aspx'
}
  
export const LITE_MERCHANT_APP_ID = {
    ['dev']:  '753B138D-3720-4744-B075-89DF833433BC', //'753B138D-3720-4744-B075-89DF833433BC',
    ['uat']:  'FFE710FD-A540-4304-B296-0814EB8D7D25', //'753B138D-3720-4744-B075-89DF833433BC',
    ['prod']: '7a0fa866-d79d-4ce9-8ba7-232ae494463c'
}

export const BANK_BRANCH_GUID = {
    ['dev']: 'F5D22457-30FC-8FC4-90DB-BC43DC258464',
    ['uat']: '201413A3-4410-B6C1-498E-7FD5D7100874',
    ['prod']: '7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01'
}


export const NETWORK_ID = {
    ['dev']: '96d8b9ec-ae4e-4108-9a3b-52260d695e6b',
    ['uat']: 'f696fd88-3c18-debf-5b04-b3447caa076d',
    ['prod']: '032208dd-c8f4-50d4-c89f-371badc366f0' // Need to be updated
}

  
export const COMPANY_ID = {
    ['dev']: 'bf685a12-658c-eed5-4318-5965fecd1e71',
    ['uat']: '3B6B26B7-C881-2FBF-A0AA-2BDF3A1C045B',
    ['prod']: '2E219BBC-52ED-42FE-9A37-02849FD37E24'
}

export const PAYMENT_CONFIGURATION_KEY = {
    ['dev']: 'bd46596c-6562-4f7a-96d4-1e8c058da19a',
    ['uat']: '1e8e383c-df6c-469f-ab85-91f48bcecd4f' ,
    ['prod']: '2ab00e39-bce2-4443-b8af-407c5346d17d' 
}

export const MIDDLEWARE_URL = {
    // ['dev']: 'http://localhost:5000',
    ['dev']: 'https://react-middleware-dev.azurewebsites.net',
    ['uat']: 'https://react-middleware-uat.azurewebsites.net',
    ['prod']: 'https://react-middleware-prod.azurewebsites.net',
//   ['prod']: 'http://localhost:8000', 

}

export const RECAPTCHA_SITE_KEY = {
    ['dev']: '6Lf-wCIeAAAAAGlU5br2OWXU0VS9C2BjR2i0HvTA',
    ['uat']: '6LfwwSIeAAAAAGADWlrwmETdNbtq_ypIE6Yo-Xp-',
    ['prod']: '6Lfs9KUfAAAAAEavfxSUniMbLg9Po-akQ61BZNrX' // Cyber LIVE
}


/* eslint-disable */

export const SITE_URL = {
  ["dev"]: "https://react-cyber-sme-dev.azurewebsites.net",
  ["uat"]: "https://react-cyber-sme-uat.azurewebsites.net",
  ["prod"]: "https://cyber.thinkexpert.co.za",
};