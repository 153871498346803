// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col } from "react-bootstrap";

// import custom component
import { Divider, VerticalSpacer } from "../index";
import Button from "../Buttons/Button";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import style from "./ProductCard.module.css";
import { toast } from "react-toastify";


// React functional component starts here..
const ProductCard = ({
  title,
  sub,
  price,
  coverAmount,
  prorata,
  onPress,
  turnover,
  disabled,
  excess,
  containerStyle,
  description,
  firstCollectionAmount
}) => {
  // return jsx to display on screen
  return (
    <div
      className={classNames(style.container)}
      style={containerStyle && containerStyle}
    >
      <h3 className={style.title}>{title}</h3>
      <h6 className={style.sub}>{sub}</h6>
      <h5 className={style.price}>{price}</h5>

      <Divider />
      <VerticalSpacer rows={1} />

      <p className={style.cover}>Cover amount: {coverAmount}</p>
      <p className={style.turnover}>Turnover: {turnover}</p>
      <p className={style.excess}>Excess: {excess}</p>

      <Divider />

      <p className={style.description}>{description}</p>
      {!disabled ? (
        <Col sm={4} className="mt-4">
          <Button
            border="1px solid #D0D0D0"
            onClick={onPress}
            shadow={false}
            name="Select quote"
            bg="white"
            color="#DA291C"
            id={`quote-button-${title}`}
          />
        </Col>
      ) : <>
       <Button
            border="1px solid #D0D0D0"
            onClick={onPress}
            shadow={false}
            name="Select quote"
            bg="lightgray"
            color="#DA291C"
            id={`quote-button-${title}`}
          /> 
      </>}
    </div>
  );
};

export default ProductCard;
