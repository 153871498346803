// <------------ Images from the assets ----------->
import {
  CheckStep_Not_Eligible,
  CheckStep_Eligible,
} from "assets/images/images";

// <------------ Essential components ----------->
import { Header, VerticalSpacer, Divider } from "components";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";

// <-------- React --------->
import React, { useEffect, useState } from "react";

// <-------- React bootstrao --------->
import { Col, Row } from "react-bootstrap";

// <-------- Redux hooks --------->
import { useSelector } from "react-redux";
import useWindowDimensions from "utils/useWindowDimension";

const Check = ({ handleBack, handleNext }) => {
  // Risk attributes from the Product.
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  // variable defines the boolean state
  const [isValid, setIsValid] = useState(false);

  const { width } = useWindowDimensions();

  const policyResponse = useSelector((state) => state.products.policyResponse);

  useEffect(() => {
    // Consolidating the risk attributes into an array which checks the form is valid or not
    // This functions or code needs to be updated in the future as per scope.
    var arr = [
      ...riskAttributes[1].attributes,
      ...riskAttributes[3].attributes,
      ...riskAttributes[4].attributes,
      ...riskAttributes[5].attributes,
    ];
    if (
      arr
        .filter((filter) => filter.value)
        .some((some) => some.value.toLowerCase() === "no")
    ) {
      setIsValid(false);
    } else {
      if (riskAttributes[0].attributes[0].value.includes("*")) {
        setIsValid(false);
      } else {
        setIsValid(true);
        handleNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskAttributes]);

  return (
    <div>
      {/* <--------- Header -----------> */}
      <h4>Application Review</h4>

      {/* <--------- Divider -----------> */}
      <Divider />
      <VerticalSpacer rows={2} />

      {/* <----------- Validating the form -----------> */}
      {isValid ? (
        <div>
          <Header
            titleSize={22}
            title="Congratulations! "
            sub="You are eligible for the insurance cover. Please proceed to complete the payment."
          />
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="mt-5"
              style={{ height: "30vh" }}
              src={CheckStep_Eligible}
              alt="Check Step - Eligible"
            />
          </div>
        </div>
      ) : (
        <div>
          <Header
            titleSize={22}
            title="Thank you for your submission."
            sub={`We need to review some of the answers you have provided. One of our brokers will contact you as soon as possible  to complete your application and get you covered. Your reference number is ${policyResponse?.policies[0]?.policyReference}`}
          />
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="mt-5"
              style={{ height: "30vh" }}
              src={CheckStep_Not_Eligible}
              alt='"Check Step - Not Eligible'
            />
          </div>
        </div>
      )}

      <VerticalSpacer rows={4} />

      {/* <------- Navigating buttons Starts ------> */}
      <Row className="mt-5">
        <Col xs={6} md={2} className="d-flex justify-content-start">
          <ButtonWithDownArrow
            onClick={handleBack}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
            id="form-6-back-button"
          />
        </Col>
        {width > 430 && <Col xs={0} md={8} />}
        {isValid && (
          <Col xs={6} md={2} className="d-flex justify-content-end">
            <ButtonWithDownArrow
              onClick={handleNext}
              type="right"
              name="Next"
              bg="#dd302a"
              color="white"
              id="form-6-next-button"
            />
          </Col>
        )}
      </Row>
      {/* <------- Navigating buttons Ends ------> */}
    </div>
  );
};

export default Check;
