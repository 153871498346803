import classNames from 'classnames';
import { VerticalSpacer,Divider } from 'components';
import React from 'react';
import useWindowDimensions from 'utils/useWindowDimension';

// import stylesheet
import style from "./ProductCard.module.css";

const DebitOrderCard = ({
    containerStyle,
    title,
    price,
    LPA,
    policyStartDate,
    policyEndDate,
    debitDate,
    policyRenewalDate,
    proratePremium,
    monthlyProratePremium
}) => {


    const {width}  = useWindowDimensions();

    return(
        <div
            className={classNames(style.container)}
            style={containerStyle && containerStyle}
        >
            <h3 className={style.title}>{title}</h3>
            <div className={`d-flex justify-content-between ${width > 550 ? 'flex-row  align-items-center' : 'flex-column  align-items-start'}`}>
              <h6 className={style.sub}>Pro rata premium for the first month</h6>
              <h5 className={style.price}>{price}</h5>
            </div>

            <div className={classNames("d-flex mt-3 justify-content-between", style.policySecondaryContainer)}>
                <h6 className={style.policySecondary}>Pro Rata Premium: </h6>
                <h6 className={style.policySecondary}>R {proratePremium}</h6>
            </div>

            <div className={classNames("d-flex justify-content-between", style.policySecondaryContainer)}>
                <h6 className={classNames(style.policySecondary, 'm-0 p-0')}>Monthly Premium: </h6>
                <h6 className={style.policySecondary}>R {monthlyProratePremium}</h6>
            </div>

            <div className={classNames("d-flex justify-content-between", style.policyPrimaryContainer)}>
                <h6 className={style.policyPrimary}>First Debit Date: </h6>
                <h6 className={style.policyPrimary}>{debitDate}</h6>
            </div>
            <p  className={style.spanText}>*and then monthly there after</p>

            <VerticalSpacer rows={2} />
            <Divider />

            <div className={classNames("d-flex mt-3 justify-content-between", style.policyPrimaryContainer)}>
                <h6 className={style.policyPrimary}>Policy Start Date: </h6>
                <h6 className={style.policyPrimary}>{policyStartDate}</h6>
            </div>

            <div className={classNames("d-flex justify-content-between", style.policySecondaryContainer)}>
                <h6 className={style.policySecondary}>Policy End Date: </h6>
                <h6 className={style.policySecondary}>{policyEndDate}</h6>
            </div>

            <div className={classNames("d-flex justify-content-between mb-3", style.policySecondaryContainer)}>
                <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
                <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
            </div>

            <Divider />

        </div>
    )
}

export default DebitOrderCard;