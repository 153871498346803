import React, { useCallback, useEffect, useState } from "react";

// <--------- MUI Components --------->
import { Divider } from "@mui/material";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <--------- Essential Components -------->
import {
    Question,
    Select,
    TextInput,
} from "../../../components";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import validate from "validate.js";
import useWindowDimensions from "utils/useWindowDimension";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";

// <---------- Actions  ----------->
import { updateStepValue } from "store/actions/products";




const Step3 = ({ handleNext, data, handleBack }) => {

    // Risk attributes (local state)
    const [attributes, setAttributes] = useState([]);

    // Risk attributes from the store
    const riskAttributes = useSelector((state) => state.products.riskAttributes);
    
    // Redux hooks for dispatching
    const dispatch = useDispatch();

    // Constraints state for validating the forms 
    const [constraints, setConstraints] = useState({});

    // Dynamic width from screens dimensions
    const { width } = useWindowDimensions();

    useEffect(() => {
        // Setting up the risk attributes from store
        setAttributes(
            data?.attributes.map((d) => ({
                ...d,
                value: d.value ? d.value : null
            }))
        );

        // Setting up the risk attributes constraints for form validations
        var obj = {};
        if(data){
            data.attributes.forEach((ex, i) => {
                if(ex.isRequired){
                    obj = {...obj, [ex.name.replaceAll(" ", "")] : {	presence: {allowEmpty: false}} }
                }
                if(i === data.attributes.length -1){
                    setConstraints(obj);
                }
            })
        }
    }, [data]);


    // Handling synthetic events
    const handleChange = (evt, instanceID) => {
        const elementIndex = attributes?.findIndex(
            (ele) => ele.instanceId === instanceID
        );
        var arr = [...attributes];
        arr[elementIndex] = {
            ...arr[elementIndex],
            value:
                evt.target.value === "YES"
                    ? "Yes"
                    : evt.target.value === "NO"
                        ? "No"
                        : evt.target.value,
            errors: arr[elementIndex]?.value && []
        };
        // storing the values to the local state
        setAttributes(arr);

        // Dispatching the values to the store
        dispatch(
            updateStepValue({
                code: data.code,
                attributes: arr
            })
        );
    };

    // Dynamic fields by datatype
    const renderComponent = useCallback(
        (type) => {
            switch (type.dataType) {
                case 2:
                    return (
                        <Select
                            label={type.description}
                            errors={type.errors}
                            required={false}
                            value={type.value || ""}
                            placeholder={type.description}
                            options={JSON.parse(type?.settings)?.items}
                            onChange={(evt) => handleChange(evt, type.instanceId)}
                        />
                    );

                case 7:
                    return (
                        <Question
                            vertical={true}
                            errors={type.errors}
                            detailed={type.name.includes("CYR") && type.value === "No"}
                            question={type.description}
                            label={
                                attributes.filter(
                                    (filter) =>
                                        filter.name.slice(
                                            filter.name.length - 8,
                                            filter.name.length - 1
                                        ) ===
                                        type.name.slice(type.name.length - 7, type.name.length)
                                )[0]?.description
                            }
                            required={false}
                            value={type.value}
                            placeholder={type.description}
                            options={JSON.parse(type?.settings)?.items}
                            detailedValue={
                                attributes.filter(
                                    (filter) =>
                                        filter.name.slice(
                                            filter.name.length - 8,
                                            filter.name.length - 1
                                        ) ===
                                        type.name.slice(type.name.length - 7, type.name.length)
                                )[0]?.value
                            }
                            onChange={(evt) => handleChange(evt, type.instanceId)}
                            detailedErrors={ attributes.filter(
                                (filter) =>
                                    filter.name.slice(
                                        filter.name.length - 8,
                                        filter.name.length - 1
                                    ) ===
                                    type.name.slice(type.name.length - 7, type.name.length)
                            )[0]?.errors}
                            onDetailedChange={(evt) =>
                                handleChange(
                                    evt,
                                    attributes.filter(
                                        (filter) =>
                                            filter.name.slice(
                                                filter.name.length - 8,
                                                filter.name.length - 1
                                            ) ===
                                            type.name.slice(type.name.length - 7, type.name.length)
                                    )[0]?.instanceId
                                )
                            }
                        />
                    );
                case 1:
                    return (
                        <TextInput
                            errors={type.errors}
                            required={false}
                            placeholder={type.description}
                            label={type.description}
                            value={type.value || ""}
                            onChange={(evt) => handleChange(evt, type.instanceId)}
                        />
                    );
                default:
                    return;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [attributes, riskAttributes]
    );


    // Functions performs submitting the form or step
    const handleSubmit = (evt) => {

        evt.preventDefault();

        // <---- Consolidating Errors values ---->
        var values = {};
        attributes.filter((f) => f.isRequired).forEach((ex, i) => {
          if(ex.isRequired){
            values = {...values, [ex.name.replaceAll(' ', '')]: ex.value}
          }
        })

        // <---- Errors check ---->
        const errors = validate(values, constraints);
        if(errors || errors !== undefined) {
            setAttributes(
              attributes.map((ex) => ({...ex, errors: errors[ex.name.replaceAll(' ', '')] }))
            )
            return;
        }else{
            setAttributes(
                attributes.map((ex) => ({...ex, errors: "" }))
              )
        }

        var attri = attributes.map((y) => ({...y, errors: null}));
        setAttributes(attri);
        dispatch(
          updateStepValue({
            code: data.code,
            attributes: attri,
          })
        )
        handleNext();
    }

    return (
        <div>
            {/* <---------- Title -------> */}
            <h4>{data?.name}</h4>

            {/* <------- Sub title ------> */}
            <h6>{data?.description}</h6>

            <Divider row={2} />

            {/* <------- Attributes Starts ------> */}
            <form onSubmit={handleSubmit}>
                <Row>
                    {attributes
                        ?.filter((filter) => filter.settings !== "null")
                        .map((d, idx) => (
                            <Col key={idx} sm={6}>{renderComponent(d)}</Col>
                        ))}
                </Row>
           
            {/* <------- Attributes Starts ------> */}


            {/* <------- Navigating buttons Starts ------> */}
            <Row className="mt-5">
                <Col xs={6} 
                    md={2} 
                    className="d-flex justify-content-start"
                >
                    <ButtonWithDownArrow
                        onClick={handleBack}
                        type="left"
                        name="Back"
                        bg="#dd302a"
                        color="white"
                        id={'form-3-back-button'}
                    />
                </Col>
               {width > 430 && <Col xs={0}  md={8} />}
                <Col 
                    xs={6} 
                    md={2} 
                    className="d-flex justify-content-end"
                >
                    <ButtonWithDownArrow
                        buttonType="submit"
                        type="right"
                        name="Next"
                        bg="#dd302a"
                        color="white"
                        id={'form-3-next-button'}
                    />
                </Col>
            </Row>
            </form>
            {/* <------- Navigating buttons ends ------> */}
        </div>
    );
};

export default Step3;
