export const topcardData = {
  header:
    "Cyber iTOO Go Insurance is a product available to South African SMEs. Once you have purchased the insurance you will be covered and will instantly receive your Policy Schedule and Terms & Conditions via email.",
  header2: "Here's the reality of cyber attacks in South Africa:",
  content: [
    {
      title: "Malware is on the rise",
      answer: "93% increase of ransomware attacks in 2021.",
      ref: "Check Point 2021 Mid Year Report /",
      refNo: 1,
    },
    {
      title: "SMEs in the crosshairs",
      answer:
        "63% reported experiencing a data breach in the previous 12 months.",
      ref: "2019 study by Keeper  Security and the Ponemon Institute /",
      refNo: 2,
    },
    {
      title: "Remote working threats",
      answer:
        "22% of SMEs switched on remote working without a threat prevention plan.",
      ref: "Research from Alliant Cybersecurity and Cyber Resilient Organization study by Resilient and Ponemon/",
      refNo: 3,
    },
    {
      title: "Difficult to recover",
      answer:
        "39% don’t have an incident response plan, believed 66% of SMEs would not be capable of recovering from a cyber attack.",
      ref: "2019 data  from Keeper Security and the Ponemon Institute/",
      refNo: 4,
    },
    {
      title: "Passwords still a key risk",
      answer: "70% of SMEs employees passwords were stolen or lost.",
      ref: "Ponemon Cost of a Data Breach study 2021",
      refNo: 5,
    },
    {
      title: "Average cost in S.A",
      answer:
        "The average cost per stolen or lost record in South Africa is R 2 412.",
      refNo: 6,
    },
    {
      title: "Time to identify",
      answer:
        "Avg approx. 287 days to detect a breach and additional 75 days to contain.",
      refNo: 6,
    },
  ],
};

export const accordion1Data = {
  header:
    "Far broader than the name cyber implies, our policy extends to cover numerous incidents including but not limited to:",
  content: [
    {
      title: "Cyber extortion and malware",
      answer: "Viruses, ransomware, or publishing of stolen data.",
    },
    {
      title: "Denial of service",
      answer: "Disruption to operations.",
    },
    {
      title: "Downstream attack",
      answer:
        "A compromise of your environment resulting in damages to others.",
    },
    {
      title: "Hacking",

      answer: "Gaining of unauthorised access to systems and/or data.",
    },
    {
      title: "Insider and privilege misuse",

      answer:
        "Unauthorised access and use of systems and data by employees and service providers.",
    },

    {
      title: "Physical theft and loss",

      answer: "Both devices and physical hard copy data.",
    },
    {
      title: "Threats posed by third party access",

      answer: "Gaining of unauthorised access to systems and/or data via third party access into a client environment.",
    },
  ],
};

export const accordion2Data = {
  header:
    "Our comprehensive cyber insurance policy provides the following coverages:",
  section: [
    {
      header: "1ST",
      content: [
        {
          title: "Regulatory fines",
          answer:
            "To the extent insurable by law, fines imposed by a government regulatory body due to an information privacy breach.",
        },
        {
          title: "Business interruption",
          answer:
            "Loss of income and increased cost of working because of a systems security incident.",
        },
        {
          title: "Data restoration",
          answer:
            "Costs to restore, re-collect or replace data lost, stolen or corrupted due to a systems security incident.",
        },
        {
          title: "Theft of Funds",
          answer:
            "Unrecoverable loss of money, because of a system security incident by a third party.",
        },
        {
          title: "Physical Damage",
          answer:
            "Costs to replace or repair direct physical damage of property due to a system security incident.",
        },
      ],
    },
    {
      header: "3RD PARTY",
      content: [
        {
          title: "Privacy liability",
          answer:
            "Defence and settlement of liability claims arising from compromised information.",
        },
        {
          title: "Network security liability",
          answer:
            "Defence and settlement of liability claims resulting from a system security incident affecting systems and data as well as causing harm to third-party systems and data.",
        },
        {
          title: "Media liability",
          answer:
            "Defence and settlement of liability claims resulting from disseminated content (including social media content) including:",
          subAnswer: [
            "Defamation.",
            "Unintentional copyright infringement.",
            "Unintentional infringement of right to privacy.",
          ],
        },
      ],
    },
    {
      header: "INCIDENT RESPONSE",
      content: [
        {
          title: "Incident response costs",
          answer:
            "Costs to respond to a system’s security incident, including:",
          subAnswerType: "bulletin",
          subAnswer: [
            "To obtain professional (legal, public relations and IT forensics) advice, including assistance in managing the incident, co-ordinating response activities, making representation to regulatory bodies and coordination with law enforcement.",
            "To perform incident triage and forensic investigations, including IT experts to confirm and determine the cause of the incident, the extent of the damage including the nature and volume of data compromised, how to contain, mitigate and repair the damage, and guidance on measures to prevent reoccurrence.",
            "For crisis communications and public relations costs to manage a reputational crisis, including spokesperson training and social media monitoring.",
            "For communications to notify affected parties.",
            "For remediation services such as credit and identity theft monitoring to protect affected parties from suffering further damages.",
          ],
        },
      ],
    },
  ],
};

export const travelSuperiorPlan = [
  {
    title: "Why would my company be a target?",
    answer:
      "Attacks such as ransomware are indiscriminate and can affect any company and every industry. Smaller companies are often a target for hackers particularly if they are found to have less sophisticated IT infrastructure. Smaller companies can be severely impacted following a breach as they are required to absorb the high incident response costs. Compromises at larger companies tend to yield larger data sets for theft and break into the news, which can boost a hackers’ reputation.",
  },
  {
    title:
      "Can I protect my business without purchasing cyber insurance cover?",
    answer:
      "There are many ways to mitigate the risk of cyber threats such as staff education, encryption, sophisticated endpoint protection, bring-your-own-device policies and password policies, however, even the most diligent businesses can be exposed to a cyber-attack.",
  },
  {
    title:
      "We use state of the art protection, do we still need cyber insurance?",
    answer:
      "Having the latest technology, firewalls and encryption will reduce the risks of a breach occurring however, many cyber threats originate internally from employee mistakes (misplacing a laptop, or not disposing of confidential information securely). Having state of the art protection is not a 100% guarantee against an incident occurring.",
  },
  {
    title: "What if we use a cloud provider to store client data?",
    answer:
      "You are the custodian of the data and remain responsible for any data lost in a breach. Look to use a cloud service provider that can provide reasonable assurance that your data will be protected, however, there is still a chance your business could be held liable for data compromised from the cloud environment (the same would apply for other outsourced providers you make use of and share data with).",
  },
  {
    title: "Does any other liability policy provide cyber cover?",
    answer:
      "A cyber insurance policy provides the most comprehensive cover for system and data related risks.",
    subAnswer: [
      "A Professional Indemnity policy provides limited cover for third party data loss but only as it relates to the provision of professional services.",
      "A General Liability policy, as data is deemed intangible provides no cover.",
      "A Business Interruption policy requires physical damage to trigger the policy and incidents such as ransomware or hacking a server may reflect no physical damage.",
      "A Commercial Crime policy provides cover for first party financial loss only.",
      "A Directors & Officers policy will likely be triggered after a cyber breach but will not cover the business interruption, incident response or liability damages suffered by the company",
    ],
  },
];

export const productDet = [
  {
    header:
      "Far broader than the name cyber implies, our policy extends to cover numerous incidents including but not limited to:",
    content: [
      {
        title: "Cyber extortion and malware",
        answer: "viruses, ransomware, or publishing of stolen data.",
      },
      {
        title: "Denial of service",
        answer: "disruption to operations.",
      },
      {
        title: "Downstream attack",
        answer:
          "a compromise of your environment resulting in damages to others.",
      },
      {
        title: "Hacking",
        answer: "Gaining of unauthorised access to systems and/or data.",
      },
      {
        title: "Insider and privilege misuse",
        answer:
          "Unauthorised access and use of systems and data by employees and service providers.",
      },

      {
        title: "Physical theft and loss",
        answer: "both devices and physical hard copy data.",
      },
      {
        title: "Threats posed by third party access",
        answer: "Gaining of unauthorised access to systems and/or data via third party access into a client environment.",
      },
    ],
  },
  {
    header: "Payment options",
    content: "Annual or Monthly.",
  },
  {
    header: "Benefits",
    content: "Benefit limits from R 250 000 to R 10 000 000 available.",
  },
  {
    header: "Benefits table to be read in conjunction with your policy wording",
    content:
      "The information below is only a summary of the cover you have under your Cyber insurance policy and is provided to you for easy reference. Kindly refer to your policy wording for the full list of terms and conditions.",
  },
];
