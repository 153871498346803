// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

// import function from actions
import { addCrumb } from "store/actions/common";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H1Red, H3Red, H1Grey } from "components/Heading";
import Accordion from "components/Accordion";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { RedirectToExternal } from "components/Heading";


// React functional component starts here..
function Claims() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // react layout hook to perform init operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/claims", name: "Claims" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentToken =  useSelector(state => state.auth.currentUserToken)


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <div>
            <H1Grey style={{ fontFamily: "var(--primary-font)" }}>
              Cyber Insurance Claims
            </H1Grey>
            <br />
            <p className="font-weight-bold">
              {" "}
              Simple, transparent and effective
            </p>
          </div>
        </Row>
        <Row className={classNames("mt-5")}>
          <H1Red>Claims</H1Red>
          <div>
            <H3Red>Detailed claims process for brokers</H3Red>
            <br />
            Different policy types have different requirements for submissions.
            The speed of response is critical on a cyber insurance claim, as
            such please notify ITOO as soon as possible on the following contact
            details: <br />
            ITOO Cyber Assist Hotline: 0861 767 778 | 010 020 8712 | 087 110
            1665 | cyber_claims@ITOO.co.za
          </div>
          <Row className={classNames("mt-3")}>
            <Accordion title="Cyber Insurance Claims:">
              The following initial information will be required and must be
              submitted via your broker:
              <ol className={classNames("mt-3")}>
                <li className="font-weight-bold">Policy Number</li>
                <li className="font-weight-bold">Contact details of Insured</li>
                <li className="font-weight-bold">
                  Circumstance/Letter of Demand/Summons
                </li>
                <ol>
                  <li>
                    <span className="font-weight-bold">
                      If a Circumstance which may lead to a claim/Regulatory
                      Body complaint:
                    </span>
                    <br />
                    <ol>
                      <li>
                        Detailed description of the matter from Insured which
                        should include at least the following:
                        <br />
                        - Date of Incident
                        <br />
                        - Brief description of incident
                        <br />- Date of first demand/correspondence indicating
                        the events may lead to a claim
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      If a Letter of Demand
                    </span>

                    <ol>
                      <li>
                        Copy of Letter of Demand together with all documentation
                        received from the third party.
                      </li>
                      <li>
                        Insured to submit a detailed description of the matter
                        which should include at least the following:
                        <br />
                        - Date of Incident
                        <br />
                        - Brief description of incident
                        <br />- Date of first demand/correspondence indicating
                        the events may lead to a claim
                      </li>
                      <li>
                        Statement from the insured commenting on the
                        content/allegations in Letter of Demand.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      If a Legal Summons is received
                    </span>
                    <ol>
                      <li>Copy of summons with annexures if available.</li>
                      <li>
                        Confirmation of exact date summons was served on the
                        Insured.
                      </li>
                      <li>
                        Copies of all relevant correspondence to the matter
                        including date of first demand/correspondence indicating
                        the events that may have led to a claim
                      </li>
                    </ol>
                  </li>

                  <li>
                    <span className="font-weight-bold">
                      Confirmation of current status of the matter.
                    </span>
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      Copies of any other relevant documentation in Insured's
                      possession.
                    </span>
                  </li>
                </ol>
              </ol>
              <div className={classNames("mt-4")}>
                {" "}
                NB: Please note the requested information is not an exhaustive
                list and additional information may be requested on a case by
                case basis.
              </div>
            </Accordion>
          </Row>
          <div className={classNames("mt-4")}>
            <H3Red>How to claim as a broker or policy holder</H3Red>
            <br />
            <div className={classNames("mt-3")}>
              <RedirectToExternal
                target="_blank"
                style={{ color: "black", fontWeight: 800, textDecoration: "underline", }}
                href="/claims-video"
              >
                [ Claims Video ]
              </RedirectToExternal>
            </div>
            {/* In the event of an actual or suspected incident please notify ITOO
            as soon as possible on the following contact details:
            <br /> ITOO Cyber Assist Hotline: 0861 767 778 | 087 110 1665 | 010
            020 8712 | cyber_claims@ITOO.co.za
            <br />
            Please make sure you have your policy number on hand. */}
          </div>
          {/* <div className={classNames("mt-4")}>
            <H3Red>We're fair</H3Red>
            <br />
            We aim to be fair, gathering all the detail we can, from both sides,
            to see the complete picture. At iTOO we listen to understand and
            gain insight. We value feedback and strive to find a way that is
            true, just and impartial, without compromise, on every single claim.
          </div>
          <div className={classNames("mt-4")}>
            <H3Red>We know our industry</H3Red>
            <br />
            We only work with the country’s leading law firms and independent,
            specialist loss adjusters. These extended resources together with
            our strong internal technical skill, knowledge and understanding,
            means you can enjoy complete peace of mind because your claim is in
            the hands of an expert. At iTOO we recognise how to apply the facts
            of a case to its policy wording, how to interpret policies and
            correctly clarify, justify and simplify the most complex cases.
          </div>
          <div className={classNames("mt-4")}>
            <H3Red>We’re honest</H3Red>
            <br />
            We correctly adjust claims on their unique merits, in line with
            every respective policy and agreed condition. We never take
            shortcuts and our experts consider every detail, acting ethically,
            with honesty and integrity, without compromise.
          </div>
          <div className={classNames("mt-4")}>
            <H3Red>We’re committed to you</H3Red>
            <br />
            Building relationships and paying claims promptly are principles
            that lead our service. We explain every action, are clear in our
            channels of communication and validate every decision. Our experts
            are fluent in technical knowledge, always willing to share reports
            and legal opinion. We clarify expectations up front on every policy.
            Our turn-around times are exceptional, because we look for ways to
            settle your claim, prioritising timeous payments. Above all, we
            value every client we deal with.
          </div> */}
        </Row>
      </Container>
    </div>
  );
}

export default Claims;
