import React, { useCallback, useEffect, useState } from "react";

// <--------- MUI Components --------->
import { Divider } from "@mui/material";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <--------- Essential Components -------->
import {
  DatePicker,
  Select,
  SelectWithRadio,
  TextInput,
} from "../../../components";
import Button from "../../../components/Buttons/Button";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { 
  executeCalculator, 
  updateValues, getProductRisksAttributesWithPresist, updateIEDAttributes 
} from "store/actions/products";

import GIFLoader from "../../../assets/images/loader.gif";
import validate from "validate.js";
import useWindowDimensions from "utils/useWindowDimension";
import { toast } from "react-toastify";
import { HIDDEN_QUESTION, HIDE_QUESTION } from "utils/constants";

const Step1 = ({ handleNext, data }) => {

  // Attributes  i.e Account holder or Account number
  const [attributes, setAttributes] = useState([]);

  // Redux Hooks
  const dispatch = useDispatch();

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  // Validation constraints
  const [constraints, setConstraints] = useState({});
  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Type ID
  const typeID = useSelector(
    (state) =>
      state.products.types.types && state.products.types.types[0].instanceId
  );
  // const typeID = useSelector(
  //   (state) =>
  //   state?.products?.types?.data?.types && state?.products?.types?.data?.types[0]?.instanceId
  // );

  const { width } = useWindowDimensions();

  useEffect(() => {
    const BUD = riskAttributes?.find((x) => x.code === 'BUD')
                ?.attributes?.filter((f) => !HIDDEN_QUESTION.includes(f.instanceId) && !HIDE_QUESTION.includes(f.instanceId))

    if (BUD?.length) {
      // Setting up the risk attributes for Step 1
      setAttributes(
        BUD
          .map((d) => ({ ...d, value: d.value ? d.value : null }))
      );

      // Setting up the risk attributes constraints for form validations
      var obj = {};
      BUD
      .forEach((ex, i) => {
        if (ex.isRequired) {
          obj = {
            ...obj,
            [ex.name]: { presence: { allowEmpty: false } },
          };
        }
        if (i ===  BUD.length - 1) {
          setConstraints(obj);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // handling synthetic events
  const handleChange = (evt, instanceID) => {
    const elementIndex = attributes?.findIndex(
      (ele) => ele.instanceId === instanceID
    );
    var arr = [...attributes];
    arr[elementIndex] = { ...arr[elementIndex], value: evt.target.value };
    setAttributes(arr);
    var obj = { ...data, attributes: arr };
    dispatch(updateValues(obj));
  };

  useEffect(() => {
    var IED = ['OSE', 'CEU', 'ICU'];
    var length = riskAttributes
    .filter((f) => IED.includes(f.code)).length
    var temp = [...riskAttributes];
    riskAttributes
    .filter((f) => IED.includes(f.code))
    .map(async (ex, index) => {
      const response = await dispatch(getProductRisksAttributesWithPresist(ex.instanceId));
      const elementIndex = riskAttributes?.findIndex(
        (ele) => ele.code === response?.data?.risk?.code
      );
      temp[elementIndex] = { ...temp[elementIndex], attributes: response.data.attributes };
      if(index === length-1){
        dispatch(updateIEDAttributes(temp))
      }
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // Render fields
  const renderComponent = useCallback(
    (type) => {
      switch (type.dataType) {
        case 7:
          return (
            <Select
              errors={type.errors}
              label={type.name}
              name={type.name}
              required={false}
              value={type.value || ''}
              placeholder={type.description}
              options={JSON.parse(type?.settings)?.items}
              onChange={(evt) => handleChange(evt, type.instanceId)}
            />
          );

        case 2:
          return (
            <SelectWithRadio
              vertical={true}
              errors={type.errors}
              label={type.name}
              required={false}
              value={type.value || ""}
              placeholder={type.description}
              options={JSON.parse(type?.settings)?.items}
              onChange={(evt) => handleChange(evt, type.instanceId)}
            />
          );
        case 1:
          return (
            <TextInput
              required={false}
              errors={type.errors}
              placeholder={type.description}
              label={type.name}
              value={type.value || ""}
              onChange={(evt) => handleChange(evt, type.instanceId)}
            />
          );

        case 5:
          return (
            <DatePicker
              required={false}
              value={type.value}
              placeholder={type.description}
              errors={type.errors}
              onChange={(evt) => handleChange(evt, type.instanceId)}
              label={type.name}
            />
          );

        default:
          return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, riskAttributes]
  );

  const handleSubmit = (e) => {

    // <---- Consolidating Errors values ---->
    var values = {};
    attributes
      .filter((x) => !HIDE_QUESTION.includes(x.instanceId))
      .filter((f) => f.isRequired)
      .forEach((ex, i) => {
        if(ex.name === 'Company Types'){
          values = { ...values, [ex.name]: 'Company' };
        }
        else if (ex.isRequired) {
          values = { ...values, [ex.name]: ex.value };
        }
      });
    // <---- Errors check ---->
    const errors = validate(values, constraints);
    if (errors || errors !== undefined) {
      setAttributes(
        attributes
        .filter((x) => !HIDE_QUESTION.includes(x.instanceId))
        .map((ex) => ({
          ...ex,
          errors: errors[ex.name],
        }))
      );
      return;
    }else{
       setAttributes(
        attributes
        .filter((x) => !HIDE_QUESTION.includes(x.instanceId))
        .map((ex) => ({
          ...ex,
          errors: "",
        }))
      );
    }
    try {
      setIsLoading(true);

      var dup1 = attributes
      .filter((x) => !HIDE_QUESTION.includes(x.instanceId))
      .map((d) => ({
        dataType: d.dataType,
        name: d.name,
        instanceId: d.instanceId,
        value: d.name.toLowerCase().includes('annual') ? d.value.replaceAll('R ', 'R') : d.value
      }))

      var dup = [...dup1, {
          "dataType": 7,
          "name": "Annual Turnover/Revenue in ZAR",
          "instanceId": "3a78b4d2-bb19-7e85-f584-916b9f63dc5a",
          "value": attributes.find((f) => f.name.toLowerCase().includes('annual'))?.value.replaceAll('R ', 'R')
      }]

      // <---- Dispatching values for executeCalculators ---->
       dispatch(
        executeCalculator({
          factors: [],
          risks: [
            {
              attributes: dup,
              riskReference: data.instanceId,
              code: data.code,
            },
          ],
          productTypeReference: typeID,
        })
      )
        .then((data) => {
          var obj = { ...data, attributes: attributes, startDate: attributes[3]?.value };
          dispatch(updateValues(obj));
          setIsLoading(false);
          handleNext(1);
        })
        .catch((er) => {
          
          toast.error(er?.response?.data?.message || er?.response?.data || "Error Occured");
        });
    } catch (err) {
      
    }
  };

  return data?.attributes ? (
    <div>
      {/* <---------- Title -------> */}
      <h4>{data?.name}</h4>

      {/* <---------- Title -------> */}
      <h6>{data?.description}</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}

      <Row>
        {attributes?.filter((x) => !HIDE_QUESTION.includes(x.instanceId) )?.map((d, i) => (
          <Col key={i} sm={6}>{renderComponent(d)}</Col>
        ))}
      </Row>
      <Row
        className={
          width > 430 ? "justify-content-end" : "justify-content-center"
        }
      >
        <Button
          type="submit"
          isLoading={isLoading}
          containerStyle={{ width: 200 }}
          onClick={handleSubmit}
          name="Get Quote"
          bg="#DA291C"
          color="white"
          id="form-1-quote-button"
        />
      </Row>

      {/* <------- Attributes Ends ------> */}
    </div>
  ) : (
    <div className="gif-loader">
      <img src={GIFLoader} alt='GIF LOADER'></img>
    </div>
  );
};

export default Step1;