// default imports for react project
import React, { useLayoutEffect } from "react";

// default import for routing in react
import { useDispatch } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";
import { H2Red, H1Grey, H2Grey, H4Grey } from "components/Heading";

// import function from actions
import { addCrumb } from "store/actions/common";

// React functional component starts here..
function DebitMandate() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/debit-mandate", name: "Debit Mandate" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container className={classNames("content")}>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey>We’re always thinking about tomorrow,</H1Grey>
          <H2Grey>so you can concentrate on making today count.</H2Grey>
        </Row>
        <Row className={classNames("mt-5")}>
          <H2Red>Debit mandate</H2Red>
          <br />
          <div className={classNames("mt-2")}>
            <H4Grey style={{ fontFamily: "var(--primary-font)" }}>
              Website Terms and Conditions of Use
            </H4Grey>
            <br />
            General Disclaimer
          </div>
        </Row>
        <Row className={classNames("mt-4")}>
          Debit order mandate <br/>  We, the Insured, have elected to pay the Annual
          Premium by monthly instalment, and authorise ITOO to debit the above
          bank account (or any other bank to which we may transfer our account).
          The amount of the debit may vary from time to time to reflect any
          changes in cover or premium.We accept and agree to the following:The
          monthly instalment shall be payable on the debit date reflected
          above.In the event Hollard  does not receive the monthly instalment for
          any reason whatsoever, then a double debit will be made the following
          month. Should this fail then the cover shall, notwithstanding anything
          to the contrary contained in the policy, be deemed to have been
          cancelled from the end of the last month for which the premium
          payments were paid.Reinstatement of the policy shall be at the sole
          discretion of Hollard. <br/> <br/>  Note that despite the payment of the premium by
          way of monthly instalments, this is an annual policy for which an
          annual premium is payable. Accordingly in the event of notification of
          any claim or circumstances that might lead to a claim during the
          Annual Period of Insurance for which any monthly instalment is unpaid
          then Hollard has the right to reject such claim. Should any payments
          have been made by Hollard on any such claims then such payments may be
          recovered from the Insured.In acceptance of the above and all clauses
          and declarations.
        </Row>
      </Container>
    </div>
  );
}

export default DebitMandate;
