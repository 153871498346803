import React, { useCallback, useEffect, useState } from "react";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";

// <---------- Actions ----------->
import { updateSelectedOption, updateStepValue } from "store/actions/products";

// <--------- Essential Components -------->
import {
  Question,
  Select,
  TextInput,
  Divider,
  VerticalSpacer,
  SelectWithRadioHorizontal,
} from "../../../components";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import validate from "validate.js";
import useWindowDimensions from "utils/useWindowDimension";
import { COVER_AMOUNT_MID, COVER_AMOUNT_HIGH } from "utils/constants";

const Step4 = ({
  handleNext,
  data,
  ceu, // CEU Risk attributes
  ose, // OSE Risk attributes
  icu, // ICU Risk attributes
  handleBack,
}) => {
  //****************************************************** NOTES **************************************************************/
  //**                                                                                                                       **/
  //**   1. The "code" terms which identifies the product risks and we're mapping the product risks according to code.       **/
  //**   2. This component is partially hardcoded and needs to be update or re-code as per riskattributes in the future.     **/
  //**   2. In this component we're handling these 3 types OSE, ICU, CEU and hardcoded from stote.                           **/
  //**   3. Attributes defines the types of the risk attributes and sub-attributes defines attributes from selected-type.    **/
  //**                                                                                                                       **/
  //****************************************************** NOTES **************************************************************/

  // Attributes
  const [attributes, setAttributes] = useState([]);

  // Sub attributes from selected attributes
  const [subAttributes, setSubAttributes] = useState([]);
  const [subAttributesAll, setSubAttributesAll] = useState([]);

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  // Redux Hooks
  const dispatch = useDispatch();

  // selected option i.e ICU, CEU, ICU
  const selectedOption = useSelector((state) => state.products.selectedOption);

  const { width } = useWindowDimensions();

  const requestedPayload = useSelector(
    (state) => state.products.requestedPayload
  );

  const [optionError, setOptionError] = useState({});

  useEffect(() => {
    // if (ceu && icu && ose) {
    setAttributes(data?.attributes);
    //   let coverAmount =
    //     requestedPayload?.find((f) => f.name === "Cover Amount") ||
    //     (requestedPayload && requestedPayload[3]);

    //   coverAmount = parseFloat(
    //     coverAmount.value.replaceAll(" ", "").replace("R", "")
    //   );

    // if (coverAmount <= 500000) return;

    // if (coverAmount >= 1000000 && coverAmount <= 2500000) {
    //   var arr = [
    //     ...ceu?.attributes
    //       .filter((f) => COVER_AMOUNT_MID.CEU.includes(f.instanceId))
    //       .map((d) => ({ ...d, type: "CEU" })),
    //     ...ose?.attributes
    //       .filter((f) => COVER_AMOUNT_MID.OSE.includes(f.instanceId))
    //       .map((d) => ({ ...d, type: "OSE" })),
    //     ...icu?.attributes
    //       .filter((f) => COVER_AMOUNT_MID.ICU.includes(f.instanceId))
    //       .map((d) => ({ ...d, type: "ICU" })),
    //   ];
    //   setSubAttributes(arr);
    // }

    // if (coverAmount >= 5000000) {
    var arrq = [
      ...ceu?.attributes
        .filter((f) => COVER_AMOUNT_HIGH.CEU.includes(f.instanceId))
        .map((d) => ({ ...d, type: "CEU" })),
      ...ose?.attributes
        .filter((f) => COVER_AMOUNT_HIGH.OSE.includes(f.instanceId))
        .map((d) => ({ ...d, type: "OSE" })),
      ...icu?.attributes
        .filter((f) => COVER_AMOUNT_HIGH.ICU.includes(f.instanceId))
        .map((d) => ({ ...d, type: "ICU" })),
    ];
    
    setSubAttributes(arrq);
    setSubAttributesAll(arrq);
    // }

    // Setting up the risk attributes constraints for form validations
    // var obj = {};
    // arr
    //   ?.filter(
    //     (filter) =>
    //       filter.type === selectedOption && filter.settings !== "null" &&  !filter.name.includes('NO')
    //   )
    //   .map((ex, i) => {
    //     return (obj = {
    //       ...obj,
    //       [ex.name.replaceAll(" ", "")]: { presence: { allowEmpty: false } },
    //     });
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedOption, ceu, ose, icu]);

  // Render fields
  const renderComponent = useCallback(
    (type) => {
      switch (type.dataType) {
        case 2:
          return (
            <Select
              label={type.description}
              required={false}
              value={type.value || ""}
              placeholder={type.description}
              errors={type.errors}
              options={JSON.parse(type?.settings)?.items}
              onChange={(evt) => handleChange(evt, type.instanceId)}
            />
          );

        case 7:
          return (
            <Question
              vertical={true}
              errors={type.errors}
              labelStyle={{ height: "50px" }}
              detailed={
                (type.name.includes("OSE") ||
                  type.name.includes("CEU") ||
                  type.name.includes("ICU")) &&
                type.value === "No"
              }
              question={type.description}
              label={
                subAttributes.filter(
                  (filter) =>
                    filter.name.slice(
                      filter.name.length - 8,
                      filter.name.length - 1
                    ) ===
                    type.name.slice(type.name.length - 7, type.name.length)
                )[0]?.description
              }
              required={false}
              value={type.value}
              placeholder={type.description}
              options={JSON.parse(type?.settings)?.items}
              detailedValue={
                subAttributes.filter(
                  (filter) =>
                    filter.name.slice(
                      filter.name.length - 8,
                      filter.name.length - 1
                    ) ===
                    type.name.slice(type.name.length - 7, type.name.length)
                )[0]?.value
              }
              onChange={(evt) => handleChange(evt, type.instanceId)}
              onDetailedChange={(evt) =>
                handleChange(
                  evt,
                  subAttributes.filter(
                    (filter) =>
                      filter.name.slice(
                        filter.name.length - 8,
                        filter.name.length - 1
                      ) ===
                      type.name.slice(type.name.length - 7, type.name.length)
                  )[0]?.instanceId
                )
              }
            />
          );
        case 1:
          return (
            <TextInput
              errors={type.errors}
              required={false}
              placeholder={type.description}
              label={type.description}
              value={type.value || ""}
              onChange={(evt) => handleChange(evt, type.instanceId)}
            />
          );

        default:
          return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, riskAttributes, subAttributes]
  );

  // handling synthetic events
  const handleChange = (evt, instanceID) => {
    const elementIndex = subAttributesAll?.findIndex(
      (ele) => ele.instanceId === instanceID
    );
    var arr = [...subAttributesAll];
    arr[elementIndex] = {
      ...arr[elementIndex],
      value: evt.target.value,
      errors: arr[elementIndex]?.value && [],
    };
    setSubAttributes(arr);
    dispatch(
      updateStepValue({
        code: arr[elementIndex].type,
        attributes: arr.filter((d) => d.type === arr[elementIndex].type),
      })
    );
  };

  const handleSelectedOption = (evt) => {
    //  Updating the selected radio button to the store for filtring the attributes (OSE, CEU, ICU).
    dispatch(
      updateSelectedOption(
        JSON.parse(
          attributes.find((find) => find.description === evt.target.value)
            ?.settings
        )?.items[0]
      )
    );

    var selectedOption = JSON.parse(
      attributes.find((find) => find.description === evt.target.value)?.settings
    )?.items[0];
    // Clear the values from the selected radio attributes after choosing another radio.
    var attri = subAttributesAll?.filter((x) => x.type === selectedOption)?.map((y) => ({ ...y, value: null }));
    
    setSubAttributes(attri);
    dispatch(
      updateStepValue({
        code: selectedOption,
        attributes: attri.filter((d) => d.type === selectedOption),
      })
    );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (selectedOption === null) {
      return setOptionError({ selectedOption: ["Please select an option"] });
    } else {
      setOptionError({ selectedOption: [""] });
    }
    
    var obj = {};
    subAttributes
      ?.filter(
        (filter) =>
          filter.type === selectedOption &&
          filter.settings !== "null" &&
          !filter.name.includes("NO")
      )
      .map((ex, i) => {
        return (obj = {
          ...obj,
          [ex.name.replaceAll(" ", "")]: { presence: { allowEmpty: false } },
        });
      });
    // <---- Consolidating Errors values ---->
    var values = {};
    subAttributes
      ?.filter(
        (filter) =>
          filter.type === selectedOption &&
          filter.settings !== "null" &&
          !filter.name.includes("NO")
      )
      .map((ex, i) => {
        return (values = {
          ...values,
          [ex.name.replaceAll(" ", "")]: ex.value,
        });
      });
    // <---- Errors check ---->
    
    const errors = validate(values, obj);
    
    if (errors || errors !== undefined) {
      setSubAttributes(
        subAttributes
          ?.filter(
            (filter) =>
              filter.type === selectedOption &&
              filter.settings !== "null" &&
              !filter.name.includes("NO")
          )
          .map((ex) => ({ ...ex, errors: errors[ex.name.replaceAll(" ", "")] }))
      );
      return;
    }
    var attri = subAttributes.map((y) => ({ ...y, errors: [] }));
    
    setSubAttributes(attri);
    dispatch(
      updateStepValue({
        code: selectedOption,
        attributes: attri.filter((d) => d.type === selectedOption),
      })
    );
    handleNext();
  };

  const coverAmount = useSelector(
    (state) =>
      state.products.riskAttributes[0]?.attributes.find(
        (x) => x.name === "Cover Amount"
      )?.value
  );

  return (
    <div>
      {/* <---------- Title -------> */}
      <h4>{data?.name}</h4>

      {/* <------- Sub title ------> */}
      <h6>{data?.description}:</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}

      <Row>
        <Col sm={12}>
          <SelectWithRadioHorizontal
            label={`Choose your environment: Cover amount of ${coverAmount}`}
            options={attributes?.map((ele) => ele.description)}
            onChange={handleSelectedOption}
            required={true}
            errors={optionError.selectedOption}
            value={
              attributes?.find(
                (ele) => JSON.parse(ele.settings)?.items[0] === selectedOption
              )?.description.length
                ? attributes?.find(
                    (ele) =>
                      JSON.parse(ele.settings)?.items[0] === selectedOption
                  )?.description
                : " "
            }
          />
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          {attributes
            ?.filter((f, index) => index === 0)
            .map((d, i) => (
              <div key={i} className="mt-4 mb-4">
                <Row>
                  {subAttributes &&
                    subAttributes
                      ?.filter(
                        (filter) =>
                          filter.type === selectedOption &&
                          filter.settings !== "null" &&
                          !filter.name.includes("NO")
                      )
                      ?.map((o, p) => (
                        <Col key={p} sm={6}>
                          {renderComponent(o)}
                        </Col>
                      ))}
                </Row>
              </div>
            ))}
        </Row>

        <Divider />
        <VerticalSpacer rows={2} />
        {/* <------- Attributes Ends ------> */}

        {/* <------- Navigating buttons Starts ------> */}
        <Row className="mt-5">
          <Col xs={6} md={2} className="d-flex justify-content-start">
            <ButtonWithDownArrow
              onClick={handleBack}
              type="left"
              name="Back"
              bg="#dd302a"
              color="white"
              id="form-4-back-button"
            />
          </Col>
          {width > 430 && <Col xs={0} md={8} />}
          <Col xs={6} md={2} className="d-flex justify-content-end">
            <ButtonWithDownArrow
              buttonType="submit"
              type="right"
              name="Next"
              bg="#dd302a"
              color="white"
              id="form-4-next-button"
            />
          </Col>
        </Row>
        {/* <------- Navigating buttons Ends ------> */}
      </form>
    </div>
  );
};

export default Step4;


