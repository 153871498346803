import React, { useEffect, useState } from "react";
import style from "./Form.module.css";
// <--------- Essential Components -------->
import {
  Header,
  Divider,
  VerticalSpacer,
  DescriptionPlaceholders,
} from "../../components";
import GIFLoader from "../../assets/images/loader.gif";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import {
  clearState,
  makePolicySalePayment,
  issuePolicy,
  makeExternalPayment,
  activatePolicy,
} from "store/actions/products";
import SummaryCard from "components/Forms/SummaryCard";
import classNames from "classnames";
import CONSTANTS from "utils/constants";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";

const PaymentSuccess = () => {
  // Company details from the store
  const details = useSelector((state) => state.prevState.companyDetails);

  // Selected product from the store
  const selectedProduct = useSelector(
    (state) => state.prevState.selectedProduct
  );

  // Policy response from the store
  const policyResponse = useSelector((state) => state.prevState.policyResponse);

  // Risk attributes from the store
  const [isLoading, setIsLoading] = useState(false);

  // Redux hooks for dispatching
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();


  const companyDetails = useSelector((state) => state.products.companyDetails);

  // External reference
  const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

  useEffect(() => {
    if (location.state) {
      if (location.state.type !== "DEBIT") {
        submitCredit();
      }
    } else {
      submitCredit();
    }
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const currentToken =  useSelector(state => state.auth.currentUserToken)


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  const submitCredit = async () => {
    try {
      setIsLoading(true);
      // Dispatching the values and calling the makeExternalPayment API
      const res = await dispatch(
        makeExternalPayment({
          configurationKey: CONSTANTS.EXTERNAL_PAYMENT_CONFIGURATION_KEY,
          external: {
            providerReference: policyResponse.policies[0].policyReference,
          },
          payment: {
            currency: "zar",
            amount: parseFloat(
              getProrate(
                selectedProduct.risks && selectedProduct.risks[0].rating.message
              ).prorataAnnualTotal
            ),
            policyReference: policyResponse.policies[0].policyReference,
          },
        })
      );

      // Dispatching the values and calling the makePolicySalePayment API
      await dispatch(
        makePolicySalePayment({
          externalReference: uniqueId(),
          paymentReference: res.data.paymentReference,
          policySaleReference: policyResponse.policySaleReference,
          paymentType: "External",
          transactionType: "credit",
          paymentStatus: "success",
          amount: selectedProduct.rating.price,
          discount: 0,
          tax: 0,
          total: selectedProduct.rating.price,
          currencyCode: "ZAR",
          allocations: [
            {
              externalReference: policyResponse.policies[0].externalReference,
              policyReference: policyResponse.policies[0].policyReference,
              amount: parseFloat(
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).prorataAnnualTotal
              ),
              discount: 0,
              tax: 0,
              total: parseFloat(
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).prorataAnnualTotal
              ),
            },
          ],
        })
      );

      // Dispatching the values and calling the issuePolicySale API
      await dispatch(
        issuePolicy({
          externalReference: policyResponse.externalReference,
          policySaleReference: policyResponse.policySaleReference,
          comment: "Postman Issue Sale",
          policies: [
            {
              policyReference: policyResponse.policies[0].policyReference,
              bankAccounts: [],
            },
          ],
          webhook: {
            webhookUrl:
              "https://briisk-i-dev-policies.azurewebsites.net/webhook/saveWebhook",
          },
        })
      );

      // Dispatching the values and calling the activePolicySale API
      await dispatch(
        activatePolicy({
          externalReference: policyResponse.externalReference,
          policySaleReference: policyResponse.policySaleReference,
          policies: [
            {
              policyReference: policyResponse.policies[0].policyReference,
              bankAccounts: [],
            },
          ],
        })
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };


  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center flex-column">
        {/* <---------- Title -------> */}
        <h3 className={style.header}>Click ... Click - DONE!</h3>
        <VerticalSpacer rows={5} />
        {isLoading ? (
          <div className="gif-loader">
            <img src={GIFLoader} alt="Loader"></img>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center align-items-center w-100 text-center">
              <Header
                titleSize={28}
                title={`Thank you for the purchase of your Cyber SME.`}
              />
            </div>

            <div className={classNames("container", style.boxBorder)}>
              <DescriptionPlaceholders
                breaks={false}
                textStyle={{
                  fontWeight: 700,
                  fontSize: "1.1rem",
                }}
                value={[
                  //`Dear ${details.firstName} ${details.lastName}`,
                  `An Email has be sent to ${details.eMail}`,
                  "Keep the document safe as they will be needed if you make a claim (please check your spam folder).",
                ]}
              />

              <Divider />

              <div className="d-flex mt-3 justify-content-center align-items-center">
                <SummaryCard
                  title={selectedProduct.name}
                  type={
                    selectedProduct.name.toLowerCase().includes("monthly")
                      ? "debit"
                      : "credit"
                  }
                  policyNumber={policyResponse.policies[0].policyReference}
                  policyHolder={
                    details?.workCompanyOptions === "Company"
                      ? details?.workCompany
                      : `${details?.title}. ${details.firstName}  ${details.lastName}`
                  }
                  premiumPaid={`R ${decimalCheck(
                    parseFloat(
                      getProrate(
                        selectedProduct.risks &&
                          selectedProduct.risks[0].rating.message
                      ).prorataAnnualTotal
                    )
                      .toLocaleString("fi-FI")
                      .replaceAll(",", ".")
                  )}`}
                  premiumPaidMonthly={`R ${decimalCheck(
                    parseFloat(
                      getProrate(
                        selectedProduct.risks &&
                          selectedProduct.risks[0].rating.message
                      ).policyCollectionAmount
                    )?.toFixed(2).toString().replaceAll(",", ".")
                  )}`}
                  // premiumPaidMonthly={`R ${(
                  //   Math.floor(
                  //     parseFloat(
                  //       getProrate(
                  //         selectedProduct.risks &&
                  //           selectedProduct.risks[0].rating.message
                  //       ).policyCollectionAmount
                  //     ) * 100
                  //   ) / 100
                  // )
                  //   ?.toString()
                  //   .replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
                  policyStartDate={
                    getProrate(
                      selectedProduct.risks &&
                        selectedProduct.risks[0].rating.message
                    ).policyStartDate
                  }
                  policyEndDate={
                    getProrate(
                      selectedProduct.risks &&
                        selectedProduct.risks[0].rating.message
                    ).policyEndDate
                  }
                  policyRenewalDate={
                    getProrate(
                      selectedProduct.risks &&
                        selectedProduct.risks[0].rating.message
                    ).policyRenewalDate
                  }
                  proratePremium={decimalCheck(
                    parseFloat(
                      getProrate(
                        selectedProduct.risks &&
                          selectedProduct.risks[0].rating.message
                      ).prorataPremium
                    )
                      .toLocaleString("fi-FI")
                      .replaceAll(",", ".")
                  )}
                  monthlyProratePremium={
                    selectedProduct.risks &&
                    decimalCheck(
                      parseFloat(
                        selectedProduct.risks[0].rating.total.toFixed(2)
                      )
                        .toLocaleString("fi-FI")
                        .replaceAll(",", ".")
                    )
                  }
                  debitDate={
                    getProrate(
                      selectedProduct.risks &&
                        selectedProduct.risks[0].rating.message
                    ).policyCollectionDate
                  }
                  selectedProduct={selectedProduct}
                  companyDetails={companyDetails}
                  policyResponse={policyResponse}
                />
              </div>
              <VerticalSpacer rows={2} />

              <div>
                <h4 className="header__title">
                  As a future-focussed business, we are continuously researching
                  new developments to deliver the very best specialist products
                  that our customers’ complex and growing businesses need.{" "}
                  <a className="header__title" href="https://itoo.co.za">
                    [ See our products ]
                  </a>
                </h4>
                <h6 className="header__sub" style={{ fontWeight: 400 }}>
                  Our people are our experts. We are driven by a high
                  performance specialist team, leaders in their individual
                  fields. At iTOO we are always ready to engage and understand
                  so we can do more, make it work and find a way on time, every
                  time. We are riddle solvers, planners, guardians, expert
                  thinkers, industry leaders and get-stuck-in-doers. We are
                  always ready to help because we’ve planned ahead of every
                  eventuality. That’s the iTOO difference
                </h6>
              </div>

              <VerticalSpacer rows={2} />

              <Header
                title="iTOO"
                sub="iTOO Company Registration no. 2016/281463/07. FSP no. 47230 | Postal Address: PO Box 87419, Houghton, 2041 | Phone: +27 11 351 5000 | Email: info@itoo.co.za"
                subStyle={{ fontWeight: 400 }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
