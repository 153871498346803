// default imports for react project
import React,{ useEffect } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import { main } from "assets/images/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

// Importing Brochure
import Brochure from "../../assets/documents/brochure_Mar_2023.pdf";
import { clearState } from "store/actions/products";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

// React functional component starts here..
function Homepage() {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
    <div style={{width: '100%', maxWidth: '100%', overflowX: 'hidden'}}>
      <div className="home-container">
        <Container >
          <Row className="py-5 ">
            <Col lg={4} xs={12} className="text-center">
              <img className="main__img" src={main} alt="main" />
            </Col>
            <Col lg={8} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0">
                Cyber breaches are no longer a matter of if, but when, and how you
                would respond.
              </Row>
              <Row className="homepage1__heading2 m-0 pt-2">
                Recovery costs time and money. With your reputation on the line, how
                will you respond?
              </Row>
              <Row className="py-4 m-0">
                <Col lg={3} md={5} xs={6} className="p-0">
                  <BodyButton
                    color="white"
                    bg="#353F4E"
                    name={"See Cover Details"}
                    size={16}
                    marginL={0}
                    marginT={width > 770 ? 0 : 20}
                    weight={700}
                    width={width > 770 ? "200px" : "100%"}
                    align={width > 770 ? "left" : "center"}
                    link={"/product"}
                    id="landing-page-cover-details-button"
                  />
                </Col>
                <Col xs={6} lg={2} md={7} className="p-0">
                  <a
                    href={Brochure}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <BodyButton
                      color="white"
                      bg={'#353F4E'}
                      name={width > 770 ? "Download our Brochure" : "Get Brochure"}
                      size={16}
                      marginL={width > 770 ? 30 : 10}
                      marginT={width > 770 ? 0 : 20}
                      weight={700}
                      width={width > 770 ? "200px" : "100%"}
                      align={width > 770 ? "left" : "center"}
                      id="landing-page-brochure-button"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </div>
      <Container>
        <Row style={{ marginTop: width > 770 ? '20px' : 0 }}>
          <div className="app__header__two">
            Given this reality, is your business properly protected against
            evolving cyber threats?
          </div>
          <div className="app__header__two__sub">
            Our cyber insurance policy gives your business comprehensive cover
            against costs and damages resulting from a privacy breach or a network
            security breach. Plus, you’ll also get an expert incident response
            team managing the process.
          </div>
        </Row>
        <Row className="mt-5">
          <Col sm={12}>
            <Card2 width={width} />
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}

export default Homepage;
