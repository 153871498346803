// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import dispatch hook to dispatch actions
import { useDispatch, useSelector } from "react-redux";

// import function from actions
import { addCrumb } from "store/actions/common";

// import data from custom component
import { travelSuperiorPlan } from "./data";

// Brochure
import brochure from "../../assets/documents/brochure_Mar_2023.pdf";

//   import custom component
import { H1Grey, H2Grey, H3Red, BlackArrayText } from "components/Heading";
import Accordion from "components/Accordion";
import BodyButton from "components/Buttons/BodyButton";
import BreadCrumb from "components/Breadcrumb";
import CardHeader from "components/Card/CardHeader";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// import stylesheet
import Style from "./fastfacts.module.css";

// import classname package to combine module css and normal css
import classNames from "classnames";
import { card_header_img } from "assets/images/images";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";


// React functional component starts here..
function FAQ() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // scroll window to top of page on navigation
    window.scrollTo(0, 0);

    // dispatching an action to update breadcrumb
    dispatch(
      addCrumb([
        { link: "/faq", name: "FAQ" },
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const currentToken =  useSelector(state => state.auth.currentUserToken)


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  return (
    <div>
      <Container>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey className={classNames("mb-5")}>
            Cyber iTOO Go <span style={{color: '#DA291C'}}> Frequently Asked Questions </span>
          </H1Grey>
          <H2Grey>Covered by Experts</H2Grey>
        </Row>
        <Row className="mt-5">
          <Col lg={6} className={classNames("")}>
            <CardHeader 
              image={card_header_img}
              title="Cyber iTOO Go"
            />
          </Col>
          <Col lg={3} className={Style.money}>
            <div className={Style.money}>
              From: &nbsp;<span>R 225 pm</span>
            </div>
          </Col>
          <Col className={Style.fbutton}>
            <BodyButton
              color="white"
              bg="rgb(53, 63, 78)"
              name="Get Cover Now"
              size={16}
              marginL={0}
              weight={700}
              marginT={width > 770 ? 0 : 20}
              black={width > 770 ? false : true}
              link="/forms"
            />
          </Col>
        </Row>
        <Row className={classNames(Style.content__answer, "px-3")}>
          <div className={classNames("my-5")}>
            Cyber Insurance proposals can be a daunting task to complete. In
            some instances these can even serve as a barrier to you taking out
            the cover. As such we have launched a simplified Cyber Insurance
            proposal form (please see attached), targeted at compa­nies with a
            turnover below R 250 000 000. Simple and quick to complete, the form
            also includes pricing informa­tion and is based on a comprehensive
            set of coverages including:
          </div>
          <H3Red>
            Benefits table to be read in conjunction with your policy wording:
          </H3Red>
          <div>
            The information contained in this document is only a summary of the
            cover you have under your Cyber Insurance policy and is provided to
            you for easy reference. Kindly refer to your policy wording for the
            full list of terms and conditions.
          </div>
          <div className={classNames("my-4 d-flex justify-content-between")}>
          <BlackArrayText><a style={{ color: 'black' }} rel="noreferrer" href={brochure} target="_blank">[ Download Brochure ]</a></BlackArrayText>
           
          </div>
        </Row>
        <Row>
          {travelSuperiorPlan?.map((plan, i) => (
            <Accordion key={i} title={plan?.title}>
              <div>
                {plan?.answer}
                <ul>
                  {plan?.subAnswer?.map((ans, j) => (
                    <li key={j}>{ans}</li>
                  ))}
                </ul>
              </div>
            </Accordion>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default FAQ;
