import React from "react";
import Video from '../../assets/videos/iTOODigitalClaims.mp4';


const ClaimsVideo = () => {
  return (
    <div
      style={{
        zIndex: 100,
        backgroundColor: "black",
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
      }}
    >
      <video
        allowfullscreen
        autoPlay="autoPlay"
        controls
        autoplay="autoplay"
        muted
        height="100%"
        width="100%"
      >
        <source
          type="video/mp4"
          src={Video}
        ></source>
      </video>
    </div>
  );
};

export default ClaimsVideo;
