// default imports for react project
import React from "react";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import style from "./Button.module.css";

// React functional component starts here..
function Button({
  name,
  containerStyle,
  isLoading,
  bg,
  color,
  onClick,
  border,
  shadow,
  type,
  id
}) {
  return (
    <button
      onClick={() => !isLoading && onClick()}
      type={type || "button"}
      style={{
        background: `${bg}`,
        color: `${color}`,
        borderRadius: "5px",
        border,
        height: "auto",
        boxShadow: !shadow ? "" : "0px 3px 8px #00000059",
        ...containerStyle,
      }}
      className={classNames("px-3 py-2 d-flex", style.button__header)}
      name={id ?? name}
      id={id ?? name}
      aria-label={id ?? name}
    >
  
        {name} <div className={isLoading ? "lds-dual-ring" : ""}></div>{" "}
      
    </button>
  );
}

export default Button;
