// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import icon from assets folder
import {
  EmailIcon,
  PhoneIphoneRoundedIcon,
  LinkedInIcon,
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
} from "assets/icons";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import Style from "./Navbar.module.css";
import { useSelector } from "react-redux";

// React functional component starts here..
function TopNavbar() {

  const companyDetails = useSelector(state => state.auth.companyDetails?.companyContactViewModel);


  return (
    <div className={classNames(Style.topnavbar, " py-2")}>
      <div className={classNames(Style.topnavbar_row,"container p-0 d-flex justify-content-between ")}>
        <div className="d-flex flex-row">
          <div  className="d-flex appbar1__content">
          <div>
              <PhoneIphoneRoundedIcon />
            </div>
            <div className="mx-2">{companyDetails?.contactNumber ? companyDetails?.contactNumber : '+27 (0)11 351 5000'}</div>
          </div>
          <div  className="d-flex appbar1__content">
          <div>
              <EmailIcon />
            </div>
            <div className="mx-2" >{companyDetails?.contactEMail ? companyDetails?.contactEMail : 'admin@itoo.co.za'}</div>
          </div>
        </div>
        <div
          style={{ marginRight: '10px' }}
          className={classNames(
            Style.appbar1__content,
            "d-flex justify-content-end"
          )}
        >
          {companyDetails?.facebook?.length ?  <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.facebook ? companyDetails?.facebook : "#"}
            role="button"
            className="ml-auto"
          >
            <FacebookIcon />
          </a> : <></>}
          {companyDetails?.twitter?.length ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.twitter ? companyDetails?.twitter : "#"}
            role="button"
            className="ml-3"
            style={{marginLeft: 20}}
          >
            <TwitterIcon />
          </a> : <></>}
          {companyDetails?.instagram?.length ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.instagram ? companyDetails?.instagram : "#"}
            role="button"
            className="ml-3"
            style={{marginLeft: 20}}
          >
            <InstagramIcon />
          </a> : <></>}
          {companyDetails?.linkedIn?.length ? <a
            rel="noreferrer noopener"
            target="_blank"
            href={companyDetails?.linkedIn ? companyDetails?.linkedIn : "https://linkedin.com"}
            role="button"
            className="ml-3"
            style={{marginLeft: 20}}
          >
            <LinkedInIcon />
          </a> : <></>}
         
        </div>
      </div>
    </div>
  );
}

export default TopNavbar;
