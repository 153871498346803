import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import style from "./cookies.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
// import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomAttributes } from "../../store/actions/products";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/styles";
import useWindowDimensions from "utils/useWindowDimension";
import zIndex from "@mui/material/styles/zIndex";

//------Close----------
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 1, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//--------Switch---------------
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 25,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(10px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      left: 6,
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

// ----------Table-------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#424C67",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // backgroundColor: "#DBE4ED",
    // color:"black"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: "#C8D1D9",
  },
  "&:nth-of-type(even)": {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: "#C8D1D9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(cookie, duration, desc) {
  return { cookie, duration, desc };
}

const rows = [
  createData(
    "cookielawinfo-checkbox-analytics",
    "11 months",
    'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Analytics".'
  ),
  createData(
    "cookielawinfo-checkbox-functional",
    "11 months",
    'The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category "Functional".'
  ),
  createData(
    "cookielawinfo-checkbox-necessary	",
    "11 months",
    'This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".'
  ),
  createData(
    "cookielawinfo-checkbox-others",
    "11 months",
    'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Other.'
  ),
  createData(
    "cookielawinfo-checkbox-performance",
    "11 months",
    'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Performance".'
  ),
  createData(
    "viewed_cookie_policy	",
    "11 months",
    "The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data."
  ),
];

// --------Accordion----------
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F2F2F2 !important",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    backgroundColor: "#F2F2F2 !important",
  },
  "& .Mui-expanded > div > p": {
    color: "black !important",
    // fontWeight: "700",
  },
  "& .Mui-expanded > svg": {
    backgroundColor: "#F2F2F2 !important",
    color: "black !important",
  },
  "& .MuiPaper-elevation": {
    backgroundColor:
      "#FEFEFECC !important" /* set background color to light gray */,
  },
  "& .MuiPaper-root": {
    backgroundColor: "pink !important",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "black" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "rgba(254, 232, 229, 0.8)",
  },
  summaryContainer: {
    display: "flex",
  },
  heading: {
    // marginTop: "8px !important",
    color: "black !important",
    // fontWeight: "700",
  },
  heading_mobile: {
    marginTop: "11px !important",
    color: "black !important",
    // fontWeight: "700",
  },
  heading_functional: {
    marginTop: "2px !important",
    color: "black !important",
    // fontWeight: "700",
  },
  subtitle: {
    // marginLeft: theme.spacing(1),
    // marginLeft: "309px !important",
    marginLeft: "279px !important",
  },
  subtitle_mobile: {
    // marginLeft: theme.spacing(1),
    // marginLeft: "309px !important",
    marginLeft: "95px !important",
  },
  subtitle_function: {
    marginLeft: "279px !important",
  },
  subtitle_mobile_func: {
    marginLeft: "70px !important",
  },
  subtitle_performance: {
    marginLeft: "261px !important",
  },
  subtitle_mobile_per: {
    marginLeft: "52px !important",
  },
  subtitle_analy: {
    marginLeft: "290px !important",
  },
  subtitle_mobile_analy: {
    marginLeft: "80px !important",
  },
  subtitle_Advertisement: {
    marginLeft: "251px !important",
  },
  subtitle_mobile_Adv: {
    marginLeft: "39px !important",
  },
  subtitle_Oth: {
    marginLeft: "310px !important",
  },
  subtitle_mobile_Oth: {
    marginLeft: "97px !important",
  },
  table: {
    // minWidth: 650,
    width: "100%",
  },
}));

function CookieBanner() {
  const text = `This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.`;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [openSettings, setOpenSettings] = useState(false);
  const [onNecessary, setOnNecessary] = useState(true);
  const [onFunctional, setOnFunctional] = useState(false);
  const [onPerformance, setOnPerformance] = useState(false);
  const [onAnalytics, setOnAnalytics] = useState(false);
  const [onAdvertisement, setOnAdvertisement] = useState(false);
  const [onOthers, setOnOthers] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [expanded, setExpanded] = useState("panel0");
  const [showMore, setShowMore] = useState(false);
  const [isAccordionFunction, setIsAccordionFunction] = useState(false);

  // Get the current date
  const now = new Date();

  // Calculate the expiry date
  const expiry = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

  // Format the expiry date as a string in the correct format for cookies
  const expiryString = expiry.toUTCString();

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleAccordionFunction = (event, isExpanded) => {
    if (!onFunctional) {
      setIsAccordionFunction(isExpanded);
    }
  };

  //expand accordion handleChange
  const handleChange = (panel) => (event, newExpanded) => {
    
    setExpanded(newExpanded ? panel : false);
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
  };
  const handleSettingsClose = () => {
    setOpenSettings(false);
    // if (onFunctional === true) {
    //   dispatch(
    //     updateCustomAttributes({
    //       type: "IS_COOKIES",
    //       payload: true,
    //     })
    //   );
    // }
  };
  const handleSave = () => {
    setOpenSettings(false);
    // if (onFunctional === true) {
    dispatch(
      updateCustomAttributes({
        type: "IS_COOKIES",
        payload: true,
      })
    );
    // }
  };
  const handleOnNecessary = (event) => {
    setOnNecessary(event.target.checked);
  };

  const handleOnFunctional = (event) => {
    setOnFunctional(event.target.checked);
    if (event.target.checked === true) {
      // set the cookie if switch is on
      // document.cookie = "functional=yes; expires=Fri, 31 Dec 9999 23:59:59 GMT";
         document.cookie = `functional=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_FUNCTIONAL",
          payload: event.target.checked,
        })
      );
    }
    if (event.target.checked === false) {
      // delete the cookie if switch is off
      // document.cookie = "functional=no; expires=Thu, 01 Jan 1970 00:00:00 GMT";
         document.cookie = `functional=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_FUNCTIONAL",
          payload: event.target.checked,
        })
      );
    }
  };

  const handleOnPerformance = (event) => {
    setOnPerformance(event.target.checked);
    if (event.target.checked === true) {
      
      // set the cookie if switch is on
      document.cookie = `performance=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_PERFORMANCE",
          payload: event.target.checked,
        })
      );
    }
    if (event.target.checked === false) {
      
      // delete the cookie if switch is off
      document.cookie = `performance=no; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_PERFORMANCE",
          payload: event.target.checked,
        })
      );
    }
  };

  const handleOnAnalytics = (event) => {
    setOnAnalytics(event.target.checked);
    if (event.target.checked === true) {
      // set the cookie if switch is on
      document.cookie = `analytics=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_ANALYTICS",
          payload: event.target.checked,
        })
      );
    }
    if (event.target.checked === false) {
      // delete the cookie if switch is off
      document.cookie = `analytics=no; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_ANALYTICS",
          payload: event.target.checked,
        })
      );
    }
  };

  const handleOnAdvertisement = (event) => {
    setOnAdvertisement(event.target.checked);
    if (event.target.checked === true) {
      // set the cookie if switch is on
      document.cookie = `advertisement=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_ADVERTISEMENT",
          payload: event.target.checked,
        })
      );
    }
    if (event.target.checked === false) {
      // delete the cookie if switch is off
      document.cookie = `advertisement=no; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_ADVERTISEMENT",
          payload: event.target.checked,
        })
      );
    }
  };

  const handleOnOthers = (event) => {
    setOnOthers(event.target.checked);
    if (event.target.checked === true) {
      // set the cookie if switch is on
      document.cookie = `others=yes; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_OTHERS",
          payload: event.target.checked,
        })
      );
    }
    if (event.target.checked === false) {
      // delete the cookie if switch is off
      document.cookie = `others=no; expires=${expiryString};`;
      dispatch(
        updateCustomAttributes({
          type: "IS_OTHERS",
          payload: event.target.checked,
        })
      );
    }
  };

  //Accept
  const handleAcceptOpen = () => {
    // setOpenAccept(true);
    dispatch(
      updateCustomAttributes({
        type: "IS_COOKIES",
        payload: true,
      })
    );
    dispatch(
      updateCustomAttributes({
        type: "IS_COOKIES_ACCEPT",
        payload: true,
      })
    );
  };
  // const handleAcceptClose = () => {
  //   setOpenAccept(false);
  //   dispatch(
  //     updateCustomAttributes({
  //       type: "IS_COOKIES",
  //       payload: true,
  //     })
  //   );
  // };

  //Reject
  const handleRejectOpen = () => {
    // setOpenReject(true);
    dispatch(
      updateCustomAttributes({
        type: "IS_COOKIES",
        payload: true,
      })
    );
    dispatch(
      updateCustomAttributes({
        type: "IS_COOKIES_REJECT",
        payload: true,
      })
    );
  };
  // const handleRejectClose = () => {
  //   setOpenReject(false);
  //   dispatch(
  //     updateCustomAttributes({
  //       type: "IS_COOKIES",
  //       payload: true,
  //     })
  //   );
  // };

  return (
    <div>
      <div className={style.cookie_banner}>
        <Row>
          <p style={{ color: "black", fontWeight: "700", fontSize: "18px" }}>
            This website uses cookies
          </p>
        </Row>
        <Row>
          <Col lg={8} className={style.cookie_banner_text}>
            <p style={{ fontWeight: "400" }}>
              {/* We use necessary cookies to improve your website experience and
              analyse the site use. */}
              We use cookies on our website to give you the most relevant
              experience by remembering your preferences and repeat visits. By
              clicking “Accept All”, you consent to the use of ALL the cookies.
              However, you may visit "Cookie Settings" to provide controlled
              consent.
              {/* For more detailed information about the
              cookies we use, see our{" "}
              <a
                // href={TermsandConditions}
                // rel="noreferrer"
                style={{ color: "#E42313" }}
                target="_blank"
              >
                Cookie Policy
              </a>
              .{" "} */}
            </p>
          </Col>
          <Col lg={4} className={style.cookie_buttons}>
            <Row>
              <Col lg={4} className="px-2 py-2 d-flex">
                <button className={style.settings} onClick={handleSettingsOpen}>
                  Cookie Settings
                </button>
              </Col>
              <Col lg={4} className="px-2 py-2 d-flex">
                <button className={style.accept} onClick={handleAcceptOpen}>
                  Accept All Cookies
                </button>
              </Col>
              <Col lg={4} className="px-2 py-2 d-flex">
                <button className={style.reject} onClick={handleRejectOpen}>
                  Decline All Cookies
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Dialog
          open={openSettings}
          onClose={handleSettingsClose}
          style={{ zIndex: 9999 }}
        >
          <Paper
            style={{ backgroundColor: "rgba(254, 254, 254, 0.8) !important" }}
          >
            {/* <DialogTitle style={{ color: "black", fontWeight: "700" }}> */}{" "}
            {/* Privacy Overview */}
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleSettingsClose}
              style={{ color: "black", fontWeight: "700" }}
            >
              Privacy Overview
            </BootstrapDialogTitle>
            {/* </DialogTitle> */}
            <DialogContent>
              <DialogContentText style={{ color: "black" }}>
                {/* <DialogContentText> */}
                <p style={{ fontWeight: 100 }}>
                  {showMore ? text : `${text.slice(0, 250)}...`}
                </p>
                <br />
                <a
                  onClick={toggleShowMore}
                  style={{
                    textDecorationColor: "black",
                    textDecoration: "underline",
                  }}
                >
                  {showMore ? "Show Less" : "Show More"}
                </a>
              </DialogContentText>
              <br />
              {/* ------------Necessary-------- */}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  // expandIcon={<ExpandMoreIcon />}
                >
                  <div className={classes.summaryContainer}>
                    <Typography
                      className={
                        width > 590 ? classes.heading : classes.heading_mobile
                      }
                    >
                      Necessary
                    </Typography>
                    <Typography
                      className={
                        width > 590 ? classes.subtitle : classes.subtitle_mobile
                      }
                    >
                      {onNecessary === true ? "Always Enabled" : "Disabled"}
                      {/* <FormControlLabel
                      // sx={{ mt: 1 }}
                      control={
                        <Switch
                          checked={onNecessary}
                          onChange={handleOnNecessary}
                        />
                      }
                      label={onNecessary ? "Always Enabled" : "Disabled"}
                      labelPlacement="start"
                    /> */}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Necessary cookies are absolutely essential for the website
                    to function properly. These cookies ensure basic
                    functionalities and security features of the website,
                    anonymously.
                  </Typography>
                  <br />
                  <Typography>
                    <TableContainer component={Paper}>
                      {/* <Table sx={{ minWidth: 700 }} aria-label="customized table"> */}
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow style={{ backgroundColor: "#F0F2F2" }}>
                            <StyledTableCell>Cookie</StyledTableCell>
                            <StyledTableCell align="left">
                              Duration
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Description&nbsp;(g)
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <StyledTableRow key={row.cookie}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                width="30%"
                              >
                                {row.cookie}
                              </StyledTableCell>
                              <StyledTableCell align="left" width="20%">
                                {row.duration}
                              </StyledTableCell>
                              <StyledTableCell align="left" width="40%">
                                {row.desc}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <div style={{ minHeight: "11px" }} />
              {/* ------------Functional-------- */}
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                // expanded={isAccordionFunction}
                // onChange={handleAccordionFunction}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <div className={classes.summaryContainer}>
                    <Typography className={classes.heading_functional}>
                      Functional
                    </Typography>
                    <Typography
                      className={
                        width > 590
                          ? classes.subtitle_function
                          : classes.subtitle_mobile_func
                      }
                    >
                      {/* <ThemeProvider theme={theme}> */}
                      <FormControlLabel
                        // sx={{ marginLeft:"8px" }}
                        control={
                          <AntSwitch
                            checked={onFunctional}
                            onChange={handleOnFunctional}
                            sx={{
                              marginLeft: "8px",
                              "& .MuiSwitch-track": {
                                bgcolor: onFunctional ? "#4caf50" : "#bdbdbd",
                              },
                              "& .MuiSwitch-thumb": {
                                bgcolor: onFunctional ? "#f5f5f5" : "#f5f5f5",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: onFunctional ? "#4caf50" : "",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  bgcolor: onFunctional ? "#4caf50" : "",
                                },
                            }}
                          />
                        }
                        label={onFunctional ? "Enabled " : "Disabled"}
                        labelPlacement="start"
                      />
                      {/* </ThemeProvider> */}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Functional cookies help to perform certain functionalities
                    like sharing the content of the website on social media
                    platforms, collect feedbacks, and other third-party
                    features.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <div style={{ minHeight: "11px" }} />
              {/* ------------Performance-------- */}
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  // expandIcon={<ExpandMoreIcon />}
                >
                  <div className={classes.summaryContainer}>
                    <Typography className={classes.heading_functional}>
                      Performance
                    </Typography>
                    <Typography
                      className={
                        width > 590
                          ? classes.subtitle_performance
                          : classes.subtitle_mobile_per
                      }
                    >
                      {/* <FormControlLabel
                      // sx={{ mt: 1 }}
                      control={
                        <Switch
                          checked={onPerformance}
                          onChange={handleOnPerformance}
                        />
                      }
                      label=""
                      labelPlacement="start"
                    /> */}
                      <FormControlLabel
                        // sx={{ mt: 1 }}
                        control={
                          <AntSwitch
                            checked={onPerformance}
                            onChange={handleOnPerformance}
                            sx={{
                              marginLeft: "8px",
                              "& .MuiSwitch-track": {
                                bgcolor: onPerformance ? "#4caf50" : "#bdbdbd",
                              },
                              "& .MuiSwitch-thumb": {
                                bgcolor: onPerformance ? "#f5f5f5" : "#f5f5f5",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: onPerformance ? "#4caf50" : "",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  bgcolor: onPerformance ? "#4caf50" : "",
                                },
                            }}
                          />
                        }
                        label={onPerformance ? "Enabled " : "Disabled"}
                        labelPlacement="start"
                      />
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Performance cookies are used to understand and analyze the
                    key performance indexes of the website which helps in
                    delivering a better user experience for the visitors.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <div style={{ minHeight: "11px" }} />

              {/* ------------Analytics-------- */}
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <div className={classes.summaryContainer}>
                    <Typography className={classes.heading_functional}>
                      Analytics
                    </Typography>
                    <Typography
                      className={
                        width > 590
                          ? classes.subtitle_analy
                          : classes.subtitle_mobile_analy
                      }
                    >
                      <FormControlLabel
                        // sx={{ mt: 1 }}
                        control={
                          <AntSwitch
                            checked={onAnalytics}
                            onChange={handleOnAnalytics}
                            sx={{
                              marginLeft: "8px",
                              "& .MuiSwitch-track": {
                                bgcolor: onAnalytics ? "#4caf50" : "#bdbdbd",
                              },
                              "& .MuiSwitch-thumb": {
                                bgcolor: onAnalytics ? "#f5f5f5" : "#f5f5f5",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: onAnalytics ? "#4caf50" : "",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  bgcolor: onAnalytics ? "#4caf50" : "",
                                },
                            }}
                          />
                        }
                        label={onAnalytics ? "Enabled " : "Disabled"}
                        labelPlacement="start"
                      />
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Analytical cookies are used to understand how visitors
                    interact with the website. These cookies help provide
                    information on metrics the number of visitors, bounce rate,
                    traffic source, etc.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <div style={{ minHeight: "11px" }} />

              {/* -----------Advertisement-------- */}
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                >
                  <div className={classes.summaryContainer}>
                    <Typography className={classes.heading_functional}>
                      Advertisement
                    </Typography>
                    <Typography
                      className={
                        width > 590
                          ? classes.subtitle_Advertisement
                          : classes.subtitle_mobile_Adv
                      }
                    >
                      <FormControlLabel
                        // sx={{ mt: 1 }}
                        control={
                          <AntSwitch
                            checked={onAdvertisement}
                            onChange={handleOnAdvertisement}
                            sx={{
                              marginLeft: "8px",
                              "& .MuiSwitch-track": {
                                bgcolor: onAdvertisement
                                  ? "#4caf50"
                                  : "#bdbdbd",
                              },
                              "& .MuiSwitch-thumb": {
                                bgcolor: onAdvertisement
                                  ? "#f5f5f5"
                                  : "#f5f5f5",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: onAdvertisement ? "#4caf50" : "",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  bgcolor: onAdvertisement ? "#4caf50" : "",
                                },
                            }}
                          />
                        }
                        label={onAdvertisement ? "Enabled " : "Disabled"}
                        labelPlacement="start"
                      />
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Advertisement cookies are used to provide visitors with
                    relevant ads and marketing campaigns. These cookies track
                    visitors across websites and collect information to provide
                    customized ads.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <div style={{ minHeight: "11px" }} />

              {/* -----------Others-------- */}
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                >
                  <div className={classes.summaryContainer}>
                    <Typography className={classes.heading_functional}>
                      Others
                    </Typography>
                    <Typography
                      className={
                        width > 590
                          ? classes.subtitle_Oth
                          : classes.subtitle_mobile_Oth
                      }
                    >
                      <FormControlLabel
                        // sx={{ mt: 1 }}
                        control={
                          <AntSwitch
                            checked={onOthers}
                            onChange={handleOnOthers}
                            sx={{
                              marginLeft: "8px",
                              "& .MuiSwitch-track": {
                                bgcolor: onOthers ? "#4caf50" : "#bdbdbd",
                              },
                              "& .MuiSwitch-thumb": {
                                bgcolor: onOthers ? "#f5f5f5" : "#f5f5f5",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: onOthers ? "#4caf50" : "",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  bgcolor: onOthers ? "#4caf50" : "",
                                },
                            }}
                          />
                        }
                        label={onOthers ? "Enabled " : "Disabled"}
                        labelPlacement="start"
                      />
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: "black" }}>
                    Other uncategorized cookies are those that are being
                    analyzed and have not been classified into a category as
                    yet.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Row className="mt-3">
              <Col lg={10}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </p>
              </Col>
              <Col lg={2}>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch checked={onSettings} onChange={handleOnSettings} />
                  }
                  label=""
                  labelPlacement="start"
                />
              </Col>
            </Row> */}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleSettingsClose}>SAVE & ACCEPT</Button> */}
              <button className={style.save} onClick={handleSave}>
                SAVE & ACCEPT
              </button>
            </DialogActions>
          </Paper>
        </Dialog>

        {/* //-----------Accept Dialog--------// */}
        {/* <Dialog open={openAccept} onClose={handleAcceptClose}>
          <DialogTitle> Accept All</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Accept All cookiees.You can able to create/buy policy
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAcceptClose}>Close</Button>
          </DialogActions>
        </Dialog> */}

        {/* //-----------Reject Dialog--------// */}
        {/* <Dialog open={openReject} onClose={handleRejectClose}>
          <DialogTitle> Reject All</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Reject All cookiees.You cannot able to create/buy policy.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRejectClose}>Close</Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </div>
  );
}

export default CookieBanner;
