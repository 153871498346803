import Button from "components/Buttons/Button";
import React, { useEffect, useState } from "react";

// <--------- React Bootstrap -------->
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { validate } from "validate.js";

// <--------- Essential Components -------->
import { Divider, Select, TextInput } from "../../../components";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";
import { toast } from "react-toastify";
import GIFLoader from "../../../assets/images/loader.gif";

// <------------------- Actions --------------------->
import { clearState, updateTryCount, issuePolicy, checkTC } from "store/actions/products";
import { storeState } from "store/actions/prevState";

// <------------------- Global variable --------------------->
import CreditOrderCard from "components/Forms/CreditOrderCard";
import DebitOrderCard from "components/Forms/DebitOrderCard";
import ReCAPTCHA from "react-google-recaptcha";
import CONSTANTS from "utils/constants";

import { Checkbox, FormControlLabel } from "@mui/material";
import { H2Red } from "components/Heading";


const Step6 = ({ handleBack }) => {
  // Attributes  i.e Account holder or Account number
  const [attributes, setAttributes] = useState({});

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Bank details data from the store
  const banks = useSelector((state) => state.products.bankDetails);

  // React-router dom
  const history = useHistory();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const [showExtraContent, setShowExtraContent] = useState(false);

  // functions performs parsing the query from the location URL
  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  // Variable defines the status of the form from the payment gateway.
  const status = parseQuery(location.search);

  // Variable defines number of failed tries.
  const tryCount = useSelector((state) => state.products.tryCount);

  // Reduct hooks
  const dispatch = useDispatch();

  useEffect(() => {
    // if the status is failed for more then 3 times, it will redirect to the homepage..
    if (status.status === "error") {
      if (tryCount >= 3) {
        toast.error(
          "You've tried maximum submission. Please try again after sometime."
        );
        setTimeout(() => {
          history.push("/");
          dispatch(clearState());
        }, 3000);
      } else {
        dispatch(updateTryCount(tryCount + 1));
        toast.error("Payment Failed");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedProduct = useSelector(
    (state) => state.products.selectedProduct
  );

  // Handling synthetic events
  const handleChange = (evt) => {
    setAttributes({
      ...attributes,
      [evt.target.name]: evt.target.value,
    });
  };

  // policy response form CreatePolicy from the store
  const policyResponse = useSelector((state) => state.products.policyResponse);

  // Variable defines the Monthly or Annual
  const [type, setType] = useState("MONTHLY");

  useEffect(
    () => {
      // Check the selected product is annual or monthly which defines the payment type.
      if (selectedProduct.name.toLowerCase().includes("monthly")) {
        setType("MONTHLY");
      } else {
        setType("ANNUAL");
      }
      dispatch(storeState());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]
  );

  const [errors, setErrors] = useState({});

  // functions perform submitting the form
  const handleSubmit = async () => {
    try {
      // <---- Errors check ---->
      const errors = validate(attributes, debitSchema);
      if (errors) {
        setErrors(errors);
        return;
      }

      setIsLoading(true);

      // Validating the OTP
      // const res = await dispatch(validateOTP(mobileOTP));

      // if (res) {
      // Dispatch the policy values and hitting the issue policy API.
      const res = await dispatch(
        issuePolicy({
          externalReference: policyResponse.externalReference,
          policySaleReference: policyResponse.policySaleReference,
          comment: "Issue Sale from Web App",
          policies: [
            {
              policyReference:
                policyResponse.policies?.length &&
                policyResponse.policies[0].policyReference,
              bankAccounts: [
                {
                  externalReference: "CREATE_BANK_100",
                  type: attributes.type,
                  pennyDropStatus: "verified",
                  pennyDropMessage: "Verified by XYZ",
                  bank: attributes.bank,
                  accountNumber: attributes.accountNumber,
                  accountHolder: attributes.accountHolder,
                  branch: attributes.branch,
                  branchCode: attributes.branchCode,
                  iban: "IB-200",
                  swift: "SW-200",
                },
              ],
            },
          ],
          webhook: {
            webhookUrl:
              "https://briisk-i-dev-policies.azurewebsites.net/webhook/saveWebhook",
          },
        })
      )

      if(res.status === 202 || res.status === 200 ){
          history.push({
            pathname: "/payment-success",
            state: { type: "DEBIT" },
          });
      }else{
        toast.error('Error occured')
      }
      setIsLoading(false);
    } catch (err) {}
  };

  // Company details from the store
  const companyDetails = useSelector((state) => state.products.companyDetails);
  const tCChecked = useSelector((state) => state.products.tCChecked);

 const handlesetIsVisible = () => {
   setShowExtraContent(true);
  setIsVisible(true)
 }

  const handleTC = () => {
    dispatch(checkTC(!tCChecked));
  };

  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const handleBackButton = () => {
    handleBack(4);
  };

  const [isRecaptchaComplete, setIsRecaptachaComplete] = useState(false);


  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };

  return (
    <div>
      {/* <---------- Title -------> */}
      <h4>Purchase Summary</h4>

      {/* <------- Sub title ------> */}
      <h6>Details</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}

      {type === "ANNUAL" ? (
        <div className="d-flex justify-content-center mt-4 align-items-center">
          <CreditOrderCard
            title={selectedProduct.name}
            containerStyle={{ width: 500 }}
            sub={selectedProduct.description}
            setIsVisible={setIsVisible}
            price={`R ${decimalCheck(parseFloat(
              getProrate(
                selectedProduct.risks && selectedProduct.risks[0].rating.message
              ).prorataAnnualTotal
            )
              .toLocaleString("fi-FI")
              .replaceAll(",", "."))} pa`}
            // price={`R ${(
            //   Math.floor(
            //     parseFloat(
            //       getProrate(
            //         selectedProduct.risks &&
            //           selectedProduct.risks[0].rating.message
            //       ).prorataAnnualTotal
            //     ) * 100
            //   ) / 100
            // )
            //   ?.toString()
            //   .replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ")} pa`}
            LPA={decimalCheck(
              parseFloat(
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).annualPremium
              )
                .toLocaleString("fi-FI")
                .replaceAll(",", ".")
            )}
            policyStartDate={
              getProrate(
                selectedProduct.risks && selectedProduct.risks[0].rating.message
              ).policyStartDate
            }
            policyEndDate={
              getProrate(
                selectedProduct.risks && selectedProduct.risks[0].rating.message
              ).policyEndDate
            }
            policyRenewalDate={
              getProrate(
                selectedProduct.risks && selectedProduct.risks[0].rating.message
              ).policyRenewalDate
            }
            selectedProduct={selectedProduct}
            companyDetails={companyDetails}
            policyResponse={policyResponse}
            handleTC={handleTC}
            tCChecked={tCChecked}
          />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-center mt-4 align-items-center flex-column">
            <DebitOrderCard
              title={selectedProduct.name}
              sub={selectedProduct.description}
              price={`R ${decimalCheck(
                parseFloat(
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  ).policyCollectionAmount
                )
                  .toLocaleString("fi-FI")
                  .replaceAll(",", ".")
              )} pm`}
              LPA={decimalCheck(
                parseFloat(
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  ).annualPremium
                )
                  .toLocaleString("fi-FI")
                  .replaceAll(",", ".")
              )}
              proratePremium={decimalCheck(
                parseFloat(
                  getProrate(
                    selectedProduct.risks &&
                      selectedProduct.risks[0].rating.message
                  ).prorataPremium
                )
                  .toLocaleString("fi-FI")
                  .replaceAll(",", ".")
              )}
              monthlyProratePremium={decimalCheck(
                parseFloat(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.total.toFixed(2)
                )
                  .toLocaleString("fi-FI")
                  .replaceAll(",", ".")
              )}
              policyStartDate={
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).policyStartDate
              }
              debitDate={
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).policyCollectionDate
              }
              policyEndDate={
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).policyEndDate
              }
              policyRenewalDate={
                getProrate(
                  selectedProduct.risks &&
                    selectedProduct.risks[0].rating.message
                ).policyRenewalDate
              }
              selectedProduct={selectedProduct}
              companyDetails={companyDetails}
              policyResponse={policyResponse}
              handleTC={handleTC}
              tCChecked={tCChecked}
            />
            <Col xs={12} md={5}>
              <TextInput
                label="Account holder name*"
                placeholder="- full name"
                value={attributes.accountHolder}
                name="accountHolder"
                onChange={handleChange}
                errors={errors.accountHolder}
              />
              <TextInput
                label="Account number*"
                placeholder="- Your account number"
                value={attributes.accountNumber}
                name="accountNumber"
                onChange={handleChange}
                errors={errors.accountNumber}
              />
              <Select
                label="Account type*"
                placeholder="- Select type"
                options={["Savings", "Current"]}
                value={attributes.type}
                name="type"
                onChange={handleChange}
                errors={errors.type}
              />
              <Select
                label="Bank name*"
                placeholder="- Select your bank"
                options={banks.items.map((bank) => bank.code)}
                value={attributes.bank}
                name="bank"
                onChange={(e) => {
                  handleChange(e);
                  setAttributes({
                    ...attributes,
                    bank: e.target.value,
                    branchCode: banks.items.find(
                      (find) => find.code === e.target.value
                    )?.value2,
                  });
                }}
                errors={errors.bank}
              />
              <TextInput
                label="Branch Code*"
                placeholder="- Your branch code"
                value={attributes.branchCode}
                name="branchCode"
                onChange={handleChange}
                disabled={true}
                errors={errors.branchCode}
              />
            </Col>
            <Col xs={12} md={5}>
              <div className="d-flex justify-content-start align-items-center">
                <FormControlLabel
                  className="mt-3 mb-2"
                  onClick={handleTC}
                  control={
                    <Checkbox
                      checked={tCChecked ? tCChecked : null}
                      sx={{
                        "&.Mui-checked": {
                          color: "#DA291C",
                          fontSize: 8,
                        },
                      }}
                    />
                  }
                  label=""
                />
                <div
                  onClick={handlesetIsVisible}
                  className="mt-2 t_c_text"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  I accept the T&C's
                </div>
              </div>

              <ReCAPTCHA
                sitekey={CONSTANTS.RECAPTCHA_KEY}
                onChange={() => setIsRecaptachaComplete(true)}
              />
              {isRecaptchaComplete && (
                <Button
                  name="Confirm Purchase"
                  onClick={() => {
                    if (!tCChecked) {
                      return toast.error(
                        "Please accept All terms & conditions."
                      );
                    }
                    handleSubmit();
                  }}
                  color="#fff"
                  containerStyle={{
                    width: "100%",
                    marginTop: "10px",
                  }}
                  bg="#24B03C"
                  id="form-7-confirm-purchase-button"
                />
              )}
            </Col>
          </div>
        </div>
      )}

      <Modal show={isVisible} onHide={() => setIsVisible(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center flex-column mb-5 align-items-center">
            <H2Red>Privacy</H2Red>
            <span>
              In accordance with the applicable laws, we may be required to
              share your personal information with other insurers, industry
              bodies, credit agencies and service providers. This includes
              information about your insurance, claims and premium payments. We
              do this to provide insurance services, prevent fraud, assess
              claims and conduct surveys. We will treat your personal
              information with caution and have put reasonable security measures
              in place to protect it. By signing this application for insurance,
              you agree to the processing and sharing of your personal
              information.
            </span>
            <br />
            <br />
            <H2Red>Declaration</H2Red>
            <span>
              That all the information supplied is true and accurate and that
              I/we have not misstated or suppressed any material facts. That the
              information supplied by me/us forms the basis of and will be
              included in this insurance contract. To notify ITOO of any changes
              to the information supplied prior to inception of cover.
            </span>
            <br />
            <br />
            {showExtraContent ? (
              <>
                <H2Red>Debit Order Mandate</H2Red>
                <span>
                  We, the Insured, have elected to pay the Annual Premium by
                  monthly instalment, and authorise ITOO to debit the above bank
                  account (or any other bank to which we may transfer our
                  account). The amount of the debit may vary from time to time
                  to reflect any changes in cover or premium.
                  <br />
                  <br />
                  <div className="d-flex flex-row">
                    <span>We accept and agree to the following:</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "28px" }}>1.</div>
                    <div>
                      &nbsp;The monthly instalment shall be payable on the debit
                      date reflected above.
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "28px" }}>2.</div>
                    <div>
                      &nbsp;In the event Hollard does not receive the monthly
                      instalment for any reason whatsoever, then a double debit
                      will be made the following month. Should this fail then
                      the cover shall, notwithstanding anything to the contrary
                      contained in the policy, be deemed to have been cancelled
                      from the end of the last month for which the premium
                      payments were paid.
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "28px" }}>3.</div>
                    <div>
                      &nbsp;Reinstatement of the policy shall be at the sole
                      discretion of Hollard.
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "28px" }}>4.</div>
                    <div>
                      &nbsp;Note that despite the payment of the premium by way
                      of monthly instalments, this is an annual policy for which
                      an annual premium is payable. Accordingly in the event of
                      notification of any claim or circumstances that might lead
                      to a claim during the Annual Period of Insurance for which
                      any monthly instalment is unpaid then Hollard has the
                      right to reject such claim. Should any payments have been
                      made by Hollard on any such claims then such payments may
                      be recovered from the Insured.
                    </div>
                  </div>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Row className="mt-5">
        <Col sm={2}>
          <ButtonWithDownArrow
            onClick={handleBackButton}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
            id="form-7-back-button"
          />
        </Col>
        <Col sm={10} />
      </Row>

      {isLoading && (
        <div className="gif-loader-fullscreen">
          <img src={GIFLoader} alt="GIFLoader"></img>
        </div>
      )}
    </div>
  );
};

export default Step6;

const debitSchema = {
  accountHolder: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  accountNumber: {
    presence: { allowEmpty: false },
    format: {
      pattern: "^[0-9]+$",
      message: "can only contain number",
    },
  },
  type: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  bank: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  branchCode: {
    presence: { allowEmpty: false },
  },
};
