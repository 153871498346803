import classNames from "classnames";
import { Divider } from "components";
import React from "react";

// import stylesheet
import style from "./ProductCard.module.css";

const SummaryCard = ({
    containerStyle,
    title,
    policyNumber,
    policyHolder,
    premiumPaid,
    policyStartDate,
        type,
    policyRenewalDate,
    policyEndDate,
    proratePremium,
    monthlyProratePremium,
    premiumPaidMonthly,
    selectedProduct,
    policyResponse,
    companyDetails,
    debitDate
}) => {
    return (
      <div
        className={classNames(style.container)}
        style={containerStyle && containerStyle}
      >
        <h3 className={style.title}>Policy: {title}</h3>
        <div className={style.policyPrimary}>Claims Contact Details:</div>
        Contact your broker who will notify iTOO
        <div style={{ marginBottom: "13px" }}></div>
        <Divider />
        <div
          className={classNames(
            "d-flex justify-content-between mt-2",
            style.policyPrimaryContainer
          )}
        >
          <h6 className={style.policyPrimary}>Policy Number: </h6>
          <h6 className={style.policyPrimary}>{policyNumber}</h6>
        </div>
        <div
          className={classNames(
            "d-flex justify-content-between",
            style.policySecondaryContainer
          )}
        >
          <h6 className={style.policySecondary}>Policy Holder: </h6>
          <h6 className={style.policySecondary}>{policyHolder}</h6>
        </div>
        <Divider />
        {type === "credit" ? (
          <div>
            <div
              className={classNames(
                "d-flex mt-3 justify-content-between",
                style.policyPrimaryContainer
              )}
            >
              <h6 className={style.policyPrimary}>Premium Paid: </h6>
              <h6 className={style.policyPrimary}>{premiumPaid}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy Start Date: </h6>
              <h6 className={style.policySecondary}>{policyStartDate}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy End Date: </h6>
              <h6 className={style.policySecondary}>{policyEndDate}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
              <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={classNames(
                "d-flex mt-3 justify-content-between",
                style.policyPrimaryContainer
              )}
            >
              <h6 className={style.policyPrimary}>First Month's Premium: </h6>
              <h6 className={style.policyPrimary}>{premiumPaidMonthly}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Pro Rata Premium: </h6>
              <h6 className={style.policySecondary}>R {proratePremium}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={classNames(style.policySecondary, "m-0 p-0")}>
                Monthly Premium:{" "}
              </h6>
              <h6 className={style.policySecondary}>
                R {monthlyProratePremium}
              </h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policyPrimaryContainer
              )}
            >
              <h6 className={style.policyPrimary}>First Debit Date: </h6>
              <h6 className={style.policyPrimary}>{debitDate}</h6>
            </div>
            <p className={style.spanText}>*and then monthly there after</p>

            <Divider />

            <div
              className={classNames(
                "d-flex justify-content-between mt-2",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy Start Date: </h6>
              <h6 className={style.policySecondary}>{policyStartDate}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy End Date: </h6>
              <h6 className={style.policySecondary}>{policyEndDate}</h6>
            </div>

            <div
              className={classNames(
                "d-flex justify-content-between",
                style.policySecondaryContainer
              )}
            >
              <h6 className={style.policySecondary}>Policy Renewal Date: </h6>
              <h6 className={style.policySecondary}>{policyRenewalDate}</h6>
            </div>
          </div>
        )}
        {/* <Divider />

            <div className={style.cover}>
                Claims Contact Details:-
            </div>
            Contact your broker who will notify iTOO */}
        {/* <ul className="mt-2">
                <li className={style.description_1}>
                    iTOO Cyber Assist Hotline
                </li>
                <li className={style.description_1}>
                    +27 10 020 8712 | cyber_claims@itoo.co.za
                </li>
            </ul> */}
      </div>
    );
}

export default SummaryCard;