// default imports for react project
import React from "react";

// import default style components from react-bootstrap package
import { Row } from "react-bootstrap";

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

// React functional component starts here..
function CardHeader({ image, title, imageStyle }) {
  const { width } = useWindowDimensions();

  // return jsx to display on screen
  return (
    <div>
      <Row className="card__header__container m-0">
        {width > 430 ? (
          <div className="card_header_img">
            <img
              width="50px"
              src={image}
              style={imageStyle}
              alt=""
              className=""
            />
          </div>
        ) : null}
        <span
          className={` card__header ${
            width < 430 ? `justify-content-center w-100` : `w-auto`
          }`}
        >
          {title}
        </span>
      </Row>
    </div>
  );
}

export default CardHeader;
