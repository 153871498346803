// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";
import { parseQuery, setParam } from "utils/utils";


//   import custom component
import BreadCrumb from "components/Breadcrumb";
import {
  H1Red,
  H1Grey,
  H2Grey,
  H4Red,
  RedirectToExternal
} from "components/Heading";

// import function from actions
import { addCrumb } from "store/actions/common";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";


// React functional component starts here..
function About() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentToken =  useSelector(state => state.auth.currentUserToken)

  // react layout hook to perform init operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/about", name: "About" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload:parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  // return jsx to display on screen
  return (
    <div>
      <Container>
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
          <H1Grey className={classNames("mb-5")}>
            We’re always thinking about tomorrow,
          </H1Grey>
          <H2Grey>so you can concentrate on making today count.</H2Grey>
        </Row>
        <Row className={classNames("content mt-5")}>
          <H1Red>About iTOO</H1Red>{" "}
          <div className={classNames("mt-2")}>
            We believe understanding our customers, their business and
            individual needs, is what really sets us apart, sharpens our
            expertise and broadens our service offering.
          </div>
          <div className={classNames("mt-4")}>
            <H4Red>We’re a special risks insurance provider,</H4Red> operating
            as Hollard’s preferred underwriter of specialty products. We see
            iTOO as a niche home for entrepreneurs, start-ups and specialist
            UMAs.
          </div>
          <div className={classNames("mt-4")}>
            <H4Red> We partner with over 650 brokers</H4Red> countrywide and are
            always available nationwide and in selected African regions
            24/7/365.
          </div>
          <div className={classNames("mt-4")}>
            We’re experts in what we do, so you can be an expert in what you do.
          </div>
          <div className={classNames("mt-4")}>
            <H4Red>We have the technical expertise</H4Red> in every sphere of
            our insurance offering and are passionate about finding a way to
            solve any and every problem.
          </div>
          <div className={classNames("mt-4")}>
            <H4Red>
              As a future-focussed business, we are continuously researching new
              developments to deliver the very best specialist products that our
              customers’ complex and growing businesses need.{" "}
              <RedirectToExternal style={{ color: 'black', textDecoration: 'underline' }} target="_blank" href="https://itoo.co.za/our-products/">
                [ See our products ]
              </RedirectToExternal>
            </H4Red>
          </div>
          <div className={classNames("mt-4")}>
            Our people are our experts. We are driven by a high performance
            specialist team, leaders in their individual fields. At iTOO we are
            always ready to engage and understand so we can do more, make it
            work and find a way on time, every time. We are riddle solvers,
            planners, guardians, expert thinkers, industry leaders and
            get-stuck-in-doers. We are always ready to help because we’ve
            planned ahead of every eventuality. That’s the iTOO difference.
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default About;
