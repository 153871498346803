import React from "react";

// <------- MUI Components  ---------->
import { Divider } from "@mui/material";

// <------ React-bootstrap Components ------->
import { Col, Row } from "react-bootstrap";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

// <-------  Components  ---------->
import ProductCard from "../../../components/Forms/ProductCard";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";

// Actions
import { selectProduct } from "store/actions/products";
import { toast } from "react-toastify";



const Step2 = ({ handleNext, handleBack }) => {

    // Selectors from Store
    const productOptions = useSelector(state => state.products?.productOptions?.productOptions);
    const productOptionsNext = useSelector(state => state.products?.productOptionsNext?.productOptions);
    const turnOver = useSelector(state => state.products?.productOptions?.requestBody?.risks && state.products?.productOptions?.requestBody?.risks[0]?.attributes && state.products?.productOptions?.requestBody?.risks[0]?.attributes[1]?.value)
    const product = useSelector(state => state.products?.productOptions?.product);

    // Redux Hooks
    const dispatch = useDispatch();

    // Dispatching selected products
    const handleSelectQuote = (evt) => {
        try {
            if(evt.rating.price  === 0 || evt.rating.price === 0.00){
               return toast.error("Please check the input values")
            }
            dispatch(
                selectProduct(
                    evt
                )
            )
            handleNext(2);
        }
        catch (err) { }
    }

    const getProrate = (string, name) => {
        const arr = string.split(",");
        let obj = {};
        arr.forEach((a) => { const val = a.split(":"); obj[val[0]] = val[1]; })
        return obj;
    }


  const decimalCheck = (string) => {
    if (string.split(".").length <= 1) {
      return `${string}.00`;
    } else  if(string.split(".")[1].length <= 1){
      return `${string}0`;
    }else {
      return string;
    }
  };
  
    return (
        <div>
            {/* <---------- Title -------> */}
            <h4>{product?.name}</h4>

            {/* <------- Sub title ------> */}
            <h6>Select a quote</h6>

            <Divider row={2} />

            {/* <------- Product Cards Starts ------> */}
            <Row >
                {(productOptionsNext?.length ? [...productOptions, ...productOptionsNext] : productOptions)?.map((ele, index) =>
                    <Col key={index} lg={6} sm={12} xs={12} className="mb-5 mt-3" >
                        <ProductCard
                            title={ele.name}
                            sub={ele.name.toLowerCase().includes('monthly') ? 'Total monthly payment' : 'Once off annual payment'}
                            prorata={`${ele.name.toLowerCase().includes('monthly') ? 'Prorata Monthly:' : 'Prorata Annual:'} ${ele.name.toLowerCase().includes('monthly') ?
                                getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataPremium.toLocaleString("fi-FI").replaceAll(",", ".")
                                :
                                getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium.toLocaleString("fi-FI").replaceAll(",", ".")}`}
                            firstCollectionAmount={`First Collection Amount:  ${ele.name.toLowerCase().includes('monthly') ?
                                getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).policyCollectionAmount
                                :
                                getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium}`}
                            price={`R ${ele.name.toLowerCase().includes('monthly')
                                ?  decimalCheck(parseFloat(ele.rating.price.toFixed(2)).toLocaleString("fi-FI").replaceAll(",", "."))
                                // :  decimalCheck(parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium).toLocaleString("fi-FI").replaceAll(",", "."))}
                                // : parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium)?.toFixed(2)?.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ") }
                            //    : (Math.floor(parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium) * 100) / 100).toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ") }
                            // :  decimalCheck(parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium).toLocaleString("fi-FI").replaceAll(",", "."))}
                            :  decimalCheck(parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).prorataAnnualPremium)?.toFixed(2).toString().replaceAll(",", "."))}
                             ${ele.name.toLowerCase().includes('monthly') ? 'pm' : 'pa'}`}
                            coverAmount={ele.body.risks[0].attributes[2].value}
                            turnover={turnOver}
                            excess={`R ${decimalCheck(parseFloat(getProrate(ele.risks[0] && ele.risks[0].rating.message, ele.name).excess).toLocaleString("fi-FI").replaceAll(",", "."))}`}
                            onPress={() => handleSelectQuote(ele)}
                            disabled={(decimalCheck(parseFloat(ele.rating.price.toFixed(2)).toLocaleString("fi-FI").replaceAll(",", ".")) === 0 || decimalCheck(parseFloat(ele.rating.price.toFixed(2)).toLocaleString("fi-FI").replaceAll(",", ".")) === 0.00) ? true : false }
                            description={ele.description}
                        />
                    </Col>
                )}
            </Row>
            {/* <------- Product Cards Ends ------> */}

            {/* <------- Navigator button starts ------> */}
            <Row className="mt-5">
                <Col sm={2}>
                    <ButtonWithDownArrow
                        onClick={handleBack}
                        type="left"
                        name="Back"
                        bg="#dd302a"
                        color="white"
                        id="form-2-back-button"
                    />
                </Col>
                <Col sm={10} />
            </Row>
            {/* <------- Navigator button ends ------> */}
        </div>
    )
}

export default Step2;
