import React from "react";

// <--------- Importing Components from React-bootstrap --------->
import { Container, Nav, Navbar } from "react-bootstrap";

// <--------- Importing Images --------->
import { logo } from "../../assets/images/images";

// <--------- Importing Essential components --------->
import ButtonWithDownArrow from "../Buttons/ButtonWithArrow";
import Button from "../Buttons/Button";

// <--------- Importing Navbar style sheets ---------->
import Style from "./Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addCrumb } from "store/actions/common";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { clearState } from "store/actions/products";
import classNames from "classnames";

import useWindowDimensions from "utils/useWindowDimension";

const NavbarComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const logoURL = useSelector(
    (state) => state.auth.companyDetails?.logoBlobUrl
  );
 const primaryLogo = useSelector((state) => state?.auth?.primaryLogo);

 const handleClaim = () => {
   //link in the same tab
   history.push("/claims");
   // window.location.href = '/agent-logoff'

   //link in a new tab
   //  window.open("https://itoo-dev.tfaforms.net/f/new-claim-notification", "_blank");
   var newTabLink = document.createElement("a");
   newTabLink.href =
     process.env.REACT_APP_ENV_TYPE === "PROD"
       ? "https://online.itoo.co.za/f/new-claim-notification"
       : "https://itoo-dev.tfaforms.net/f/new-claim-notification";
   newTabLink.target = "_blank";
   document.body.appendChild(newTabLink);
   newTabLink.click();
 };

  // console.log("primaryyy-->", primaryLogo !== null, primaryLogo === "",  primaryLogo, logoURL);
  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/*<--------- App Logo Starts ----------> */}

        <Navbar.Brand href="/">
          <img
            className={Style.logo}
            // src={logoURL ? logoURL : logo}
            src={(primaryLogo !== null && primaryLogo.trim() !== "") ? primaryLogo : logoURL ? logoURL : logo}
            alt="logo"
          />
        </Navbar.Brand>
        {width < 770 && (
          <ButtonWithDownArrow
            name="Buy Insurance"
            bg={width > 770 ? "black" : "rgb(53, 63, 78)"}
            color="white"
            width={width < 330 ? "124px" : ""}
            screenWidth={width}
            fontSize={width < 330 && "14px"}
            padding={width < 330 && "10px 0px 10px 0px"}
            onClick={() => {
              dispatch(clearState()).then(() => {
                if (location.pathname === "/forms") {
                  window.location.reload();
                } else {
                  window.location.href = "/forms";
                }
              });
            }}
          />
        )}
        {/*<--------- App Logo Ends ------------> */}

        {/*<--------- Menus Starts -------------> */}

        <Navbar.Collapse className="navbar-expand-md" id="basic-navbar-nav">
          <Nav className="inline-flex justify-content-end w-100">
            <div
              className={classNames(
                width > 770 ? `d-flex` : ``,
                Style.header_navs
              )}
              style={{
                width: width > 770 ? (width < 1025 ? "55%" : "45%") : "100%",
              }}
            >
              <Nav.Link href="/" active={location.pathname === "/"}>
                Home
              </Nav.Link>
              <Nav.Link href="/about" active={location.pathname === "/about"}>
                About
              </Nav.Link>
              <Nav.Link href="/faq" active={location.pathname === "/faq"}>
                FAQs
              </Nav.Link>
              <Nav.Link
                href="/fast-facts"
                active={location.pathname === "/fast-facts"}
                onClick={() => {
                  dispatch(
                    addCrumb([{ link: "/fast-facts", name: "Fast Facts" }])
                  );
                }}
              >
                Fast Facts
              </Nav.Link>
              <Nav.Link
                href="/contact"
                active={location.pathname === "contact"}
              >
                Contact
              </Nav.Link>
            </div>
            <div className={width > 374 ? "d-flex" : ""}>
              {width > 770 && (
                <ButtonWithDownArrow
                  name="Buy Insurance"
                  bg="rgb(53, 63, 78)"
                  color="white"
                  id="landing-page-buy-insurance-button"
                  onClick={() => {
                    dispatch(clearState()).then(() => {
                      if (location.pathname === "/forms") {
                        window.location.reload();
                      } else {
                        window.location.href = "/forms";
                      }
                    });
                  }}
                />
              )}
              <div style={{ marginLeft: width > 770 ? 20 : 0 }}>
                <Button
                  name="Submit Claim"
                  shadow={true}
                  bg="white"
                  color="#6e6f6c"
                  // onClick={() => (window.location.href = "/claims")}
                  onClick={handleClaim}
                  id="landing-page-submit-claim-button"
                />
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>

        {/*<--------- Menus Ends ----------> */}
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
