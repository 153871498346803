import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import ButtonWithDownArrow from "components/Buttons/ButtonWithArrow";

// <--------- React Bootstrap -------->
import { Col, Row } from "react-bootstrap";

// <---------- Redux Hooks ----------->
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// <---------- Actions ----------->
import { updateCompanyDetails, CreatePolicy } from "store/actions/products";

// <---------- Global Variable ----------->
import CONSTANTS,{ PRODUCTION_CONSTANTS, HIDE_QUESTION } from "utils/constants";

import useWindowDimensions from "utils/useWindowDimension";
import { dateFormatter } from "utils/utils";
import validate from "validate.js";
import GIFLoader from "../../../assets/images/loader.gif";

// <--------- Essential Components -------->
import {
  Divider,
  Select,
  VerticalSpacer,
  TextInput,
  SelectWithRadioHorizontal,
} from "../../../components";

const Step4 = ({ handleNext, handleBack }) => {
  // <--------- Company details from store ---------->
  const companyDetails = useSelector((state) => state.products.companyDetails);

  // <--------- Assigning company details to the local state --->
  const [attributes, setAttributes] = useState(companyDetails);

  // <--------- product from the store --->
  const product = useSelector(
    (state) => state.products?.productOptions?.product
  );
  const [isLoading, setIsLoading] = useState(false);

  // <---------  Synthetic events --->
  const handleChange = (evt) => {
    // console.log("evtt before--->", evt.target.value);
    //  evt.target.value = evt.target.value.trim();
    setAttributes({
      ...attributes,
      [evt.target.name]: evt.target.value,
    });
  };

  // Redux Hooks
  const dispatch = useDispatch();

  // Risk attributes from the Product
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  const premiumBreakdownAttributes = useSelector((state) => state?.products?.riskAttributes?.find((x) => x.code === "CSPB")?.attributes);
  
  // Risk attributes from the Product
  const presistedRisks = useSelector((state) => state.products.presistedRiskAttributes);

  // selected option i.e ICU, CEU, ICU
  const selectedOption = useSelector((state) => state.products.selectedOption);

  const products = useSelector((state) => state.products);

  const auth = useSelector((state) => state.auth);

  const token = auth.jwtToken;

  const { width } = useWindowDimensions();

  const [errors, setErrors] = useState({});

  const selectedProduct = useSelector((state) => state.products.selectedProduct);

  // External reference
  const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

  const getProrate = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => { const val = a.split(":");obj[val[0]] = val[1];})
    return obj;
  }

  const [ isValid, setIsValid] = useState(true);

  useEffect(() => {
    setAttributes({
      ...attributes,
      country: 'South Africa'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useLayoutEffect(() => {
    var arr = [
      ...riskAttributes[1].attributes,
      ...riskAttributes[3].attributes,
      ...riskAttributes[4].attributes,
      ...riskAttributes[5].attributes,
    ];
    if(arr.filter((filter) => filter.value).some((some) => some.value.toLowerCase() === 'no')){
        setIsValid(false);
    }else{
        if(riskAttributes[0].attributes[0].value.includes("*")){       
          setIsValid(false);
        }else{
          setIsValid(true);
        }
      }
  },[riskAttributes]);


  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  // Submitting the form
  const handleSubmit = async () => {

    // Updating the company details values to the store.
    dispatch(updateCompanyDetails(attributes));

    // constraints for the form values
    var constraints = {
      ...companySchema,
      workCompany: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? false : true,
        },
      },
      idNumber: {
        presence: {
          allowEmpty:
            attributes.workCompanyOptions === "Company" ? true : false,
            message: "^ ID Number can't be blank"
        },
        format: {
          pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))((\d{4})(\d{3})|(\d{7}))/,
          message: "^ Invalid South African ID Number"
        } 
      },
    };

    if (attributes.workCompanyOptions !== "Company") {
      delete constraints.companyRegNo;
      delete constraints.vatNumber;
      delete constraints.workCompany;
    }

    if (attributes.workCompanyOptions === "Company") {
      delete constraints.idNumber;
    }

    // <---- Consolidating Errors values ---->
    const errors = validate(attributes, constraints);
    if (errors) {
      setErrors(errors);
      return;
    }else{
      setErrors({});
    }

    if(attributes.mobilePhone){

      var trimmed = attributes.mobilePhone.replace(/\s/g, '');

      var regex = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

      if (regex.test(trimmed) === true) {
        if(errors !== undefined){
          var obj = errors;
          delete obj.mobilePhone
          setErrors(obj);
        }
         
      }else{
        setErrors({
          ...errors,
          mobilePhone:[ 'Invalid Number']
        });
        return;
      }
    }
    setIsLoading(true);
    try {
      console.log('vatt-->', attributes?.vatNumber)
      // Dispatching the values and call the API for Create policy.
      let beneficiaries = {
        ...attributes,
        customerIdentityType: 1,
        companyName: attributes.workCompanyOptions === 'Company' ? attributes.workCompany : `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
        pin: attributes.postalStateCode
      };
    
      let members = {
        ...attributes,
        customerIdentityType:
          attributes.workCompanyOptions === "Sole proprietor" ? 2 : 1,
        companyName:
          attributes.workCompanyOptions === "Company"
            ? attributes.workCompany
            : `${attributes.title} ${attributes.firstName} ${attributes.lastName}`,
        pin: attributes.postalStateCode,
        nationalityId:
          attributes.workCompanyOptions === "Company"
            ? attributes.companyRegNo
            : attributes.idNumber,
        companyRegNo: attributes.companyRegNo,
        vatNumber: attributes.vatNumber ?? "" ,
      };
      delete beneficiaries?.idNumber;
      delete members?.idNumber;
        const response = await axios.post(
        `${CONSTANTS.MIDDLEWARE_URL}/policy/createPolicySale`,
        {
          policies: [
            {
              startDate:dateFormatter(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyStartDate),
              endDate: dateFormatter(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyEndDate),
              PolicyStatus: isValid ? 'Quoted' : "Referral",
              currencyCode: "ZAR",
              members: [
                members
              ],
              beneficiaries: [
                beneficiaries
              ],
              attributes: [],
              risks: [
                {
                  externalReference: uniqueId(),
                  attributes: [...riskAttributes[0].attributes.filter((f) => !HIDE_QUESTION.includes(f.instanceId)).map((d) => ({
                    name: d.name,
                    value: d.value,
                  })), {name: companyOrSoleProprietor.name, value: attributes.workCompanyOptions }],
                  valuations: products.selectedProduct.valuations.values,
                  productRiskReference: riskAttributes[0]?.instanceId,
                  price: parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataAnnualPremium.replaceAll(' ','')), // Debit -> same as now || Credti -> prorataannualPremium
                  tax: parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataAnnualTax.replaceAll(' ','')),// Debit -> same as now || Credti -> prorataannualTax
                  discount: parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataAnnualDiscount.replaceAll(' ','')),// Debit -> same as now || Credti -> prorataannualDiscount
                  total: parseFloat(getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataAnnualTotal.replaceAll(' ','')),// Debit -> same as now || Credti -> prorataannualTotal
                },
                {
                  externalReference: uniqueId(),
                  attributes: riskAttributes[1].attributes
                    .filter((filter) => filter.value !== null)
                    .map((d) => ({ name: d.name, value: d.value })),
                  valuations: products?.selectedProduct?.risks?.find((x) => x.code === riskAttributes[1]?.code)?.valuations?.values ?? [],
                  productRiskReference: riskAttributes[1]?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                {
                  externalReference: uniqueId(),
                  attributes: [
                    {
                      name: riskAttributes[2].name,
                      value: selectedOption,
                    },
                  ],
                  valuations: products?.selectedProduct?.risks?.find((x) => x.code === riskAttributes[2]?.code)?.valuations?.values ?? [],
                  productRiskReference: riskAttributes[2]?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                {
                  externalReference: uniqueId(),
                  attributes: riskAttributes[
                    selectedOption === "OSE"
                      ? 3
                      : selectedOption === "CEU"
                      ? 4
                      : 5
                  ].attributes
                    .filter((filter) => filter.value !== undefined && filter.value !== null)
                    .map((d) => ({ name: d.name, value: d.value })),
                  valuations: products?.selectedProduct?.risks?.find((x) => x.code === riskAttributes[selectedOption === "OSE" ? 3 : selectedOption === "CEU" ? 4 : 5]?.code)?.valuations?.values ?? [],
                  productRiskReference:
                    riskAttributes[
                      selectedOption === "OSE"
                        ? 3
                        : selectedOption === "CEU"
                        ? 4
                        : 5
                    ]?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                {
                  externalReference: uniqueId(),
                  attributes: [{
                    name: 'Annual Premium',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).annualPremium,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Annual Premium")[0]?.dataType,
                  },
                  {
                    name: 'Pro-Rata Annual Premium',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataAnnualPremium,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Pro-Rata Annual Premium")[0]?.dataType,

                  },
                  {
                    name: 'Monthly premium',
                    value: selectedProduct.risks[0].rating.total,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Premium")[0]?.dataType,

                  },
                  {
                    name: 'Pro-Rata Premium',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).prorataPremium,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Pro-Rata Premium")[0]?.dataType,

                  },
                  {
                    name: 'First Collection Date',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyCollectionDate,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "First Collection Date")[0]?.dataType,

                  },
                  {
                    name: 'First Collection Amount',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyCollectionAmount,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "First Collection Amount")[0]?.dataType,

                  },
                  {
                    name: 'Payment Frequency',
                    value: selectedProduct.name.toLowerCase().includes('monthly') ? 'Monthly' : 'Annually',
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Payment Frequency")[0]?.dataType,

                  },
                  {
                    name: 'Policy End Date',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyEndDate,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Policy End Date")[0]?.dataType,

                  },
                  {
                    name: 'Policy Renewal Date',
                    value: getProrate(selectedProduct.risks && selectedProduct.risks[0].rating.message).policyRenewalDate,
                    dataType: premiumBreakdownAttributes?.filter((x) => x.name === "Policy Renewal Date")[0]?.dataType,

                  }],
                  valuations: products?.selectedProduct?.risks?.find((x) => x.code === riskAttributes[6]?.code)?.valuations?.values ?? [],
                  productRiskReference: riskAttributes[6]?.instanceId ,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
              ],
              bankAccounts: null,
              productOptionReference:
                products.selectedProduct.productOptionReference,
              status: isValid ? 15 : 18,
            },
          ],
          networkId: CONSTANTS.NETWORK_ID,
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        },
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );
      // dispatch(CreatePolicy(process.env.REACT_APP_ENV_TYPE === 'PROD' ? response.data : response.data.data))
      dispatch(CreatePolicy(response.data.data))
      setIsLoading(false);
      handleNext(response);
    } catch (err) {
      
      setIsLoading(false);
      toast.error(err?.response?.data);
  }
  };

  const companyOrSoleProprietor = presistedRisks.find((f) => f.code === 'BUD').attributes.find((x) => x.name === 'Company Types')


  return (
    <div>
      {/* <---------- Title -------> */}
      <h4>{product?.name}</h4>

      {/* <------- Sub title ------> */}
      <h6>Add your details</h6>

      <Divider row={2} />

      {/* <------- Attributes Starts ------> */}
      <Row className="mt-4">
        <Col sm={6}>
          <SelectWithRadioHorizontal
            onChange={handleChange}
            onExtraChange={handleChange}
            required={false}
            name={'workCompanyOptions'}
            options={JSON.parse(companyOrSoleProprietor?.settings).items}
            value={attributes.workCompanyOptions}
            label="Company or Sole Proprietor"
            extras={
              
              attributes.workCompanyOptions === "Company"
                ? [
                    {
                      type: "text",
                      label: "- Company name",
                      name: "workCompany",
                      value: attributes["workCompany"],
                      errors: errors.workCompany,
                    },
                    {
                      type: "text",
                      label: "- Company Reg No, if registered",
                      name: "companyRegNo",
                      value: attributes["companyRegNo"],
                      errors: errors.companyRegNo,
                    },
                    {
                      type: "text",
                      label: "- VAT No, if registered",
                      name: "vatNumber",
                      value: attributes["vatNumber"],
                      errors: errors.vatNumber && ["VAT number can't be blank"],
                    },
                  ]
                : [
                    {
                      type: "text",
                      label: "- VAT No",
                      name: "vatNumber",
                      value: attributes["vatNumber"],
                    },
                    {
                      type: "text",
                      label: "- ID Number",
                      name: "idNumber",
                      value: attributes["idNumber"],
                      errors: errors.idNumber ?  [errors.idNumber[0]]: null
                    }
                  ]
            }
          />
        </Col>
      </Row>

      <VerticalSpacer rows={2} />

      <Row>
        <Col sm={3}>
          <TextInput
            label="Business address"
            name="workAddress"
            placeholder="Add business address"
            onChange={handleChange}
            errors={errors.workAddress}
            required={false}
            value={attributes.workAddress}
          />

          <TextInput
            label="Suburb"
            placeholder="Suburb"
            name="suburb"
            errors={errors.suburb}
            onChange={handleChange}
            required={false}
            value={attributes.suburb}
          />

          <TextInput
            label="City"
            placeholder="Add city"
            name="workCity"
            onChange={handleChange}
            errors={errors.workCity}
            required={false}
            value={attributes.workCity}
          />

        </Col>

        <Col sm={3}>
         

          <Select
            label="Province"
            placeholder="Add province"
            name="workProvince"
            options={[
              'Eastern Cape',
              'Free State',
              'Gauteng',
              'KwaZulu-Natal',
              'Limpopo',
              'Mpumalanga',
              'North West',
              'Northern Cape',
              'Western Cape'
            ]}
            id="workProvince"
            errors={errors.workProvince}
            onChange={handleChange}
            required={false}
            value={attributes.workProvince}
          />


          <TextInput
            label="Postal code"
            placeholder="Add postal code"
            name="postalStateCode"
            errors={errors.postalStateCode}
            onChange={handleChange}
            required={false}
            value={attributes.postalStateCode}
          />

          <Select
            label="Country"
            placeholder="Add country"
            options={["South Africa"]}
            name="country"
            errors={errors.country}
            onChange={handleChange}
            required={false}
            value={attributes.country}
          />
        </Col>

        <Col sm={3}>
          <Select
            label="Title"
            placeholder="Title"
            options={titles}
            name="title"
            errors={errors.title}
            onChange={handleChange}
            required={false}
            value={attributes.title}
          />

          <TextInput
            label="First name"
            placeholder="Contact person"
            name="firstName"
            errors={errors.firstName}
            onChange={handleChange}
            required={false}
            value={attributes.firstName}
          />

          <TextInput
            label="Last name"
            placeholder="Contact person"
            name="lastName"
            errors={errors.lastName}
            onChange={handleChange}
            required={false}
            value={attributes.lastName}
          />

        
        </Col>

        <Col sm={3}>
          <TextInput
            label="Email"
            placeholder="Contact person email"
            name="eMail"
            errors={errors.eMail?.length ? ['Check email & remove unnecessary spaces'] : []}
            onChange={handleChange}
            required={false}
            value={attributes.eMail}
          />

          <TextInput
            label="Mobile number"
            placeholder="Contact person mobile"
            name="mobilePhone"
            errors={errors.mobilePhone}
            onChange={handleChange}
            required={false}
            value={attributes.mobilePhone}
          />

          <TextInput
            label="Alternative email"
            placeholder="Alternative email"
            name="alternateemail"
            id="alternateemail"
            errors={errors.alternateemail}
            onChange={handleChange}
            required={false}
            value={attributes.alternateemail}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xs={6} md={2} className="d-flex justify-content-start">
          <ButtonWithDownArrow
            onClick={handleBack}
            type="left"
            name="Back"
            bg="#dd302a"
            color="white"
            id="form-5-back-button"
          />
        </Col>
        {width > 430 && <Col xs={0} md={8} />}
        <Col xs={6} md={2} className="d-flex justify-content-end">
          <ButtonWithDownArrow
            onClick={handleSubmit}
            type="right"
            name="Next"
            bg="#dd302a"
            color="white"
            id="form-5-next-button"
          />
        </Col>
      </Row>

      {/* <------- Attributes Starts ------> */}

      {isLoading && (
        <div className="gif-loader-fullscreen">
          <img src={GIFLoader} alt='GIFLoader' ></img>
        </div>
      )}
    </div>
  );
};

export default Step4;

const companySchema = {
  workAddress: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[-'\sa-zA-Z0-9,]+$/,
      message:
        "^ Only alphabetic characters, comma, hyphen, number and space are allowed",
    },
  },
  workCity: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      flags: "g",
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  workCompany: {
    presence: { allowEmpty: false },
  },
  companyRegNo: {
    presence: { allowEmpty: false },
  },
  postalStateCode: {
    presence: { allowEmpty: false },
    format: {
      pattern: "^[0-9]+$",
      message: "can only contain number",
    },
  },
  suburb: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      flags: "g",
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  workProvince: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },

  title: {
    presence: { allowEmpty: false },
  },
  country: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      flags: "g",
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  eMail: {
    presence: { allowEmpty: false },
    email: true,
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      // pattern: "[a-zA-Z].+",
      pattern: /^[-'\sa-zA-Z]+$/,
      flags: "g",
      // message: "can only contain alphabets",
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  mobilePhone: {
    presence: { allowEmpty: false },
    format: {
      pattern: "^[0-9]+$",
      message: "can only contain number",
    },
  },
};



const titles = [
  'Adv',
  'Dr',
  'Miss',
  'Mr',
  'Mrs',
  'Ms',
  'Prof',
  'Rev',
  'Sir',
  'NONE'
]