// default imports for react project
import React, { useLayoutEffect, useEffect } from "react";

// import for dispatching action in react
import { useDispatch, useSelector } from "react-redux";

// import default style component from react-bootstrap package
import { Container, Row } from "react-bootstrap";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import BreadCrumb from "components/Breadcrumb";

// import function from actions
import { addCrumb } from "store/actions/common";
import { parseQuery, setParam } from "utils/utils";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// React functional component starts here..
function Disclosures() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // react layout hook to perform init operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/disclosure", name: "Disclosure" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentToken =  useSelector(state => state.auth.currentUserToken)


  useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])



  const disclosure = useSelector(state => state.auth.companyDetails.disclosure)

  return (
    <div>
      <Container className={classNames("content")}>
       
        <BreadCrumb />
        <Row className={classNames("mt-5")}>
        <div dangerouslySetInnerHTML={{ __html: disclosure }} />
        </Row>
      </Container>
    </div>
  );
}

export default Disclosures;
