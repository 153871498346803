// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import images from assets folder

//   import custom component
import { BlackArrayText } from "components/Heading";
import BodyButton from "../Buttons/BodyButton";
import CardHeader from "./CardHeader";
import {
  SecIncidentCosts,
  card_header_img,
  NetworkSecurityLiability,
  Privacy,
  BusInterupt,
  DataRestore,
  CyberExtortion,
  PhysicalDamage,
  TheftOfFunds,
} from "assets/images/images";

const DATA = [
  {
    title: "Security Incident Costs",
    sub: "Incident mitigation and repair",
    icon: DataRestore,
  },
  {
    title: "Cyber Extortion",
    sub: "Mitigation of extortion",
    icon: CyberExtortion,
  },
  {
    title: "Data Restoration",
    sub: "Business data recovery",
    icon: SecIncidentCosts,
  },
  {
    title: "Theft of Funds",
    sub: "Unrecoverable loss of money, because of a system security incident by a third party.",
    icon: TheftOfFunds,
  },
  {
    title: "Business Interruption",
    sub: "Incident recovery costs",
    icon: BusInterupt,
  },
  {
    title: "Privacy Liability",
    sub: "Compromised information protection",
    icon: Privacy,
  },
  {
    title: "Network & Security Liability",
    sub: "Data breach protection",
    icon: NetworkSecurityLiability,
  },
  {
    title: "Physical Damage",
    sub: "Costs to replace or repair direct physical damage to property due to a system security incident.",
    icon: PhysicalDamage,
  },
];

// React functional component starts here..
function Card2({ width }) {
  return (
    <Col lg xs={12}>
      <Row className="m-0 pb-5 card__homepage">
        <Row className="mt-3 p-0 m-0">
          <Col lg={5} md={7} xs={12} className="p-0">
            <CardHeader image={card_header_img} title="Cyber iTOO Go" />
          </Col>
          <Col lg={7} md={5} className="p-0">
            <div
              className={`card__footer__bottom  ${
                width < 430
                  ? "mt-2 text-center"
                  : "mt-4 d-flex justify-content-end"
              }`}
            >
              {width > 500 && (
                <div className="amount">
                  <span>From:</span>
                  <span> R 225 pm</span>
                </div>
              )}
              {width > 500 && (
                <div
                  className={width < 430 ? `d-flex justify-content-center` : ""}
                >
                  <BodyButton
                    color="white"
                    bg="rgb(53, 63, 78)"
                    name="Get Cover Now"
                    size={16}
                    marginL={width > 770 && 100}
                    weight={700}
                    marginT={0}
                    black={width > 430 ? false : true}
                    link="/forms"
                    id="landing-page-get-cover-button"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div
          style={{
            background: `linear-gradient(
178deg, #dd302a0f, #FFFFFF6E)`,
            padding: width < 430 ? "0px" : "",
          }}
        >
          <Row className="m-0">
            <div className="card__list__header text-left mt-3">
              Coverages provided by our cyber insurance policy:
            </div>
            <div>
              <Row className="mt-4 text-center">
                {DATA.map((x, i) => (
                  <Col sm={3} xs={6} key={i} className="mt-4">
                    <img
                      className="card__avatar__img"
                      src={x.icon}
                      alt="avatar"
                    />
                    <div className="card__avatar__text">
                      <div>{x.title}</div>
                      <div>{x.sub}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Row>
          <Row className="m-0 mt-5 mb-5 text-left">
            <div className="card__list__header">
              The possible management and recovery needs following a cyber
              incident:
            </div>
            <div>
              <div className="row text-left card__list2 card__list card__list2__un mt-4 p-0">
                <div className="col-sm-12 col-md-4 p-0">
                  <div>
                    <h6 className="font-weight-bold pl-5 pb-0 mb-0">
                      &nbsp;&nbsp; &nbsp;Incident Response
                    </h6>
                  </div>{" "}
                  <ul>
                    {incidentResponse.map((x, i) => (
                      <li className="bullet" key={i}>
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4 p-0">
                  <h6 className="font-weight-bold pl-5 pb-0 mb-0">
                    &nbsp;&nbsp; &nbsp;Financial Impact
                  </h6>{" "}
                  <ul>
                    {financialImpact.map((x, i) => (
                      <li className="bullet" key={i}>
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4 p-0">
                  <h6 className="font-weight-bold pl-5 pb-0 mb-0">
                    &nbsp;&nbsp; &nbsp;Liability
                  </h6>{" "}
                  <ul>
                    {liability.map((x, i) => (
                      <li className="bullet" key={i}>
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Row>
          <Row className="m-0 ">
            <div className="card__footer2 ">
              <BlackArrayText link="/fast-facts">
                {" "}
                [ See Fast Facts ]{" "}
              </BlackArrayText>
            </div>
          </Row>
        </div>
        <Row className="m-0 mt-5">
          <div
            className={`card__footer__bottom  ${
              width < 430
                ? "mt-2 text-center"
                : "mt-4 d-flex justify-content-start"
            }`}
          >
            <div className="amount">
              <span>From:</span>
              <span> R 225 pm</span>
            </div>
            <div className={width < 430 ? `d-flex justify-content-center` : ""}>
              <BodyButton
                color="white"
                bg="rgb(53, 63, 78)"
                name="Get cover now"
                size={16}
                marginL={width > 770 && 100}
                weight={700}
                marginT={width > 430 ? 0 : 50}
                black={width > 430 ? false : true}
                link="/forms"
                id="landing-page-get-cover-button"
              />
            </div>
          </div>
        </Row>
      </Row>
    </Col>
  );
}

export default Card2;

const incidentResponse = [
  "Incident triage",
  "Data and system recovery",
  "Forensic investigations",
  "Legal guidance",
  "Crisis management",
  "Notification costs",
  "Remediation services",
  "Cyber extortion",
];

const financialImpact = [
  "Business interruption",
  "Increased cost of working",
  "Fines and penalties",
];

const liability = [
  "Defence and settlement of ensuing litigation",
  "Digital media liability",
];
