// default imports for react project
import React, { useLayoutEffect } from "react";

// import for dispatching action in react
import { useDispatch } from "react-redux";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import function from actions
import { addCrumb } from "store/actions/common";

// // Import image from assets folder

// import classname package to combine module css and normal css
import classNames from "classnames";

// import custom data
import { accordion2Data, productDet } from "./data"

// import util to get current window dimensions
import useWindowDimensions from "utils/useWindowDimension";

//   import custom component
import Accordion from "../../components/Accordion";
import { H3Red, H4Grey, BlackArrayText, H1Grey, H3Grey} from "components/Heading";
import BreadCrumb from "components/Breadcrumb";
import BodyButton from "components/Buttons/BodyButton";
import CardHeader from "components/Card/CardHeader";

// import stylesheet
import Style from "./fastfacts.module.css";

// Brochure
import brochure from "../../assets/documents/brochure_Mar_2023.pdf";
import { card_header_img } from "assets/images/images";

// React functional component starts here..
function Product() {
  // initialized dispatch using react hook
  const dispatch = useDispatch();

  // react layout hook to perform initial layout operations
  useLayoutEffect(() => {
    // dispatching an action to update breadcrumb
    dispatch(addCrumb([{ link: "/fast-facts", name: "Fast Facts" }]));
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();

  return (
    <Container className={classNames("content")}>
      <BreadCrumb />
      <Row className="mt-5">
        <H1Grey> About our Cyber insurance cover</H1Grey>
        <H3Grey>Covered by Experts</H3Grey>
      </Row>
      <Row className="mt-5 p-0 m-0">
        <Col lg={5} md={7} xs={12} className={classNames("p-0")}>
          <CardHeader image={card_header_img} title="Cyber iTOO Go" />
        </Col>
        <Col lg={7} md={5} className="p-0">
          <div
            className={`card__footer__bottom  ${
              width < 430
                ? "mt-2 text-center"
                : "mt-4 d-flex justify-content-end"
            }`}
          >
            <div className="amount">
              <span>From:</span>
              <span> R 225 pm</span>
            </div>
            <div className={width < 430 ? `d-flex justify-content-center` : ""}>
              <BodyButton
                color="white"
                bg="rgb(53, 63, 78)"
                name="Get Cover Now"
                size={16}
                marginL={width > 770 && 100}
                weight={700}
                // marginT={width > 770 ? 0 : 20}
                marginT={0}
                black={width > 430 ? false : true}
                link="/forms"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={classNames(Style.topcard, "m-0 px-2")}>
        {productDet?.map((det, i) => (
          <div key={i} className={classNames("mt-4")}>
            <H3Red>{det.header}</H3Red>
            {Array.isArray(det.content) ? (
              <div className={classNames("mt-4")}>
                <ul>
                  {det.content.map(({ title, answer, subAnswer }, i) => (
                    <li key={i}>
                      <H4Grey style={{ fontFamily: "var(--secondary-font)" }}>
                        {title}{answer && ":"}{" "}
                      </H4Grey>
                      <span>{answer}</span>
                      {subAnswer?.map((ans, idx) => (
                        <div
                          key={idx}
                          className={classNames(Style.content__answer)}
                        >
                          - {ans}
                        </div>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>{det.content}</div>
            )}
          </div>
        ))}
        <div className={classNames("my-4 d-flex justify-content-between")}>
          <BlackArrayText>
            <a
              style={{ color: "black" }}
              rel="noreferrer"
              href={brochure}
              target="_blank"
            >
              [ Download Brochure ]
            </a>
          </BlackArrayText>
        </div>
      </Row>

      <Row className="mt-2">
        <Accordion title={accordion2Data?.header}>
          {accordion2Data?.section?.map(({ header, content }, i) => (
            <div key={i} className={classNames("mt-4")}>
              <div className={classNames(Style.accordion2__header)}>
                {header.includes(1) ? <div>1<sup>st</sup> PARTY</div> : header.includes(3) ? <div>3<sup>rd</sup> PARTY</div> : 'INCIDENT RESPONSE'}
              </div>
              <ul style={{ paddingLeft: "20px" }}>
                {content?.map(({ title, answer, subAnswer }, idx) => (
                  <li key={idx} className={`p-0 ${i !== 0 ? `mt-4` : ``}`}>
                    <div className={classNames(Style.content__header)}>
                      {" "}
                      {title}
                    </div>
                    <div className={classNames(Style.content__answer)}>
                      {answer}
                    </div>
                    <div>
                      <ul className="dashed">
                        {subAnswer?.map((ans, i) => (
                          <li
                            key={i}
                            className={classNames(Style.content__answer)}
                          >
                            {ans}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Accordion>
      </Row>
      <Row className="m-0 mt-5">
        <div
          className={`card__footer__bottom  ${
            width < 430
              ? "mt-2 text-center"
              : "mt-4 d-flex justify-content-start"
          }`}
        >
          <div className="amount">
            <span>From:</span>
            <span> R 225 pm</span>
          </div>
          <div className={width < 430 ? `d-flex justify-content-center` : ""}>
            <BodyButton
              color="white"
              bg="rgb(53, 63, 78)"
              name="Get cover now"
              size={16}
              marginL={width > 770 && 100}
              weight={700}
              marginT={0}
              black={width > 430 ? false : true}
              link="/forms"
            />
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Product;
